import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import { useParams } from 'react-router-dom';
import TemplateGeneralDetails from './TemplateGeneralDetails';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import TemplatePublishActions from './TemplatePublishActions';
import TemplateCompliance from './TemplateCompliance'
import TemplateOptions from './TemplateOptions';
import MiniSectorsList from '../../Shared/TablesAndLists/MiniSectorsList';
import TemplateCustomPublishActions from './TemplateCustomPublishActions';
import withRouteChange from "@threeskye/route-change";

const TemplateMgmt = ({ changeRoute }) => {
	const { width } = useWindowDimensions();

	const fakeComplianceActions = [
		{
			id: 12,
			companyId: null,
			displayName: 'Analyst Spot Check',
			description: 'Requires approval from a spot checker before engaging approval workflows',
			implemenation: 'AnalystSpotCheck',
			new: true
		},
		{
			id: 5,
			companyId: null,
			displayName: 'Peer Approval',
			description: 'Prevents publishing if peer approval has not been given',
			implemenation: 'PeerApproval'
		},
		{
			id: 14,
			companyId: null,
			displayName: 'Peer Comments',
			description: 'Enables peer comments',
			implemenation: 'PeerComments',
			new: true
		},
		{
			id: 4,
			companyId: null,
			displayName: 'Compliance Approval',
			description: 'Prevents publishing if compliance approval has not been given',
			implemenation: 'ComplianceApproval',
		},
		{
			id: 13,
			companyId: null,
			displayName: 'Compliance Comments',
			description: 'Enables compliance comments',
			implemenation: 'ComplianceComments',
			new: true
		},
		{
			id: 15,
			companyId: null,
			displayName: 'Supervisor Approval',
			description: 'Prevents publishing if supervisor approval has not been given',
			implemenation: 'SupervisorApproval',
			new: true
		},
		{
			id: 16,
			companyId: null,
			displayName: 'Management Spot Check',
			description: 'Requires approval from a spot checker before sending disclose files',
			implemenation: 'ManagementSpotCheck',
			new: true
		},
	]

	const fakePublishActions = [
		{
			id: 3,
			companyId: null,
			displayName: 'Publish to 3Skye Wealth CRM',
			description: 'Displays the published document in the 3Skye Wealth instance',
			implemenation: 'ThreeSkyeWealth'
		},
		{
			id: 7,
			companyId: 1,
			displayName: 'Publish to Portal',
			description: 'Displays the published document in the customer portal',
			implemenation: 'PublishToPortal'
		},
		{
			id: 11,
			companyId: null,
			displayName: 'Publish Disclose Files',
			description: 'Files will be uploaded to the disclose register',
			implemenation: 'PublishToPortal',
			new: true
		},
		{
			id: 1,
			companyId: null,
			displayName: 'Email Author',
			description: 'Emails a copy of the published document to the author',
			implemenation: 'EmailAuthor'
		},
		{
			id: 2,
			companyId: null,
			displayName: 'Email Distribution List',
			description: 'Emails a copy of the published document to a distribution list',
			implemenation: 'SendToDistributionList'
		},
		{
			id: 17,
			companyId: null,
			displayName: 'Enable Sub-brands',
			description: 'Enables linking of sub-brands per template',
			implemenation: 'EnableSubBrands',
			new: true
		}
	]

	const fakeCustomPublishActions = [
		{
			id: 6,
			companyId: 1,
			displayName: 'Generate BOB2 Spreadsheet',
			description: 'Generates a BOB2 and emails to the receiving system',
			implemenation: 'Bob2'
		},
		{
			id: 8,
			companyId: 1,
			description: null,
			displayName: 'Email List',
			implemenation: 'EmailList',
			new: true
		},
		{
			id: 9,
			companyId: 1,
			description: 'Publish to 3RD Party Research Providers via RIXML',
			displayName: 'RIXML',
			implemenation: 'RIXML',
			new: true
		},
		{
			id: 10,
			companyId: 1,
			description: 'Email Success/Failure Report',
			displayName: 'Reporting',
			implemenation: 'Reporting',
			new: true
		}
	]

	const [template, setTemplate] = useState({});
	const [publishActions, setPublishActions] = useState([]);
	const [selectedPublishActions, setSelectedPublishActions] = useState([]);
	const [templateDef, setTemplateDef] = useState({});
	const [notFound, setNotFound] = useState(false);
	const [assetType, setAssetType] = useState({ label: 'Sector', value: 'Sector' })
	const [disclaimer, setDisclaimer] = useState({ label: 'Fact Sheet', value: 'Fact Sheet' })

	const remote = useRemote();

	const { template: templateId } = useParams()

	useEffect(() => {
		remote.get("/crm/templates/" + templateId).then(setTemplate).catch(e => {
			console.log("Caught e ", e);
			setNotFound(true);
		});
		if (+templateId === 18) {
			setDisclaimer({ label: 'Fact Sheet', value: 'Fact Sheet' })
		} else {
			setDisclaimer({ label: 'Company Disclaimer', value: 'Company Disclaimer' })
		}
		remote.get("/crm/publish-steps").then(setPublishActions);
		remote.get(`/crm/templates/${templateId}/publish-steps`).then(setSelectedPublishActions);
	}, []);


	useEffect(() => {
		if (template && template.template) {
			try {
				setTemplateDef(JSON.parse(template.template))
			} catch (e) {
				console.log(e)
			}
		}
	}, [template])


	// const selectedPublishActionIds = selectedPublishActions.map(a => a.stepId);
	// const availableActions = publishActions.filter(a => !selectedPublishActionIds.includes(a.id))

	const addActionToTemplate = (action) => {
		remote.put(`/crm/templates/${templateId}/publish-steps/${action.id}`).then(setSelectedPublishActions);
	}

	const removeActionFromTemplate = (action) => {
		remote.delete(`/crm/templates/${templateId}/publish-steps/${action.id}`).then(setSelectedPublishActions);
	}

	const goBack = () => {
		changeRoute("/admin/templates")
	}

	if (notFound) {
		return <Page fixedBanner>
			<PageContentBanner divider>
				<ActionButtonsBanner />
			</PageContentBanner>
			<PageContentBodyGrid rowGap="xl" gridTemplateRows="1fr" splitScroll>
				<p>Invalid template</p>
			</PageContentBodyGrid>
		</Page>
	}

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					handleBackOnClick={goBack}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the template details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid paddingBottom={"3rem"}>
						{/* General Details */}
						<TemplateGeneralDetails template={templateDef} assetType={assetType} setAssetType={setAssetType} disclaimer={disclaimer} setDisclaimer={setDisclaimer}/>
						<PageGridDivider col="1 / span 6" />
						{/* Compliance */}
						<TemplateCompliance
							templateId={templateId}
							templateName={template.name}
							data={fakeComplianceActions}
							addActionToTemplate={addActionToTemplate}
							removeAction={removeActionFromTemplate}
							selected={selectedPublishActions}
							setSelectedPublishActions={setSelectedPublishActions}
						/>
						{/* Publishing actions */}
						<TemplatePublishActions
							templateId={templateId}
							templateName={template.name}
							data={fakePublishActions}
							addActionToTemplate={addActionToTemplate}
							removeAction={removeActionFromTemplate}
							selected={selectedPublishActions}
							setSelectedPublishActions={setSelectedPublishActions}
						/>
						{/* Custom Publishing actions */}
						<TemplateCustomPublishActions
							templateId={templateId}
							templateName={template.name}
							data={fakeCustomPublishActions}
							addActionToTemplate={addActionToTemplate}
							removeAction={removeActionFromTemplate}
							selected={selectedPublishActions}
							setSelectedPublishActions={setSelectedPublishActions}
						/>
					</PageContentBodyGrid>
				</PageGridItem>
				{/* grid container for the sectors list section */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid showScrollbar rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"} paddingBottom={"3rem"}>
						<TemplateOptions template={templateDef} assetType={assetType} setAssetType={setAssetType} />
						{templateDef && templateDef.ticker &&  templateDef.ticker.options && (templateDef.ticker.type === 'selection' || templateDef.ticker.type === 'scheme') &&
							<>
								<PageGridItem col="1 / span 6">
									<PageGridDivider col="1 / span 6" />
								</PageGridItem>
								<PageGridItem col="1 / span 6">
									<MiniSectorsList data={templateDef.ticker && templateDef.ticker.options} assetType={assetType} stopRedirect/>
								</PageGridItem>
							</>
						}
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(TemplateMgmt);