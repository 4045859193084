import React, { useEffect, useState, useRef } from "react";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { FileText } from "react-feather";
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import { dummyDisclaimers } from "../../../Utils/DummyData";
import { allCompaniesStyles, getSubBrandName } from '../BrandSettings/BrandsStyles'
import Page, { PageContentBanner, PageContentBodyGrid } from "../../Shared/Page/Page";
import PageGridItem from "../../Shared/Page/PageGridItem";
import ActionButtonsBanner from "../../Shared/Banner/ActionButtonsBanner";
import DetailSectionHeader from "../../Shared/DetailSection/DetailSectionHeader";
import DetailField from "../../Shared/DetailSection/DetailField";
import FlexWrapper from "../../FlexWrapper";
import SearchField from "../../../components/Input/SearchField";
import TableActionIconGroup from "../../../components/Table/TableActionIconGroup";
import RadioButton from "../../../components/RadioButton";
import withRouteChange from "@threeskye/route-change";
import DropDown from "../../../components/DropDown";
import DisclaimersBrandInfo from "./DisclaimersBrandInfo.json"
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import "./AdminCompanyDisclaimersPage.scss";


function AdminCompanyDisclaimersPage({ changeRoute, match }) {
	const [editMode, setEditMode] = useState(false);
	const [brand, setBrand] = useState('colonial')
	const [showDynamicElements, setShowDynamicElements] = useState(false)
	const [fontConfig, setFontConfig] = useState(null)
	const [templateOptions, setTemplateOptions] = useState([])
	const [currentTemplates, setCurrentTemplates] = useState([])
	const [filteredList, setFilteredList] = useState([])
	const [disclaimerName, setDisclaimerName] = useState(null)

	const searchSelect = useRef();
	const brandsInfo = DisclaimersBrandInfo
	const disclaimerId = match.params.disclaimerId;
	const dummyData = dummyDisclaimers.find((d) => d.id === parseInt(disclaimerId));
	const brands = ['colonial', 'north', 'hejaz']
	const fakeListAllTemplates = [
		"Annual Fund Report", "Company Note", "Contract Note", "Fact Sheet", "QFU", "Sector Note", "SIPO"
	]

	useEffect(() => {
		setDisclaimerName(dummyData.name)
		const updatedList = fakeListAllTemplates.map(b => {
			return { label: b, value: b }
		})
		setTemplateOptions(updatedList)
		setCurrentTemplates(dummyData.templatesUsing)
	}, [])

	useEffect(() => {
		setFilteredList(currentTemplates)
		if (templateOptions && templateOptions.length > 0) {
			const templatesOnList = currentTemplates.map(brand => brand.name)
			const filteredOptions = templateOptions.filter(option => !templatesOnList.includes(option.value))
			setTemplateOptions(filteredOptions)
		}
	}, [currentTemplates])

	useEffect(() => {
		const fetchedFonts = brandsInfo[brand].fonts
		if (fetchedFonts) {
			const config = { custom: { families: fetchedFonts.map(f => f.family), urls: fetchedFonts.map(f => f.url) } }
			setFontConfig(config)
		}
	}, [brand])

	function addTemplate(template) {
		setCurrentTemplates([...currentTemplates, template.value])
	}

	function removeTemplate(template) {
		const updetedList = currentTemplates.filter(t => t !== template)
		setCurrentTemplates(updetedList)
	}

	return (
		<Page fixedBanner style={{ paddingLeft: '1rem' }}>
			<PageContentBanner divider>
				<ActionButtonsBanner
					handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + "/disclaimers")}
					cancel={{ onClick: () => setEditMode(false) }}
					editMode={editMode}
					edit={{ onClick: () => setEditMode(true) }}
					remove
					hideFirstDivider
					save={{ onClick: () => setEditMode(false) }}
				/>
			</PageContentBanner>

			<PageContentBodyGrid id='admin-disclaimer-page' rowGap='m' gridColCount='4' paddingTop='0rem' subGrid gridTemplateColumns='270px 21cm' noOverflownY >
				<PageGridItem container className='second-sidebar-left'>
					<div className='second-sidebar-left-container no-border'>
						<FlexWrapper justify="space-between">
							<DetailSectionHeader header="Disclaimer Details" icon={<FileText />} />
						</FlexWrapper>
					</div>
					<div className='second-sidebar-left-container no-border' style={{ paddingTop: '0.5rem', gap: '1rem' }}>
						<DetailField editMode={editMode} bold label="Name" value={disclaimerName} style={{ height: 65 }} onChange={(e) => setDisclaimerName(e.target.value)}/>
						<DetailField label="Date Created" value={dummyData && dummyData.created} style={{ height: 65 }} />
						{/* <div className="detail-field-wrapper pb-m">
							<label className="colour-text-label-grey transition-fade-up">Templates using</label>
							<ul className="coloured-dot">
								{filteredList.map(template => {
									return (
										<li key={template}>
											<div className="template-edit-mode">
												{template}
												{editMode && <TableActionIconGroup remove={{onClick: () => removeTemplate(template)}} />}
											</div>
										</li>
									)
								})}
							</ul>
							{editMode && (
								<div className="mt-s">
									<SearchField
										onChange={(brand) => addTemplate(brand)}
										options={templateOptions}
										placeholder={`Add Templates`}
										size="small"
										width={"l"}
										innerRef={searchSelect}
										value=""
										classNames="distribution-list-search"
										keepOpen
										cursor='pointer'
									/>
								</div>
							)}
						</div> */}
						<DetailField bold label="Created By" value={dummyData && dummyData.createdBy} style={{ height: 65 }} />
						<DetailField label="Last Updated" value={dummyData && dummyData.modified} style={{ height: 65 }} />
						<div className="detail-field-wrapper">
							<label class="label-input">Global Default</label>
							<FlexWrapper align="center" gap="m">
								<RadioButton checked={dummyData && dummyData.isDefault} label="Yes" />
								<RadioButton checked={dummyData && !dummyData.isDefault} label="No" />
							</FlexWrapper>
						</div>
					</div>
				</PageGridItem>
				<PageGridItem className='rest-of-the-page-width-second-sidebar-left' style={{ width: '23cm' }}>
					<div className="disclaimer-container">
						<div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
							<label className='colour-text-label-grey mb-s'>Preview Brand</label>
							<div style={{ display: 'flex', gap: '3rem' }}>
								<DropDown editMode={true} onChange={() => null} value={getSubBrandName(brand)} wrapperStyle={{ width: '50%' }}>
									{brands.map((b, index) => <div key={index} onClick={() => setBrand(b)}>{getSubBrandName(b)}</div>)}
								</DropDown>
								{dummyData.type === 'dynamic' && <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', height: 40 }}>
									{showDynamicElements ?
										<i className='material-icons toggle-on' style={{ color: '#30C9AC', paddingTop: 2 }} onClick={() => setShowDynamicElements(false)}>toggle_on</i> :
										<i className='material-icons toggle-off' style={{ paddingTop: 2 }} onClick={() => setShowDynamicElements(true)}>toggle_off</i>}
									<div>Show Dynamic Elements</div>
								</div>}
							</div>
						</div>
						<div style={{ padding: '1cm', margin: '1cm 0', boxShadow: '0 0 5px rgba(75, 75, 75, 0.2)', outline: 0, height: '842pt', width: '21cm' }}>
							<WebfontLoader onStatus={() => { }} config={fontConfig}>
								<div style={{ paddingBottom: 24 }}>
									<img src={allCompaniesStyles[brand].logoInfo.src} style={{ width: allCompaniesStyles[brand].logoInfo.disclaimerLogowith }} />
								</div>
								<p style={brandsInfo[brand].styles["section-header"]}>Disclaimers</p>
								<p style={brandsInfo[brand].styles['body-text']}>
									<span style={showDynamicElements ? { backgroundColor: '#F2F5FC', color: '#0E8EFD', padding: 2, fontWeight: 600 } : null}>{showDynamicElements ? 'COMPANY NAME' : getSubBrandName(brand, 'legalName')}</span><span>{" "}</span>
									is the responsible entity and issuer of the products made available under <span style={showDynamicElements ? { backgroundColor: '#F2F5FC', color: '#0E8EFD', padding: 2, fontWeight: 600 } : null}>{showDynamicElements ? 'PRODUCT NAME' : getSubBrandName(brand, 'product')}</span>.
								</p>
								<p style={brandsInfo[brand].styles['body-text']}>
									The information provided in this document is general information only and does not take account of your objectives, personal financial or taxation
									situation or needs. Because of this, before acting on the information, you should consider its appropriateness having regard to these factors. You
									should consider obtaining financial advice relevant to your personal circumstances before investing.
								</p>
								<p style={brandsInfo[brand].styles['body-text']}>
									You should also consider the Product Disclosure Statement (PDS) before making any decision to acquire, or continue to hold, an interest in this product.
									You can get the PDS at <span style={showDynamicElements ? { backgroundColor: '#F2F5FC', color: '#0E8EFD', padding: 2, fontWeight: 600 } : null}>{showDynamicElements ? 'WEBSITE' : <a target='_blank' style={brandsInfo[brand].styles['hyper-link']} href={"https://" + getSubBrandName(brand, 'website')}>{getSubBrandName(brand, 'website')}</a>}</span>,
									or by calling us on <span style={showDynamicElements ? { backgroundColor: '#F2F5FC', color: '#0E8EFD', padding: 2, fontWeight: 600 } : null}>{showDynamicElements ? 'PHONE NUMBER' : getSubBrandName(brand, 'phone')}</span>.
								</p>
								<p style={brandsInfo[brand].styles['body-text']}>
									Investments in the funds are subject to investment risk, including loss of income and capital invested. The insurance provider is<span>{" "}</span>
									<span style={showDynamicElements ? { backgroundColor: '#F2F5FC', color: '#0E8EFD', padding: 2, fontWeight: 600 } : null}>{showDynamicElements ? 'INSURER LEGAL NAME' : getSubBrandName(brand, 'insurerLegalName')}</span>.
								</p>
							</WebfontLoader>
						</div>
					</div>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(withThreeSkyeGlobal(AdminCompanyDisclaimersPage));
