import React from 'react'
import Tooltip from '../popups/Tooltip'
import './IconLink.scss'

const IconLink = ({ icon, className, active, onClick, label, counter, counterClassName, disabled }) => {
	return (
		<Tooltip disabled={!label || disabled} placement="right" label={label} delay={1000}>
			<div className={`link-container${active ? " current-route" : ""}${className ? " " + className : ""}${disabled ? " disabled" : ""}`} onClick={disabled ? () => null : onClick}>
				{icon}
				{counter && counter > 0 ? <span className={`icon-counter-wrapper ${counterClassName ? counterClassName : ""}`}>{counter}</span> : ''}
			</div>
		</Tooltip>
	);
}

export default IconLink