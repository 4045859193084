import React, { useState, useEffect } from "react";
import { useStorage, handleDraftFiltering } from '../../Utils/Utils';
import { gicsSectors } from '../../Utils/DummyData';
import Table from "../../components/Table/Table"
import RequestsTableRow from "./RequestsTableRow";

const RequestsLibraryTable = ({ setRequestsLoading, filters }) => {
	const [expandedRows, setExpandedRows] = useState([])
	const [tickers, setTickers] = useState([])
	const [requests, setRequests] = useState([])
	const [rowActivePreview, setRowActivePreview] = useState(null)

	const storage = useStorage();

	
	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers)

		storage.refresh("/documents/requests")
			.then(filterSortAndSetData)
			.catch((err) => {
				console.error(err)
				setRequestsLoading(false)
			});
		storage.watch("/documents/requests", filterSortAndSetData);
		return () => storage.unwatch("/documents/requests")

	}, [])
	
		useEffect(() => {
			refreshList()
		}, [filters])

	const filterSortAndSetData = (drafts) => {
		let sortedDrafts = drafts.filter((doc) => {
			return handleDraftFiltering(doc.requestedAt, doc.ticker, filters)
		}).sort((a, b) => {
			if (a.date > b.date) return -1
			else if (a.date < b.date) return 1
			else return 0
		})
		setRequests(sortedDrafts)
		setRequestsLoading(false)
	}

	const refreshList = () => storage.refresh("/documents/requests").then(filterSortAndSetData)

	const getImage = (t) => {
		const sector = gicsSectors.find(s => s.name === t)
		const theTicker = tickers && tickers.find(ticker => ticker.ric === t)

		if (theTicker) return theTicker.logo
		else if (sector) return sector.image
		else return ""
	}

	const headerWidths = ["150px", "150px", "300px", "200px", "120px", "160px", "80px"];
	const headers = ["Created Date", "Coverage", "Title", "Author", "Peer Review", "Compliance Review", "Actions"];

	const dataMap = requests.map((document, idx) => {

		return <RequestsTableRow
			data={document}
			idx={idx}
			tickers={tickers}
			expandedRows={expandedRows}
			setExpandedRows={setExpandedRows}
			getImage={getImage}
			refreshList={refreshList}
			allRequests={requests}
			setRowActivePreview={setRowActivePreview}
			rowActivePreview={rowActivePreview}
		/>
	});

	return (
		<>
			<Table id="drafts-library-table" headerWidths={headerWidths} minWidth={1250} headers={headers} dataMap={dataMap} wrapperStyle={{ paddingTop: '1.5rem' }}/>
		</>
	);
}

export default RequestsLibraryTable;

export const RawDraftsLibraryTable = RequestsLibraryTable;
