import React, { useEffect, useState } from 'react';
import "./ExpandableTableRow.scss";

const ExpandableTableRow = ({ children, className, expandContent, manuallySetExpandActive, onClick, expandRowClassname, isLast, dontExpand }) => {
	const [expandActive, setExpandActive] = useState(false);

	useEffect(() => {
		if (manuallySetExpandActive === true || manuallySetExpandActive === false) {
			setExpandActive(manuallySetExpandActive);
		}
	}, [manuallySetExpandActive]);

	let classes = "expandable-table-row cursor-pointer";
	let expandRowClasses = "expand-content-row";

	if (className) {
		classes += " " + className;
	}
	if (expandActive && !dontExpand) {
		classes += " expand-active";
		expandRowClasses += " expand-active";
	}
	if (expandRowClassname) {
		expandRowClasses += " " + expandRowClassname;
	}

	if (isLast) {
		classes += " last-tr";
		expandRowClasses += " last-tr";
	}

	return (
		<>
			<tr className={classes} onClick={() => {
				!manuallySetExpandActive && setExpandActive(!expandActive)
				onClick && onClick()
			}}>
				{children}
			</tr>
			{!dontExpand &&
				<tr className={expandRowClasses}>
					<td colSpan="100%" className="expand-inner">{expandContent}</td>
				</tr>
			}
		</>
	);
}

export default ExpandableTableRow;