import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useRemote } from '../../../Utils/Utils';
import { useParams } from 'react-router-dom';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import withRouteChange from "@threeskye/route-change";
import { FileText, CreditCard, Users, ChevronDown, ChevronUp } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import RiskIndicator from './RiskIndicator'
import FundDescription from './FundDescription'
import Table from '../../../components/Table/Table';
import Button from '../../../components/Buttons/Button';
import { formatDuration } from "date-fns";
import FundsNavigation from './FundsNavigation';


const FundPage = () => {
    const remote = useRemote();
    const { fundId } = useParams()

    const [originalFund, setOriginalFund] = useState(null)
    const [fund, setFund] = useState(null)
    const [personExpanded, setPersonExpanded] = useState(null)
    const [tableWidth, setTablwWidth] = useState(675)
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        remote.get(`/funds/funds/${fundId}`).then((fund) => {
            setOriginalFund(fund)
            setFund(fund)
        })
    }, [remote, fundId])

    useEffect(() => {
        window.addEventListener("resize", getTableWidth);
        return () => window.removeEventListener("resize", getTableWidth);
    }, [])



    function getTableWidth() {
        let tableElement = document.getElementById('fund-personnel-table')
        if (tableElement) {
            setTablwWidth(tableElement.offsetWidth)
        }
    }

    function updateFund(newValue, property) {
        // save temporary updates
        const newObj = { ...fund, [property]: newValue }
        setFund(newObj)
    }

    function saveEdits() {
        remote.post(`/funds/funds/${fundId}`, fund).then(updatedFund => {
            setOriginalFund(updatedFund)
            setFund(updatedFund)
        })
        setEditMode(false)
    }

    function cancelEdits() {
        setFund(originalFund)
        setEditMode(false)
    }

    function formatFundValue(value) {
        const numberValue = parseFloat(value);
        const formattedValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(numberValue);
        return formattedValue;
    }


    const headers = [<div>&nbsp;</div>, <div>&nbsp;</div>]
    const colWidths = ['auto', '25px']
    const personnerlDataMap = (data) => data && data.map((person, idx) => {
        const { name, email, timeInCurrentPosition, timeInPreviousPosition, currentPosition, previousPosition } = person

        return (
            <>
                <tr key={idx} className='cursor-pointer' onClick={() => null/*changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/funds/' + fund.id)*/}>
                    <td className={personExpanded === idx ? "expanded-table-row" : ""}>{name + " "}<span style={{ color: '#ADB1BD', fontSize: '0.85rem' }}>{email}</span></td>
                    <td className={personExpanded === idx ? "expanded-table-row" : ""}>{personExpanded === idx ? <ChevronUp size={20} color='#BFC1CB' onClick={() => setPersonExpanded(null)} /> : <ChevronDown size={20} color='#BFC1CB' onClick={() => setPersonExpanded(idx)} />}</td>
                </tr>
                {personExpanded === idx ?
                    <PageContentBodyGrid rowGap="xl" gridColCount="6" paddingBottom='2rem' style={{ padding: '1rem 0.5rem', backgroundColor: '#fbfcfe', width: tableWidth }}>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                editMode={false}
                                onChange={(e) => null}
                                label="Current Position"
                                value={currentPosition}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                editMode={false}
                                onChange={(e) => null}
                                label="Time in Current Position"
                                value={formatDuration({ years: Math.floor(timeInCurrentPosition / 12), months: timeInCurrentPosition % 12 })}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                editMode={false}
                                onChange={(e) => null}
                                label="Previous/Other Position"
                                value={previousPosition}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                editMode={false}
                                onChange={(e) => null}
                                label="Time in Previous/Other Position"
                                value={formatDuration({ years: Math.floor(timeInPreviousPosition / 12), months: timeInPreviousPosition % 12 })}
                            />
                        </PageGridItem>
                    </PageContentBodyGrid> : ""
                }
            </>
        );
    })

    return (
        <Page fixedBanner>
            <PageContentBanner divider>
                <FundsNavigation activeHeader='Funds' item={fund} />
            </PageContentBanner>
            <PageContentBodyGrid rowGap="xl" gridColCount="12" subGrid>
                <PageGridItem container col="1 / span 6">
                    <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
                        <PageGridItem container col="1 / span 6" style={{ marginTop: '1.5rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <DetailSectionHeader header="Fund Details" icon={<FileText />} />
                                <div style={{ display: 'flex', gap: 5 }}>
                                    <Button onClick={() => editMode ? saveEdits() : setEditMode(true)}>{editMode ? 'Save' : 'Edit'}</Button>
                                    {editMode && <Button variant="secondary" onClick={cancelEdits}>Cancel</Button>}
                                </div>
                            </div>
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "fundName")}
                                label="Fund Name"
                                value={fund?.fundName}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "fundNumber")}
                                label="Fund Number"
                                value={fund?.fundNumber}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "offerName")}
                                label="Offer Name"
                                value={fund?.offerName}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "offerNumber")}
                                label="Offer Number"
                                value={fund?.offerNumber}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "disclosure")}
                                label="Period disclosure applies, if applicable"
                                value={fund?.disclosure}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "classification")}
                                label="Fund Classification"
                                value={fund?.classification}
                            />
                        </PageGridItem>
                        <PageGridItem container col="1 / span 6">
                            <RiskIndicator risk={+fund?.riskIndicator} updateFund={updateFund} editMode={editMode} />
                        </PageGridItem>
                        <PageGridItem container col="1 / span 6">
                            <FundDescription description={fund?.fundDescription} updateFund={updateFund} editMode={editMode} />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "totalValue")}
                                label="Total fund value as per last fund update"
                                value={fund && fund.totalValue ? formatFundValue(fund.totalValue) : '-'}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "currency")}
                                label="Currency of fund value"
                                value={fund?.currency}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "numberOfInvestors")}
                                label="Number of Investors"
                                value={fund?.numberOfInvestors}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "dateFundStarted")}
                                label="Date fund started"
                                value={fund?.dateFundStarted}
                            />
                        </PageGridItem>
                    </PageContentBodyGrid>
                </PageGridItem>
                <PageGridItem container col="7 / span 6" style={{ marginTop: '1.5rem' }}>
                    <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid divider='left'>
                        <PageGridItem container col="1 / span 6">
                            <DetailSectionHeader header="Fees" icon={<CreditCard />} />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={false}
                                onChange={(e) => null}
                                label="Performance fees"
                                value={fund?.performanceFeesApply ? "Yes" : "No"}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={false}
                                onChange={(e) => null}
                                label="Individual Action Charges"
                                value={fund?.individualChargesApply ? "Yes" : "No"}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                editMode={false}
                                onChange={(e) => null}
                                label="Total annual fund charge (%)"
                                value={fund?.totalAnnualCharge + "%"}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 6">
                            <PageGridDivider />
                        </PageGridItem>
                        <PageGridItem container col="1 / span 6">
                            <DetailSectionHeader header="Key Personnel" icon={<Users />} />
                            <Table
                                minWidth={300}
                                tableStyle={{ maxWidth: 675 }}
                                colWidths={colWidths}
                                headers={headers}
                                dataMap={personnerlDataMap(fund?.personnel)}
                                id='fund-personnel-table'
                            />
                        </PageGridItem>
                    </PageContentBodyGrid>
                </PageGridItem>

            </PageContentBodyGrid>
        </Page>
    );
}

export default withRouteChange(FundPage);