import React, { useEffect, useState } from 'react';
import { ChevronUp, ChevronDown, Edit2 } from 'react-feather';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import PageGridDivider from '../../Shared/Page/PageGridDivider'
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import withRouteChange from '@threeskye/route-change';
import SearchTermFilter from '../../../components/Input/SearchTermFilter'
import FundsNavigation from './FundsNavigation';

const FundsList = ({ changeRoute}) => {

    const remote = useRemote();
	const [sortingColumn, setSortingColumn] = useState({ column: 'dateFundStarted', ascending: true })
    const [fundData, setFundData] = useState([]);
	const [hasFundSearchTerm, setHasFundSearchTerm] = useState(false);
	const [filteredFundData, setFilteredFundData] = useState([])

	const fundDataDisplaying = hasFundSearchTerm ? filteredFundData : fundData

    useEffect(() => {
		remote.get("/funds/funds").then(setFundData);
	}, [remote])

	// Clear Sorting
	useEffect(() => {
		setSortingColumn({ column: "dateFundStarted", ascending: true })
	}, [filteredFundData])

	const toggleSort = (column) => {
		if (column === null) {
			setSortingColumn({ column: "dateFundStarted", ascending: true })
			return
		}

		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		setSortingColumn({ column: column, ascending: ascending })
	}

    const sortTable = (dataToSort) => {
		if (sortingColumn.column === null) {
			return dataToSort
		}
		if (!dataToSort) {
			return dataToSort
		}

		let tableData = dataToSort
		const ascending = sortingColumn.ascending
		const column = sortingColumn.column;

		tableData.sort((a, b) => ascending ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column]))

		return tableData
	}

	// Table widths and headers
	const fundsColWidths = ['31%', 11 + '%', 30 + '%', 11 + '%', 14 + '%', + 3 + '%'];
	const fundsHeaderLabels = [
		{ heading: 'Fund Name', field: 'fundName'},
		{ heading: 'Fund Number', field: 'fundNumber'},
		{ heading: 'Offer Name', field: 'offerName'},
		{ heading: 'Offer Number', field: 'offerNumber'},
		{ heading: 'Date Fund Started', field: 'dateFundStarted'}
	]

	const fundsHeaders = fundsHeaderLabels.map((column) => {
		return (
			{
				text: (
					<div className='sorting-header' onClick={() => toggleSort(column.field)} >
						{column.heading}
						{sortingColumn?.column === column.field && sortingColumn?.ascending === true ?
							<ChevronUp className='colour-text-primary' size={12} /> :
							<ChevronDown className={sortingColumn?.column === column.field ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
						}
					</div>
				)
			} 
		)
	})

	const fundsDataMap = (data) => data && data.map((fund, idx) => {
		const { fundName, fundNumber, offerName, offerNumber, dateFundStarted } = fund
		return (
			<tr key={idx} className='cursor-pointer' onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/funds/' + fund.fundNumber)}>
				<td>{fundName}</td>
				<td>{fundNumber}</td>
				<td>{offerName}</td>
				<td>{offerNumber}</td>
				<td>{dateFundStarted} </td>
				<td className='text-align-right' style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
					<Edit2 className='colour-text-primary' />
				</td>
			</tr>
		);
	})

	const sortedDataToDisplay = sortTable(fundDataDisplaying)
	const dataToDisplay = !!sortedDataToDisplay ?  fundsDataMap(sortedDataToDisplay) : null

	return (
		<Page fixedBanner>
			<PageContentBanner divider dividerThinkness="m">
				<FundsNavigation activeHeader='Funds' />
			</PageContentBanner>
			<PageContentBodyGrid>
				<PageGridItem colSpan='12' >
					<div style={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
						<SearchTermFilter
							size='small'
							width='m'
							placeholder='Search'
							isClearable
							dataSet={ fundData}
							setFilteredData={ setFilteredFundData }
							path={ ['fundName', 'fundNumber', 'offerName', 'offerNumber']}
							hasSearchTerm={(boolean) =>  setHasFundSearchTerm(boolean)}
						/>
					</div>
				</PageGridItem>
				<PageGridDivider width='100rem' />
				<PageGridItem colSpan='12' >
					<Table
						minWidth={1200}
						tableStyle={{ maxWidth: 1200 }}
						colWidths={ fundsColWidths}
						headers={ fundsHeaders}
						dataMap={ dataToDisplay}
						id='scheme-and-funds-expandable-table'
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page >
	)
}

export default withRouteChange(FundsList);