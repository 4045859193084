import React from 'react';
import { ArrowLeft, Copy, Edit2, Trash2 } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import ButtonGroupWrapper from '../../../components/Buttons/ButtonGroupWrapper';
import Divider from '../../../components/Divider';
import useWindowDimensions from '../../../Utils/Utils';
import withRouteChange from "@threeskye/route-change";

const ActionButtonsBanner = ({ editMode, edit, duplicate, remove, cancel, label, save, createMode, handleBackOnClick, hideBackButton, customButton, hideFirstDivider, height }) => {
	const { width } = useWindowDimensions();
	
	return (
		<ButtonGroupWrapper>
			{hideBackButton ? (
				""
			) : (
				<Button variant="secondary" icon={<ArrowLeft />} onClick={handleBackOnClick}>
					Back
				</Button>
			)}
			{(!edit && !duplicate && !remove && !save) || hideBackButton || hideFirstDivider ? "" : <Divider vertical/>}
			{editMode ? (
				<ButtonGroupWrapper className="transition-fade-up-slow">
					<Button disabled={save && save.disabled} onClick={save && save.onClick}>
						Save Changes
					</Button>
					{cancel && <Button variant="secondary" onClick={cancel && cancel.onClick}>
						Cancel
					</Button>}
				</ButtonGroupWrapper>
			) : (
				<ButtonGroupWrapper className="transition-fade-up-slow">
					{edit && (
						<Button icon={<Edit2 />} onClick={edit && edit.onClick} disabled={edit && edit.disabled}>
							{width < 900 ? "" : label ? "Edit " + label : "Edit"}
						</Button>
					)}
					{duplicate && (
						<Button icon={<Copy />} onClick={duplicate && duplicate.onClick} disabled={duplicate && duplicate.disabled}>
							{width < 900 ? "" : label ? "Duplicate " + label : "Duplicate"}
						</Button>
					)}
					{!edit && !duplicate && !remove ? "" : edit && !duplicate && !remove ? "" : <Divider vertical />}
					{remove && (
						<Button icon={<Trash2 />} variant="secondary" hover="danger" onClick={remove && remove.onClick} disabled={remove && remove.disabled}>
							{width < 900 ? "" : label ? "Delete " + label : "Delete"}
						</Button>
					)}
				</ButtonGroupWrapper>
			)}
			{customButton && customButton}
		</ButtonGroupWrapper>
	);
}

export default withRouteChange(ActionButtonsBanner);