import React, { useState, useEffect } from 'react';
import { FileText } from 'react-feather';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions, { useRemote, useStorage } from '../../../Utils/Utils';
import { useParams } from 'react-router-dom';
import TickerPageDetails from './TickerPageDetails';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import DetailField from '../../Shared/DetailSection/DetailField';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import MiniResearchList from '../../Shared/TablesAndLists/MiniResearchList';
import MiniDistributionList from '../../Shared/TablesAndLists/MiniDistributionList';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import MiniDataList from '../../Shared/TablesAndLists/MiniDataList';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import withRouteChange from "@threeskye/route-change";
import { format, formatISO, parseISO } from 'date-fns';
import FlexWrapper from '../../FlexWrapper';

const TickerPage = ({ changeRoute }) => {
	const [editMode, setEditMode] = useState(false);
	const [ticker, setTicker] = useState([]);
	const [lists, setLists] = useState([]);
	const [joinedLists, setJoinedLists] = useState([]);
	const [published, setPublished] = useState([]);
	const [importedData, setImportedData] = useState({});
	const [disclosure, setDisclosure] = useState("")
	const [editedDisclosure, setEditedDisclosure] = useState("")
	const [disclosureLastEdited, setDisclosureLastEdited] = useState("")
	const [onSaveListeners, setOnSaveListeners] = useState([])
	const [onCancelListeners, setOnCancelListeners] = useState([])

	const { width } = useWindowDimensions();
	const { ticker: tickerId } = useParams()
	// let history = useHistory();
	const remote = useRemote();
	const storage = useStorage()
	const disclosureCharacterLimit = 300

	const getData = () => {
		storage.getOrFetch("/crm/tickers/" + tickerId).then((res) => {
			setTicker(res)
			storage.getOrFetch("/per-ticker-stored-data/" + res.ric + "/Disclosures").then((disclosureResponse) => { setDisclosure(disclosureResponse.value) });
			storage.getOrFetch("/per-ticker-stored-data/" + res.ric + "/DisclosuresLastSaved").then((disclosureResponse) => { setDisclosureLastEdited(disclosureResponse.value) });
		});
		storage.getOrFetch("/crm/tickers/" + tickerId + "/published").then(setPublished);
		storage.getOrFetch("/crm/tickers/" + tickerId + "/lists").then(setJoinedLists);
		storage.getOrFetch("/crm/tickers/" + tickerId + "/data").then(setImportedData);
		storage.getOrFetch("/crm/lists").then(setLists);
	}

	useEffect(() => {
		getData()
	}, []);

	const saveChanges = () => {
		onSaveListeners.forEach(func => func())
		setOnSaveListeners([])
		let savedAt = formatISO(new Date())
		if (editedDisclosure && editedDisclosure !== disclosure) {
			remote.post("/per-ticker-stored-data/" + ticker.ric + "/DisclosuresLastSaved", { "value": savedAt })
				.then(() => {
					storage.refresh("/per-ticker-stored-data/" + ticker.ric + "/DisclosuresLastSaved")
					setDisclosureLastEdited(savedAt)
				});
			remote.post("/per-ticker-stored-data/" + ticker.ric + "/Disclosures", { "value": editedDisclosure })
				.then(() => {
					storage.refresh("/per-ticker-stored-data/" + ticker.ric + "/Disclosures")
					setDisclosure(editedDisclosure)
					setEditedDisclosure("")
					setEditMode(false)
				})
		} else {
			setEditMode(false)

		}
	}

	const compareDateArray = (arr1, arr2) => {
		let normalised1 = arr1.map((valNum) => {
			let val = valNum.toString()
			return val.length > 1 ? val : "0" + val
		})
		let normalised2 = arr2.map((valNum) => {
			let val = valNum.toString()
			return val.length > 1 ? val : "0" + val
		})
		return normalised2.join("") - normalised1.join("")
	}
	
	const cancelChanges = () => {
		setEditMode(false)
		setEditedDisclosure("")
		onCancelListeners.forEach(func => func())
	}

	const setOnSaveListener = (func) => {
		let newOnSaveListeners = [...onSaveListeners, func]
		setOnSaveListeners(newOnSaveListeners)
	}
	const setOnCancelListener = (func) => {
		let newOnCancelListeners = [...onCancelListeners, func]
		setOnCancelListeners(newOnCancelListeners)
	}

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					editMode={editMode}
					edit={{ disabled: false, onClick: () => setEditMode(true) }}
					duplicate={{ disabled: true, onClick: () => alert("Shadow Clone Jutsu 🙏") }}
					remove={{ disabled: true }}
					cancel={{ onClick: cancelChanges }}
					save={{ onClick: saveChanges }}
					handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + "/tickers")}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="ticker-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the ticker details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<TickerPageDetails ticker={ticker} editMode={editMode} imageEdit={editMode} setOnSaveListener={setOnSaveListener} setOnCancelListener={setOnCancelListener} />
						<PageGridDivider col="1 / span 6" />
						{/* Disclosure */}
						<PageGridItem col="1 / span 6">
							<DetailSectionHeader icon={<FileText />} header="Ticker Disclosure" />
						</PageGridItem>
						<PageGridItem col="1 / span 6">
							<DetailField maxlength={disclosureCharacterLimit} textArea resize="vertical" editMode={editMode} value={editedDisclosure ? editedDisclosure : disclosure} onChange={(text) => { setEditedDisclosure(text) }} />
							{editMode && <FlexWrapper className="mt-xs" justify={"space-between"} >
								<SuggestionMessage message={disclosureLastEdited ? `Last saved ${format(parseISO(disclosureLastEdited), "h:mmaaa do MMM yyyy")}` : ""} />
								<SuggestionMessage message={`${editedDisclosure ? editedDisclosure.length : disclosure ? disclosure.length : 0}/${disclosureCharacterLimit}`} />
							</FlexWrapper>}
						</PageGridItem>
						<PageGridDivider col="1 / span 6" />
					</PageContentBodyGrid>
				</PageGridItem>
				{/* Research section */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"}>
						<MiniResearchList restrictHeight suggestionText="Published research for this ticker will be listed here." data={published.sort((val1, val2) => compareDateArray(val1.publishedDate, val2.publishedDate))} />
						<PageGridDivider col="1 / span 6" />
						{/* Distribution Lists */}
						<MiniDistributionList restrictHeight allLists={[]} data={lists} userLists={joinedLists} suggestionText="A distribution list for this ticker will automatically be created on save." />
						{/* <MiniDataList restrictHeight data={importedData} /> */}
					</PageContentBodyGrid>
				</PageGridItem>

			</PageContentBodyGrid>
		</Page>
	);
}
export default withRouteChange(TickerPage);