import React from 'react';
import { useHeaderAutoNumbering } from '../../Utils/UseHeaderAutoNumbering';
const TrackableSlateHeader = (props) => {
	const { attributes, children, element } = props;
	const headerNumbering = useHeaderAutoNumbering()

	return (
		<h1 {...attributes}>
			{headerNumbering.headersNumbered &&
				<span
					contentEditable={false}
					style={{ marginRight: "0.1em" }}
				>
					{element.headerIndex && `${element.headerIndex}.`}
				</span>
			}
			{children}
		</h1>
	);
}

export default TrackableSlateHeader;