import React from 'react';
import './RiskIndicator.scss'

const RiskIndicator = (props) => {
    const { risk, updateFund, editMode} = props
    return (
        <div>
            <div className={`risk-label ${editMode ? '' : 'static-label'}`}>Risk Indicator</div>
            <div className='risk-cubes'>
                <div className={`risk-cube ${editMode ? 'editing' : ''} ${risk === 1 ? 'active' : ''}`} onClick={() => editMode ? updateFund(1, "riskIndicator") : null}>1</div>
                <div className={`risk-cube ${editMode ? 'editing' : ''} ${risk === 2 ? 'active' : ''}`} onClick={() => editMode ? updateFund(2, "riskIndicator") : null}>2</div>
                <div className={`risk-cube ${editMode ? 'editing' : ''} ${risk === 3 ? 'active' : ''}`} onClick={() => editMode ? updateFund(3, "riskIndicator") : null}>3</div>
                <div className={`risk-cube ${editMode ? 'editing' : ''} ${risk === 4 ? 'active' : ''}`} onClick={() => editMode ? updateFund(4, "riskIndicator") : null}>4</div>
                <div className={`risk-cube ${editMode ? 'editing' : ''} ${risk === 5 ? 'active' : ''}`} onClick={() => editMode ? updateFund(5, "riskIndicator") : null}>5</div>
                <div className={`risk-cube ${editMode ? 'editing' : ''} ${risk === 6 ? 'active' : ''}`} onClick={() => editMode ? updateFund(6, "riskIndicator") : null}>6</div>
                <div className={`risk-cube ${editMode ? 'editing' : ''} ${risk === 7 ? 'active' : ''}`} onClick={() => editMode ? updateFund(7, "riskIndicator") : null}>7</div>
            </div>
        </div>
    )

}

export default RiskIndicator