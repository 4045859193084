import React, { Component } from "react";
import SideBarFooter from "./SideBarFooter";
// import "@threeskye/core-components/dist/styles/includes/Forms.scss";
import { withData } from "../../../DataController";
import withRouteChange from "@threeskye/route-change";
import SidebarExtensionWrapper from "./SideBarExtensionWrapper";
import FlexWrapper from "../../../layouts/FlexWrapper";
import AlertBlock from "../../../layouts/Shared/Prompts/AlertBlock";
import { synchroniseData } from "../../../Utils/Utils";
import ReviewButton from "./ReviewButton";
import { Edit } from 'react-feather';
import { withStorage } from "@threeskye/global";
import ConfirmOptionModal from "../../Modals/ConfirmOptionModal";
import SideBarSection, { SortableSidebarSection } from '../../SideBar/SideBarExtension/SideBarSection'
import DropDown from '../../DropDown'
import TickerItem from '../../TickerItem'
import TextInput from '../../Input/TextInput'
import Dropzone from '../../Dropzone/Dropzone'
import Button from '../../Buttons/Button'


//MAP OF ALL TEMPLATE TICKER TYPES
export const TEMPLATE_TICKER_TYPES = {
	SELECTION: "selection",
	OPEN: "open",
	FIXED: "fixed",
	SCHEME: "scheme"
}

class EditorSideBarExtension extends Component {
	constructor(props) {
		super(props);

		this.state = {
			me: null,
			templates: [],
			selectedTemplate: "",
			template: null,
			saved: false,
			saving: false,
			tickers: [""],
			selectedTickers: [""],
			selectionGroups: [],
			uploading: false,
			showUploadingSpinner: true,
			uploadingMessage: "",
			tickerMessage: {},
			sideBarCollapsed: false,
			lastSavedDate: new Date(),
			showConfirmOptionModal: false
		}

		this.onUpdateTemplate = this.onUpdateTemplate.bind(this);
		this.updateTickerStatus = this.updateTickerStatus.bind(this);
		this.reviewDisabled = this.reviewDisabled.bind(this);
		this.performComplianceReview = this.performComplianceReview.bind(this);
		this.performPeerReview = this.performPeerReview.bind(this);
		this.publish = this.publish.bind(this);
		this.publishOverride = this.publishOverride.bind(this);
		this.statusCheck = this.statusCheck.bind(this);
		this.preview = this.preview.bind(this);
		this.getDocumentIdentifier = this.getDocumentIdentifier.bind(this);
		this.onUpdateTickerGroup = this.onUpdateTickerGroup.bind(this);

		this.includePage = this.includePage.bind(this);
		this.listener = this.listener.bind(this);
		this.saveErrorListener = this.saveErrorListener.bind(this);
		this.peerReviewAvailable = this.peerReviewAvailable.bind(this);
		this.complianceReviewAvailable = this.complianceReviewAvailable.bind(this);

		this.queryParameters = new URLSearchParams(window.location.search)
		this.URLTemplate = this.queryParameters.get("template")
		this.URLAsset = this.queryParameters.get("asset")

	}

	complianceReviewAvailable() {
		const org = this.props.organisation;
		const perms = this.state.me && this.state.me.perms;
		if (org && org.complianceReviewAvailable === false) {
			return false;
		}
		if (!perms || !perms.includes("REQUEST_COMPLIANCE")) {
			return false;
		}
		return true;
	}

	peerReviewAvailable() {
		const org = this.props.organisation;
		const perms = this.state.me && this.state.me.perms;
		if (org && org.peerReviewAvailable === false) {
			return false;
		}
		if (!perms || !perms.includes("REQUEST_PEER")) {
			return false;
		}
		return true;
	}

	getDocumentIdentifier() {
		let documentIdentifier = '';
		if (!this.state.template) {
			return;
		}
		const tickerConf = this.state.template.ticker;
		if (tickerConf.type === TEMPLATE_TICKER_TYPES.OPEN) {
			documentIdentifier = this.state.tickers.join(',');
		} else if (tickerConf.type === TEMPLATE_TICKER_TYPES.SELECTION) {
			if (!this.state.selectedTickerGroup) {
				return '';
			}
			documentIdentifier = this.state.selectedTickerGroup.name;
		} else if (tickerConf.type === TEMPLATE_TICKER_TYPES.FIXED) {
			documentIdentifier = tickerConf.ticker;
		}
		return documentIdentifier;
	}

	performComplianceReview() {
		const { /*tickers,*/ selectedTemplate, selectedTickerGroup } = this.state;
		const { compliance } = this.props
		compliance.requesting = true;
		this.props.setCompliance(compliance)


		const data = {};
		if (selectedTickerGroup && selectedTickerGroup.tickers) {
			data.tickers = selectedTickerGroup.tickers;
		}
		this.props.remote.post("/client/review/compliance/" + this.getDocumentIdentifier() + "/" + selectedTemplate, data)
			.then(resp => {
				if (resp.success) {
					if (resp.message) {
						this.props.showModal("reviewResent");
					}
					compliance.requested = true;
					this.props.setCompliance(compliance);
				} else {
					this.props.showModal("reviewFailure");
				}
			});
		this.props.setSideBarExtRight("Comments")
	}

	performPeerReview() {
		this.props.showModal("analystChecklist", () => {
			const { /*tickers,*/ selectedTemplate, selectedTickerGroup } = this.state;
			const { peerReview } = this.props
			peerReview.requesting = true;
			this.props.setPeerReview(peerReview);
			this.props.downloadPDF({
				tickers: this.state.tickers,
				template: this.state.selectedTemplate,
				templateName: this.state.selectedTemplate,
				tickerGroup: this.state.selectedTickerGroup && this.state.selectedTickerGroup.name
			}, dto => {
				if (selectedTickerGroup && selectedTickerGroup.tickers) {
					dto.allTickers = selectedTickerGroup.tickers;
				}
				this.props.remote.post("/client/review/peer/" + this.getDocumentIdentifier() + "/" + selectedTemplate, dto)
					.then(resp => {
						if (resp.success) {
							if (resp.message) {
								this.props.showModal("reviewResent");
							}
							peerReview.requested = true;
							this.props.setPeerReview(peerReview);
						} else {
							if (resp.message) {
								this.props.showModal("reviewFailure", null, null, { message: resp.message });
							} else {
								this.props.showModal("reviewFailure");
							}
						}
					});
			});
		});
		this.props.setSideBarExtRight("Comments")
	}

	includePage(page) {
		const optionalPages = this.props.optionalPages;
		const index = optionalPages.indexOf(page);
		if (index > -1) {
			optionalPages.splice(index, 1);
		} else {
			optionalPages.push(page);
		}
		this.props.setOptionalPages(optionalPages);
		this.props.data.updateLocalData("OPTIONAL_PAGES", JSON.stringify(optionalPages), "SIDEBAR");
	}

	onTickerTextChange(event, index) {
		let value = event.currentTarget.value.toUpperCase();
		this.setState(({ tickers }) => ({ tickers: tickers.slice(0, index).concat(value).concat(tickers.slice(index + 1, tickers.length)) }));
	}

	/**
	 * Update the ticker status.  Triggers pulls for review state and 
	 * also updates data controller with the currently active tickers.
	 * 
	 * 
	 */
	updateTickerStatus(force) {
		this.props.setLoadingLocalData(true, (e) => console.log('set loading local data has callback ', e));
		const { tickers, selectedTemplate, selectedTickers, template } = this.state;
		if (template && template.ticker.type === TEMPLATE_TICKER_TYPES.OPEN) {
			const noChange = this.scalarCompareArrays(tickers, selectedTickers);
			if (noChange && !force) {
				this.props.setLoadingLocalData(false);
				return;
			}
			const documentIdentifier = this.getDocumentIdentifier();
			if (tickers && tickers.length > 0) {
				if (tickers.length === 1 && tickers[0] === '') {
					this.props.setLoadingLocalData(false);
					return;
				}
				const tickersToCheck = tickers;
				Promise.all(
					tickersToCheck.map(ticker =>
						this.props.remote.get(`/data/data-status/${ticker}/${selectedTemplate}`)
							.then(result => this.setState(({ tickerMessage }) => ({ tickerMessage: { ...tickerMessage, [ticker]: result.message } })))
					)
				).then(() => {
					this.setState({ selectedTickers: tickers });
					this.props.setLoadingLocalData(false);
					this.props.data.updateTicker(this.state.tickers, "SIDEBAR").then(v => {
						this.props.setLoadingLocalData(false);
					});
				});

				if (documentIdentifier && selectedTemplate) {
						this.props.remote.get("/client/review/compliance/" + documentIdentifier + "/" + selectedTemplate)
					.then(compliance => {
						this.props.setCompliance(compliance);
					});
				this.props.remote.get("/client/review/peer/" + documentIdentifier + "/" + selectedTemplate)
					.then(peerReview => {
						this.props.setPeerReview(peerReview);
					});
				}
			}
			
			if (documentIdentifier) {
				let url = new URL(window.location.href);
				let currentTemplate = url.searchParams.get("template");
				let currentAsset = url.searchParams.get("asset");
				console.log(documentIdentifier)

				if (documentIdentifier !== currentAsset) {
					let newURL = url.origin + url.pathname
					if (currentTemplate) {
						newURL += `?template=${currentTemplate}`;
						newURL += `&asset=${documentIdentifier}`;
					} else {
						newURL += `?asset=${documentIdentifier}`;
					}

					window.history.pushState({}, '', newURL);
				}
			}
		} else if (template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION) {
			const noChange = this.scalarCompareArrays(tickers, selectedTickers);
			if (noChange) {
				this.props.setLoadingLocalData(false);
				return;
			}
			this.setState({ selectedTickers: tickers }, () => {
				this.props.setLoadingLocalData(false)
				this.props.data.setTickers(this.state.tickers, "SIDEBAR");
			});
		} else if (template && template.ticker.type === TEMPLATE_TICKER_TYPES.FIXED) {
			const fixed = template.ticker.ticker;
			if (tickers[0] === fixed) {
				//nochange
				return
			}
			this.setState({ selectedTickers: [fixed] }, () => {
				this.props.data.setTickers([fixed], "SIDEBAR");
				this.props.setLoadingLocalData(false)
			});
		} else {
			this.props.setLoadingLocalData(false);
		}
	}

	blurOnEnter(e) {
		if (e.key === 'Enter' || e.key === 'Tab') {
			this.props.setLoadingLocalData(true);
			e.target.blur()
		}
	}

	onUpdateTemplate(onMount) {
		const { selectedTemplate } = this.state;
		const prevTemplate = this.state.template;
		if (selectedTemplate !== prevTemplate) {
			this.props.remote.get("/data/templates/" + selectedTemplate).then(template => {
				let { tickers } = this.state;
				const isSelection = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION
				const isScheme = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SCHEME
				if (template.optionalPages) {
					this.props.setOptionalPages([])
				} else {
					this.props.setOptionalPages(null);
				}
				const compliance = {
					requested: false,
					approved: false,
					denied: false,
					comment: false
				};
				const peerReview = {
					requested: false,
					approved: false,
					denied: false,
					comment: ""
				}

				this.setState({ template, selectedTickers: [""], tickers: [""], selectedTickerGroup: null });
				this.props.setPeerReview(peerReview)
				this.props.setCompliance(compliance)
				this.props.data.registerTemplate(template, selectedTemplate);
				this.props.onTemplateChange(template);
				let url = new URL(window.location.href);
				let currentTemplate = url.searchParams.get("template");

				if (selectedTemplate !== currentTemplate) {
					let newURL = url.origin + url.pathname + `?template=${selectedTemplate}`;
					window.history.pushState({}, '', newURL);
				}

				//This changes the syncs selectedTickers with tickers
				//this.updateTickerStatus(true);
				/* load URLAsset Data if this function is running onMount */
				if (isSelection && onMount) {
					const selectedTickerGroup = template && template.ticker && template.ticker.options && template.ticker.options.find(option => option.name === this.URLAsset);
					this.setState({ selectedTickerGroup }, () => this.onUpdateTickerGroup())
				} else if (onMount) {
					const tickers = [this.URLAsset];
					this.setState({ tickers }, () => this.updateTickerStatus(true))
				}

				console.log("template.ticker.type", template?.ticker?.type)
				console.log("Full ticker", template?.ticker)
				// console.log("Test ticker - expect true", template?.ticker?.schemeNumbers?.includes('OFR10757'))
				console.log("Selection groups", this.selectionGroups)
				if (template.ticker.type === 'selection') {
					if (template.ticker.lookup) {
						this.props.remote.get("/crm/groupings").then(groupings => {
							if (groupings && groupings.length) {
								const lookup = groupings.filter(g => g.groupName === template.ticker.lookup);
								if (lookup.length) {
									this.props.remote.get(`/crm/groupings/${lookup[0].id}`).then(modelGrouping => {
										this.setState({ selectionGroups: modelGrouping.groups });
									})
								}
							}
						})
					}
				}
				if (template.ticker.type === 'scheme') {
					this.props.remote.get("/funds/offers").then(schemes => {
						console.log("Setting selection groups", schemes)
						this.setState({selectionGroups: schemes})
					});
				}
				if (template.ticker.type === 'fixed') {
					const fixedTicker = template.ticker.ticker;
					this.setState({ selectedTickers: [fixedTicker] }, () => this.props.data.setTickers([fixedTicker], "SIDEBAR"));
				}

			});

		}
	}

	updateSchemeTickerGroup() {
		let { selectedTickerGroup, selectedTemplate } = this.state;
		console.log("Updating scheme ticker group", selectedTickerGroup);
		this.props.setLoadingLocalData(true)

		this.props.data.updateOfferName(selectedTickerGroup.offerName);

		this.props.data.updateTickerGroup(selectedTickerGroup.offerNumber, selectedTickerGroup.funds.map(f => f.fundNumber)).then((e) => {
			this.props.setLoadingLocalData(false);
		});
		
		

		// this.props.remote.get(`/funds/offers/${selectedTickerGroup.offerNumber}`).then(resp => {
		// 	const funds = resp.funds;
		// 	console.log("Got funds for the scheme", funds)
		// 	this.props.data.updateTickerGroup(selectedTickerGroup.schemeNumber, funds.map(f => f.fundNumber))
		// })
		
	}

	onUpdateTickerGroup() {
		let { selectedTickerGroup, selectedTemplate } = this.state;
		if (selectedTickerGroup.schemeNumber) {
			this.updateSchemeTickerGroup();
			return;
		}

		if (!selectedTickerGroup || !selectedTickerGroup.tickers) {
			return;
		}
		this.props.setLoadingLocalData(true)
		const documentIdentifier = this.getDocumentIdentifier();
		selectedTickerGroup.tickers.map(ticker =>
			this.props.remote.get(`/data/data-status/${ticker}/${selectedTemplate}`)
				.then(result => this.setState(({ tickerMessage }) => ({ tickerMessage: { ...tickerMessage, [ticker]: result.message } })))
		);
		this.props.remote.get("/client/review/compliance/" + documentIdentifier + "/" + selectedTemplate)
			.then(compliance => {
				this.props.setCompliance(compliance);
			});
		this.props.remote.get("/client/review/peer/" + documentIdentifier + "/" + selectedTemplate)
			.then(peerReview => {
				this.props.setPeerReview(peerReview);
			});

		this.props.data.updateTickerGroup(selectedTickerGroup.name, selectedTickerGroup.tickers).then((e) => {
			this.props.setLoadingLocalData(false);
		});

		let url = new URL(window.location.href);
		let currentTemplate = url.searchParams.get("template");
		let currentAsset = url.searchParams.get("asset");
		console.log(selectedTickerGroup.name,)

		if (selectedTickerGroup !== currentAsset) {
			let newURL = url.origin + url.pathname
			if (currentTemplate) {
				newURL += `?template=${currentTemplate}`;
				newURL += `&asset=${selectedTickerGroup.name}`;
			} else {
				newURL += `?asset=${selectedTickerGroup.name}`;
			}

			window.history.pushState({}, '', newURL);
		}
	}

	scalarCompareArrays(arr1, arr2) {
		return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
	}

	reviewDisabled() {
		const { template, tickers } = this.state;

		if (!template) {
			return true;
		}

		if (template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION) {
			const { selectedTickerGroup } = this.state;
			if (!selectedTickerGroup) {
				return true;
			}
		} else if (template.ticker.type === TEMPLATE_TICKER_TYPES.OPEN) {
			for (let i = 0; i < template.ticker.count; i++) {
				const ticker = tickers[i];
				if (!ticker || ticker.length === 0) {
					return true;
				}
			}
		} else {
			return true;
		}

		return false;
	}

	publish() {
		this.props.showModal("publishConfirm", () => {
			this.props.downloadPDF(
				{
					tickers: this.state.tickers,
					template: this.state.selectedTemplate,
					templateName: this.state.selectedTemplate,
					tickerGroup: this.state.selectedTickerGroup && this.state.selectedTickerGroup.name
				},
				dto => this.publishAction(dto, "/publishv2")
			);
		});
	}

	preview() {
		this.setState({ previewLoading: true });

		this.props.downloadPDF(
			{
				tickers: this.state.tickers,
				template: this.state.selectedTemplate,
				templateName: this.state.selectedTemplate,
				tickerGroup: this.state.selectedTickerGroup && this.state.selectedTickerGroup.name
			}, null, () => {
				this.setState({ previewLoading: false });
			}
		);
	}

	publishAction(dto, url) {
		this.setState({ publishing: true });
		dto.template = this.state.selectedTemplate;
		this.props.remote.post(url, dto)
			.then(resp => {
				if (!resp.success) {
					this.props.showModal("publishFailure", null, null, { message: resp.message, data: resp.data });
					this.setState({ publishing: false });
				} else {
					this.props.showModal("publishSuccess");
					this.updateTickerStatus(true);
					this.setState({ publishing: false });
					this.props.changeRoute("/?note-settings=" + resp.data)
				}
			});
	}

	publishOverride() {
		this.props.showModal("overrideConfirm", () => {
			this.props.downloadPDF(
				{
					tickers: this.state.tickers,
					template: this.state.selectedTemplate,
					templateName: this.state.selectedTemplate,
					tickerGroup: this.state.selectedTickerGroup && this.state.selectedTickerGroup.name
				},
				dto => this.publishAction(dto, "/publishv2/override")
			);
		});
	}

	onDrop(files) {
		this.setState({ uploading: true, showUploadingSpinner: true, uploadingMessage: "Uploading ..." });
		const tooBig = [];
		const wrongFileType = [];
		let anyUploaded = false;
		for (var i = 0; i < files.length; i++) {
			let file = files[i];
			let name = file.name;

			if (file.size > 5 * 1024 * 1024) {
				tooBig.push(file);
				continue;
			}
			if (file.type === null || !file.type.includes("spreadsheetml")) {
				wrongFileType.push(file);
				continue;
			}

			anyUploaded = true;
			let xhr = new XMLHttpRequest();
			xhr.open('post', "/api/data/upload", true);
			xhr.addEventListener('load', e => {

				if (xhr.status >= 200 && xhr.status < 300) {
					this.onUploadComplete(e);
				} else {
					console.log(xhr);
				}
			});
			xhr.upload.addEventListener('progress', e => {
				if (e.lengthComputable) {
					let complete = (e.loaded / e.total * 100 | 0);
					if (complete === 100) {
						//TODO onscreen reporting
					} else {
						console.log("Finished");
					}
				}
			});

			xhr.setRequestHeader("Content-Type", file.type);
			xhr.setRequestHeader("Content-Disposition", "attachment; filename=" + name);
			xhr.setRequestHeader("X-3Skye-Session", this.props.remote.createTokenHeader().headers["X-3Skye-Session"]);
			xhr.send(file);
		}
		if (tooBig.length > 0 || wrongFileType.length > 0) {
			this.props.showModal("badProforma", () => { }, () => { }, { tooBig, wrongFileType });
			if (!anyUploaded) {
				this.setState({ uploading: false, showUploadingSpinner: false });
			}
		}
	}

	onUploadComplete(e) {
		//Reload data, if we've got a ticker
		const resp = JSON.parse(e.target.response);
		if (!resp.success) {
			this.props.showModal("uploadFailure");
			this.setState({ uploading: false, showUploadingSpinner: false, uploadingMessage: resp.message });
		} else {
			this.setState({ showUploadingSpinner: false, uploadingMessage: resp.message });
			setTimeout(() => this.setState({ uploading: false }), 1500);
			this.updateTickerStatus(true);
			this.onUpdateTickerGroup();
		}
	}

	setEditorTemplate(onMount) {

		this.props.remote.get("/data/templates").then(fetchedTemplates => {
			const sortedTemplates = fetchedTemplates.sort()
			this.setState({ templates: sortedTemplates })
		});
		
		if (this.URLTemplate && onMount) {
			// const { template } = this.state;
			const template = this.state.templates.find(template => template === this.URLTemplate);
			// const isSelection = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION
			const selectedTemplate = this.URLTemplate;
			this.setState({ selectedTemplate }, () => this.onUpdateTemplate(onMount))

		}
		this.statusCheck = setInterval(this.statusCheck, 60000);
		this.props.data.addLocalListener(this.listener);
		this.props.data.setSaveErrorListener(this.saveErrorListener);
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.template !== prevProps.match.params.template) {
			this.setEditorTemplate()
		}

	}

	componentDidMount() {
		this.setEditorTemplate(true)
		this.props.storage.getOrFetch("/crm/me").then(res => {
			this.setState({ me: res })
		})
	}

	componentWillUnmount() {
		if (this.statusCheck) {
			clearInterval(this.statusCheck);
		}
		this.props.data.removeLocalListener(this.listener);
	}

	saveErrorListener(failed) {
		if (failed) {
			this.props.showSaveError();
		} else {
			this.props.hideSaveError();
			this.setState({ lastSavedDate: new Date() });
		}
	}

	listener() {
		//currently only optional pages need localdata
		if (this.state.template && this.state.template.optionalPages) {
			const dataName = "OPTIONAL_PAGES";
			const content = this.props.data.getLocalValue(dataName);
			if (content && content !== null && content !== "") {
				this.props.setOptionalPages(JSON.parse(content));
			}
		}

		if (this.state.template && this.state.template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION) {
			const { selectedTickerGroup } = this.state;
			if (selectedTickerGroup) {
				const dataName = "TICKERLIST";
				const localStore = this.props.data.getLocalValue(dataName);
				const content = (localStore && localStore.split(", ")) || selectedTickerGroup.tickers;


				const current = this.state.selectedTickers || [];

				if (this.scalarCompareArrays(current, content)) {
					//nothing to do
				} else {
					this.setState({ selectedTickers: content, tickers: content }, () => {
						if (!localStore) {
							//save the defaults back
							this.props.data.updateTicker(content);
						}
					});
				}
			}
		}
		if (this.state.template && this.state.template.ticker.type === TEMPLATE_TICKER_TYPES.SCHEME) {
			const { selectedTickerGroup } = this.state;
			if (selectedTickerGroup) {
				const content = selectedTickerGroup.funds.map(f => f.fundNumber);
				const current = this.state.selectedTickers || [];
				const dataName = "TICKERLIST";
				const localStore = this.props.data.getLocalValue(dataName);
				if (this.scalarCompareArrays(current, content)) {
					//nothing to do
				} else {
					this.setState({ selectedTickers: content, tickers: content }, () => {
						if (!localStore) {
							//save the defaults back
							this.props.data.updateTicker(content);
						}
					});
				}

				// const dataName = "TICKERLIST";
				// const localStore = this.props.data.getLocalValue(dataName);
				// const content = (localStore && localStore.split(", ")) || selectedTickerGroup.funds;

				// console.log("Content has funds ", selectedTickerGroup)
				// const current = this.state.selectedTickers || [];

				// if (this.scalarCompareArrays(current, content || [])) {
				// 	//nothing to do
				// } else {
				// 	this.setState({ selectedTickers: content, tickers: content }, () => {
				// 		if (!localStore) {
				// 			//save the defaults back
				// 			this.props.data.updateTicker(content);
				// 		}
				// 	});
				// }
			}
		}
		return new Promise((resolve) => resolve(true));

	}

	statusCheck() {
		const { tickers, selectedTemplate } = this.state;
		const documentIdentifier = this.getDocumentIdentifier();

		if (!tickers || !selectedTemplate || tickers.length === 0) {
			return;
		}
		if (this.props.compliance.requested && !this.props.compliance.approved && !this.props.compliance.denied)
			this.props.remote.get("/client/review/compliance/" + documentIdentifier + "/" + selectedTemplate)
				.then(compliance => {
					this.props.setCompliance(compliance);
				});
		if (this.props.peerReview.requested && !this.props.peerReview.approved && !this.props.peerReview.denied)
			this.props.remote.get("/client/review/peer/" + documentIdentifier + "/" + selectedTemplate)
				.then(peerReview => {
					this.props.setPeerReview(peerReview);
				});
	}

	render() {
		const { templates, selectedTemplate, saving, saved, previewLoading, template, selectedTickerGroup, showConfirmOptionModal } = this.state;
		const { compliance, peerReview } = this.props;

		//Publish is enabled if we have a _response_ regardless of yay or nay
		const publishEnabled = (this.complianceReviewAvailable() === false || compliance.responded) && (this.peerReviewAvailable() === false || peerReview.responded) && this.state.me && this.state.me.perms.includes("EDITOR_PUBLISH");
		const publishWithoutReviewEnabled = this.state.template !== null && this.state.tickers && this.state.tickers[0] && this.state.tickers[0] !== "";

		const reviewDisabled = this.reviewDisabled();
		const isSelection = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION;

		return (
			<SidebarExtensionWrapper header={{ header: "Editor", icon: <Edit color="#0E8EFD" />, border: "bottom" }} noMargin collapsable collapsed={false} style={{ paddingBottom: 260 }} shadow sideBarLeft>
				{showConfirmOptionModal &&
					<ConfirmOptionModal
						header="Data Synchronisation"
						message="Select which data you would like to synchronise:"
						handleClose={() => this.setState({showConfirmOptionModal: false})}
						cancelButton={{ onClick: () => this.setState({showConfirmOptionModal: false})}}
						confirmButton={{ variant: "positive", label: "Sync Data", onClick: () => { synchroniseData(); this.setState({showConfirmOptionModal: false}) } }}
						options={[{ label: "Fund Fact Sheet", checked: false, onClick: () => {return null}, disabled: true }, { label: "Quarterly Fund Update", checked: true, onClick: () => {return null}, disabled: true }]}
					/>
				}
				{/* TEAMPLATE */}
				<SideBarSection>
					<DropDown label="Template" clickable fullWidth value={selectedTemplate ? selectedTemplate : "Select Template"} placeholder={selectedTemplate ? false : true}>
						{templates.map((template, index) => (
							<div key={index} onClick={() => this.setState({ selectedTemplate: template }, () => this.onUpdateTemplate(false))}>
								{template}
							</div>
						))}
					</DropDown>
				</SideBarSection>
				{/* TICKERS */}
				<SortableSidebarSection
					separator
					onSortStart={() => (document.body.style.cursor = "grabbing")}
					onSortEnd={this.reorderTicker}
					helperClass="drag-item"
					gap
				>
					{isSelection ? (
						template.ticker.options ? (<>
							{/* Sector Dropdown */}
							<div>
								<label className="label-input">{template.ticker.selectionName}</label>
								<DropDown clickable fullWidth value={selectedTickerGroup && selectedTickerGroup.name}>
									{template.ticker.options.map((option, index) => (
										<li key={index} onClick={() => this.setState({ selectedTickerGroup: option }, this.onUpdateTickerGroup)}>
											{option.name}
										</li>
									))}
								</DropDown>
							</div>
							{selectedTickerGroup ? (
								<FlexWrapper className="mt-l" wrap gap="m">
									{selectedTickerGroup.tickers.map((ticker, idx) => {
										const checked = this.state.tickers.includes(ticker);
										const hasData = !!this.state.tickerMessage[ticker] && this.state.tickerMessage[ticker] !== "No data available";
										// Dummy const for old data
										const dataOutOfDate = "";
										return (
											<TickerItem
												tickerStatus={hasData ? "data-available" : dataOutOfDate ? "data-out-of-date" : "data-unavailable"}
												key={idx}
												index={idx}
												ticker={ticker}
												tickerMessage={this.state.tickerMessage[ticker]}
												hasData={hasData}
												checked={checked}
												loading={this.props.loadingLocalData}
												onChange={() => this.onCheckboxTickerToggle(ticker, checked)}
											/>
										);
									})}
								</FlexWrapper>
							) : null}
						</>) : (
							<div>
								<label className="label-input">{template.ticker.selectionName}</label>
								<DropDown clickable fullWidth value={selectedTickerGroup && selectedTickerGroup.groupName}>
									{this.state.selectionGroups.map((option, index) => (
										<li key={index} onClick={() => this.setState({ selectedTickerGroup: option }, this.onUpdateTickerGroup)}>
											{option.groupName}
										</li>
									))}
								</DropDown>
							</div>
						)
					) : (
						(template && template.ticker.type === TEMPLATE_TICKER_TYPES.SCHEME) ? (
							<div>
							<label className="label-input">{template.ticker.selectionName}</label>
							<DropDown clickable fullWidth value={selectedTickerGroup && selectedTickerGroup.offerName}>
								{this.state.selectionGroups
									.filter((option) => template.ticker?.schemeNumbers?.includes(option.offerNumber))
									.map((option, index) => (
										<li key={index} onClick={() => this.setState({ selectedTickerGroup: option }, this.onUpdateTickerGroup)}>
											{option.offerName}
										</li>
									))}
							</DropDown>
						</div>

						) :
						(template && template.ticker.type !== TEMPLATE_TICKER_TYPES.FIXED) ? this.state.tickers.map((ticker, idx) => {
							// return <input key={idx} type="text" onChange={e => this.onTickerTextChange(e, idx)} onBlur={e => this.updateTickerStatus(true, true)} name="ticker" value={ticker && ticker} placeholder="Enter Ticker..." />
							let label = "Ticker";
							if (template.ticker.label) {
								label = template.ticker.label;
							}
							return (
								<TextInput
									label={label}
									// size="small"
									key={idx}
									onChange={(e) => this.onTickerTextChange(e, idx)}
									onBlur={(e) => this.updateTickerStatus(true)}
									onKeyDown={(e) => this.blurOnEnter(e)}
									name="ticker"
									value={ticker && ticker}
									placeholder={`Enter ${label}...`}
								/>
							);
						}) : null
					)}
					<p className={`label-input ${template && template.ticker.type ? "mt-xl" : ""}`} style={{ marginBottom: 0 }}>Market Data Spreadsheet</p>

					<Dropzone
						onDrop={this.onDrop}
						uploading={this.state.uploading}
						showUploadingSpinner={this.state.showUploadingSpinner}
						uploadingMessage={this.state.uploadingMessage}
					/>
					{this.state.tickerMessage[this.state.tickers[0]] && (
						<AlertBlock
							justify="center"
							className="mt-m"
							type={this.state.tickerMessage[this.state.tickers[0]] !== "No data available" ? "positive" : "warning"}
							small
							fullWidth
						>
							{this.state.tickerMessage[this.state.tickers[0]]}
						</AlertBlock>
					)}
					<Button onClick={() => this.setState({ showConfirmOptionModal: true })}> Synchronise Data</Button>
				</SortableSidebarSection>
				{/* COMPLIANCE */}
				{this.complianceReviewAvailable() && (
					<ReviewButton
						label="Compliance"
						review={compliance}
						disabled={reviewDisabled}
						performReview={this.performComplianceReview}
					/>
				)}
				{/* PEER REVIEW */}
				{this.peerReviewAvailable() && (
					<ReviewButton
						label="Peer Review"
						review={peerReview}
						disabled={reviewDisabled}
						performReview={this.performPeerReview}
					/>
				)}
				{!this.props.fixedFooter && (
					<SideBarFooter
						preview={this.preview}
						publish={this.publish}
						saving={saving}
						saved={saved}
						previewLoading={previewLoading}
						publishEnabled={publishEnabled}
						publishWithoutReviewEnabled={publishWithoutReviewEnabled}
						publishOverride={this.publishOverride}
						publishing={this.state.publishing}
						requiresReview={this.complianceReviewAvailable() || this.peerReviewAvailable()}
					/>
				)}
				{/* FOOTER */}
				{this.props.fixedFooter && (
					<SideBarFooter
						fixed
						preview={this.preview}
						publish={this.publish}
						saving={saving}
						saved={saved}
						previewLoading={previewLoading}
						publishEnabled={publishEnabled}
						publishWithoutReviewEnabled={publishWithoutReviewEnabled}
						requiresReview={this.complianceReviewAvailable() || this.peerReviewAvailable()}
					/>
				)}
			</SidebarExtensionWrapper>
		);
	}
}

export default withStorage(withData(withRouteChange(EditorSideBarExtension)));