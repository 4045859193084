import React from 'react';
import TextArea from '../../../components/Input/TextArea'
import './RiskIndicator.scss'

const FundDescription = (props) => {
    const { description, updateFund, editMode } = props
    return (
        <>
            <div className={`risk-label ${editMode ? '' : 'static-label'}`}>Fund Description</div>
            {editMode ?
                <TextArea
                    placeholder="Add a description"
                    className="add-comment-text-area"
                    value={description}
                    onChange={(e) => updateFund(e.target.value, "fundDescription")}
                    style={{minHeight: 100}}
                /> :
                <div className='fund-description'>{description}</div>
            }
        </>
    )
}

export default FundDescription