import React, { Component } from 'react';
import "./SideBarSection.scss";
import { SortableContainer } from "react-sortable-hoc";
import FlexWrapper from '../../../layouts/FlexWrapper';

class SideBarSection extends Component {
	render() {
		const { header, children, className, noPadding, noContentPadding, separator, fixedHeader, filters, gap } = this.props;
		return (<>
			<div className={`sidebar-section${className ? " " + className : ""}${noPadding || noContentPadding ? " no-padding" : ""}${separator ? " separator" : ""}${fixedHeader ? " fixed-header" : ""}`}>
				<div className="sidebar-section-wrapper">
					{header && <FlexWrapper fullWidth gap="m" justify="space-between" align="center" className={`sidebar-section-header mb-xl${noContentPadding ? " pl-l pr-l" : ""}`}><h4>{header}</h4>{filters && filters}</FlexWrapper>}
					<div className={`sidebar-section-content ${gap ? "gap" : ""}`}>
						{children && children}
					</div>
				</div>
			</div>
			</>
		);
	}
}

export const SortableSidebarSection = SortableContainer(props => <SideBarSection {...props} />);
export default SideBarSection;