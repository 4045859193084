import React, { useEffect, useState } from 'react';
import { Edit2 } from 'react-feather';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useRemote } from '../../../Utils/Utils';
import Divider from '../../../components/Divider';
import Button from '../../../components/Buttons/Button';
import SidebarExtensionHeader from '../../../components/SideBar/SideBarExtension/SidebarExtensionHeader'
import Checkbox from '../../../components/Checkbox';
import { useLocation } from "react-router";
import {
    generateLighterColors, extractColours, extractTypographies, ColourSample, Typography, DividerWidth,
    allCompaniesStyles, extractBrandTypography, getCategoryChartData, getCategoryChartConfig
} from './BrandsStyles'
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import DropDown from '../../../components/DropDown';
import LoadingIcon from '../../Shared/LoadingIcon';
import HejazBanner from '../../../images/hejaz-banner.png'
import './StyleGuide.scss'


const StyleGuide = (props) => {
    const remote = useRemote();
    const location = useLocation();
    const template = 'colonial'
    // const templates = ['threeskye', 'kernel', 'simplicity', 'colonial', 'hejaz']
    
    // const [template, setTemplate] = useState('colonial')
    const [useDefault, setUseDefault] = useState(true)
    const [useCustom, setUseCustom] = useState(false)
    const [brandColours, setBrandColours] = useState([])
    const [allColoursList, setAllColoursList] = useState({})
    const [brandTypography, setBrandTypography] = useState([])
    const [brandDividers, setBrandDividers] = useState([])
    const [allTypographiesList, setAllTypographiesList] = useState({})
    const [pieChartImage, setPieChartImage] = useState(null)
    const [categoryChartImage, setCategoryChartImage] = useState(null)
    const [logoInfo, setLogoInfo] = useState({})
    const [tableStyles, setTableStyles] = useState({})
    const [fontConfig, setFontConfig] = useState({})
    const [loading, setLoading] = useState(true)
    const [pieDataConfig, setPieDataConfig] = useState(null)


    useEffect(() => {
        setLoading(true)
        const path = `/data/templates/${getOption(template, true)}`

        remote.get(path).then(fetchedNote => {
            const styles = fetchedNote.styles
            //colours
            const primaryColour = template === 'kernel' ? '#04796b' : template === 'simplicity' ? '#ff5a01' : template === 'colonial' ? '#224C95' : template === 'hejaz' ? '#D4AE63' : '#5ac1ef'
            const secondaryColour = template === 'kernel' ? '#edf9f5' : template === 'simplicity' ? '#807766' : template === 'colonial' ? '#d81521' : template === 'hejaz' ? '#001E41' : '#0e8efd'
            const baseColor = template === 'kernel' ? '#04796b' : template === 'simplicity' ? '#ff5a01' : template === 'colonial' ? '#224C95' : '#0d8efd'
            const lightestColour = template === 'kernel' ? '#edf9f5' : template === 'simplicity' ? '#edece8' : template === 'colonial' ? '#e9edf4' : '#ebf4ff'
            // banner
            const companyHeader = template === 'kernel' ? 'page-header' : template === 'colonial' ? 'title-page-header' : template === 'hejaz' ? 'title-page-header' : template === 'simplicity' ? 'title-page-header-orange' : 'company-header'
            const subPageHeader = template === 'kernel' ? 'page-sub-header' : template === 'simplicity' ? 'title-page-header' : (template === 'colonial' || template === 'hejaz') ? 'title-page-sub-header' : 'research-header'
            // chart
            const miniDataValue = template === 'threeskye' ? 'mini-data-value' : template === 'simplicity' ? 'body-text' : 'body-text-small'
            const chartText = template === 'threeskye' ? 'mini-data-value' : 'body-text-small'
            const bodyHeader = template === 'kernel' ? 'body-h2' : 'body-header'
            const chartLine1 = template === 'kernel' ? '#73c1c7' : template === 'simplicity' ? '#ff5a01' : template === 'colonial' ? '#d81521' : template === 'hejaz' ? '#D4AE63' : '#004271'
            const chartLine2 = template === 'kernel' ? '#06786b' : template === 'simplicity' ? '#807766' : template === 'colonial' ? '#008fd2' : template === 'hejaz' ? '#001E41' : '#72C1C6'
            // table
            const tableCellValue = template === 'kernel' ? 'table-cell-value-no-bg' : (template === 'colonial' || template === 'hejaz') ? 'table-cell' : 'table-cell-value'
            const tableHeader2 = template === 'simplicity' ? 'top-10-table-cell-value' : 'table-header-two'
            const tableHeader3 = template === 'simplicity' ? 'table-cell-header' : template === 'colonial' ? 'table-header-row' : 'table-header-three'
            const tableHead4 = template === 'kernel' ? 'table-cell-value-white-bg' : template === 'simplicity' ? 'table-cell-alt' : 'table-header-four'
            // body
            const footer = template === 'colonial' ? 'page-footer' : template === 'kernel' ? 'ordered-list' : template === 'hejaz' ? 'notes-text' : 'mini-data-value'
            const bodyH1 = template === 'colonial' ? 'section-header' : template === 'hejaz' ? 'section-header-blue' : 'body-h1'
            const bodyH2 = template === 'colonial' ? 'table-header' : template === 'hejaz' ? 'section-header' : 'body-h2'

            const chartStyles = {
                width: 200,
                height: 132,
                styles: { p: chartText, h1: 'body-header' },
                backgroundColour: '#ffffff',
                gridColour: "#ffffff",
                axisColour: "#2B2E34",
                series: [{ colour: chartLine1 }, { colour: chartLine2 }]
            }

            const dividerStyle = template === 'threeskye' ? fetchedNote.pages[0].content[17].configuration : template === 'hejaz' ? '#96979b' : fetchedNote.pages[0].content[5].configuration // TODO: write a function to finds dividers' styles in the template
            const stylesArray = [
                {
                    category: 'Banner', values: [
                        { ...styles[companyHeader], label: 'Banner Header', keyName: 'bannerHeader' },
                        { ...styles[subPageHeader], label: 'Banner Sub-header', keyName: 'bannerSubheader' },
                    ]
                },
                {
                    category: 'General',
                    values: [
                        { color: primaryColour, label: 'Brand/Primary', keyName: 'brandPrimary' },
                        { color: secondaryColour, label: 'Secondary', keyName: 'secundary' },
                        { ...styles[bodyH1], label: 'Heading 1', keyName: 'heading1', alternativeLabel: 'Headings' },
                        { ...styles[bodyH2], label: 'Heading 2', keyName: 'heading2', dontShowInColour: true },
                        { ...styles['body-h3'], label: 'Heading 3', keyName: 'heading3', dontShowInColour: true },
                        // { ...styles[bodyHeader], label: 'Title', keyName: 'title' },
                        { ...styles['body-text'], label: 'Body Text', keyName: 'bodyText' },
                        { color: dividerStyle.color, thickness: '0.5pt', label: 'Divider', keyName: 'divider' },
                        {
                            color: template === 'colonial' ? '#6C6C6C' : styles[miniDataValue].color,
                            thickness: template === 'colonial' ? '0.75pt' : '1pt',
                            label: template === 'colonial' ? 'Banner Divider' : 'Footer Divider',
                            keyName: 'footerDivider'
                        },
                        { ...styles[footer], label: 'Footer', keyName: 'footer' }
                    ]
                },
                {
                    category: 'Tables',
                    values: [
                        { ...styles['table-header-one'], label: 'Table Header 1', keyName: 'tableHead1' },
                        // { ...styles[tableHeader2], label: 'Table Header 2', keyName: 'tableHead2' },
                        { ...styles[tableHeader2], backgroundColor: '#8EA4C9', label: 'Table Header 2', keyName: 'tableHead2' },
                        { ...styles[tableHeader3], label: 'Table Header 3', keyName: 'tableHead3' },
                        { ...styles[tableHead4], label: 'Table Header 4', keyName: 'tableHead4' },
                        { ...styles['table-highlight-one'], label: 'Row Highlight 1', keyName: 'rowHighlight1' },
                        { ...styles['table-highlight-two'], label: 'Row Highlight 2', keyName: 'rowHighlight2' },
                        { ...styles['table-highlight-line'], label: 'Row Line Highlight', keyName: 'rowHighlightLine' },
                        { ...styles[tableCellValue], backgroundColor: '#fff', label: 'Table Body Cell', keyName: 'tableCell' },
                        // { borderBottomColor: (template === 'colonial' || template === 'hejaz') ? styles['table-header-row'].borderBottomColor : styles['table-border'].borderBottomColor, label: 'Table Border', keyName: 'tableBorder' }
                    ]
                },
                {
                    category: 'Charts',
                    values: [
                        { color: chartStyles.axisColour, label: 'Chart Axis', keyName: 'axis' },
                        { color: chartStyles.backgroundColour, label: 'Chart Background', keyName: 'chartBackground' },
                        { color: chartStyles.gridColour, label: 'Chart Grid', keyName: 'grid' },
                        { color: chartStyles.series[0].colour, label: 'Chart Line/Bar 1', keyName: 'line1' },
                        { color: chartStyles.series[1].colour, label: 'Chart Line/Bar 2', keyName: 'line2' },
                        { ...styles[bodyHeader], label: 'Chart Title', keyName: 'chartTitle' },
                        { ...styles[miniDataValue], label: 'Chart Text', keyName: 'chartText' }
                    ]
                },
                {
                    category: 'Pie Charts',
                    values: template === 'colonial' || template === 'hejaz' ?
                        [
                            { color: template === 'colonial' ? '#008fd3' : '#D4AE63', label: 'Pie Chart Colour1', keyName: 'Colour1' },
                            { color: template === 'colonial' ? '#154a9a' : '#8e5c2d', label: 'Pie Chart Colour2', keyName: 'Colour2' },
                            { color: template === 'colonial' ? '#f06ecd' : '#eccead', label: 'Pie Chart Colour3', keyName: 'Colour3' },
                            { color: template === 'colonial' ? '#d81521' : '#d27d28', label: 'Pie Chart Colour4', keyName: 'Colour4' },
                            { color: template === 'colonial' ? '#60c9d3' : '#4f6ea3', label: 'Pie Chart Colour5', keyName: 'Colour5' },
                            { color: template === 'colonial' ? '#64a85a' : '#94949a', label: 'Pie Chart Colour6', keyName: 'Colour6' },
                            { color: template === 'colonial' ? '#7961a2' : '#001E41', label: 'Pie Chart Colour7', keyName: 'Colour7' },
                            { color: '#fff', label: 'Pie Chart background', keyName: 'pieChartBackground' }
                        ] :
                        [
                            { color: baseColor, label: 'Pie Chart Base', keyName: 'baseColour' },
                            { color: lightestColour, label: 'Pie Chart last', keyName: 'lightestColour' }, // #8ea4c9
                            { color: '#fff', label: 'Pie Chart background', keyName: 'pieChartBackground' }
                        ]
                }
            ]

            const coloursList = extractColours(stylesArray)
            const typographiesList = extractTypographies(stylesArray, template)
            const typography = template === 'colonial' ? extractBrandTypography(stylesArray, template) : extractBrandTypography(stylesArray, template)
            const dividerThinknes = [stylesArray[1].values[7], stylesArray[1].values[8]]
            const colourArray = template === 'hejaz' ?
                ['#D4AE63', '#8e5c2d', '#eccead', '#d27d28', '#4f6ea3', '#94949a', '#001E41'] : template === 'colonial' ?
                    ['#008fd3', '#154a9a', '#f06ecd', '#d81521', '#60c9d3', '#64a85a', '#7961a2']
                    : generateLighterColors(stylesArray[4].values[0].color, stylesArray[4].values[1].color)
            const fontArray = Object.values(fetchedNote.fonts)
            const fonts = fontArray.map(obj => { return { family: obj.name, url: obj.importLink } })
            const fontsConfiguration = { custom: { families: fonts.map(f => f.family), urls: fonts.map(f => f.url) } }

            const tableStyleObject = {
                font: { fontFamily: typographiesList['tableCell'].fontFamily, fontSize: typographiesList['tableCell'].size },
                row1: { backgroundColor: coloursList['tableHead1Background'], color: coloursList['tableHead1Text'] },
                row2: { backgroundColor: coloursList['tableHead2Background'], color: coloursList['tableHead2Text'], borderBottom: coloursList['tableHead2Background'] === coloursList['tableHead3Background'] ? 'solid 1px #ffffff' : null },
                row3: { backgroundColor: coloursList['tableHead3Background'], color: coloursList['tableHead3Text'] },
                row4: { backgroundColor: coloursList['tableHead4Background'] || coloursList['tableCellBackground'], color: coloursList['tableHead4Text'] || coloursList['tableCellText'] },
                default: { backgroundColor: coloursList['tableCellBackground'], color: coloursList['tableCellText'] },
                heightlight1: { backgroundColor: coloursList['rowHighlight1Background'], color: coloursList['rowHighlight1Text'] },
                heightlight2: { backgroundColor: coloursList['rowHighlight2Background'], color: coloursList['rowHighlight2Text'] }
            }
            setBrandColours(stylesArray)
            setAllColoursList(coloursList)
            setAllTypographiesList(typographiesList)
            setBrandTypography(typography)
            setBrandDividers(dividerThinknes)
            setFontConfig(fontsConfiguration)
            setLogoInfo(allCompaniesStyles[template].logoInfo)
            setTableStyles(tableStyleObject)

            const categoryChartConfig = getCategoryChartConfig(chartStyles.styles, coloursList)
            const categoryChartData = getCategoryChartData(categoryChartConfig)

            const pieDataConfiguration = {
                width: 360,
                height: 200,
                styles: { legend: 'page-sub-header' },
                backgroundColour: '#ffffff',
                legendColour: '#22495f',
                colours: colourArray,
                title: { value: '' },
                data: { value: '${WhereGraph}' }
            }

            const pieChartData = {
                "data": {
                    "data": {
                        "TICKERGROUP": "Balanced Fund",
                        "TICKER": "Balanced",
                        "HISTORICPRICESREFERENCEDATE": "2023-10-01",
                        "HISTORICVALUATIONS": "[15123.0,14123.0,13000.0,13111.0,12123.0,12111.0,12007.0,12009.0,11888.0,11777.0,11666.0,11555.0,11565.0,11500.0,11400.0,11300.0,11450.0,11400.0,11350.0,11250.0,11200.0,11280.0,11100.0,11000.0,11008.0,10998.0,10990.0,10890.0,10790.0,11001.0,10990.0,10800.0,10700.0,10550.0,10720.0,10500.0,10400.0,10300.0,10305.0,10350.0,10300.0,10250.0,10000.0]",
                        "WHEREGRAPH": "{\"Australian Fixed Interest\":34.3,\"International Fixed Interest\":18.6,\"Cash\":15.1,\"Australian Equities\":12.7,\"International Equities\":14.4,\"Property\":1.9,\"Other\":3.1}",
                        "WHATGRAPH": "{\"Growth\":60.0,\"Income\":40.0}",
                        "TICKERLIST": "Balanced Fund"
                    }
                },
                "className": "PieChart",
                "dataSuffix": null,
                "configuration": JSON.stringify(pieDataConfiguration)
            };

            setPieDataConfig(pieDataConfiguration)

            if (colourArray && colourArray.length > 0 && pieChartData && chartStyles.styles && chartStyles.styles.h1) {
                remote.post("/ssrender/Kernel Fund Fact Sheet", pieChartData).then(resp => {
                    setPieChartImage(resp.data);
                    setLoading(false)
                }).catch(() => setPieChartImage(null))

                remote.post("/ssrender/Company Note", categoryChartData).then(resp => {
                    setCategoryChartImage(resp.data);
                }).catch(() => setCategoryChartImage(null))
            }
        })

    }, [location.pathname, template])

    function getOption(string, path) {
        switch (string) {
            case 'kernel':
                return path ? 'Kernel Fund Fact Sheet' : 'Kernel Wealth'
            case 'simplicity':
                return path ? 'Simplicity QFU' : 'Simplicity Wealth'
            case 'colonial':
                return path ? 'CFS Bulletin' : 'Colonial First State'
            case 'hejaz':
                return path ? 'Hejaz Fund Update' : 'Hejaz Financial Services'
            default:
                return path ? 'Company Note' : '3SKYE'
        }
    }


    return (
        <Page noBanner noOverflownY style={{ paddingLeft: '1rem' }}>
            <PageContentBodyGrid rowGap='m' gridColCount='4' paddingTop='0rem' subGrid gridTemplateColumns='270px 21cm' noOverflownY>
                <PageGridItem container className='second-sidebar-left'>
                    {/* <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Template</div>
                        <DropDown editMode={true} onChange={(e) => null} value={getOption(template)}>
                            {templates.map((t, index) => <div key={index} onClick={() => setTemplate(t)}>{getOption(t)}</div>)}
                        </DropDown>
                    </div> */}
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Settings</div>
                        <Checkbox disabled checked={useDefault} label='Use Default' onChange={() => { setUseDefault(!useDefault); setUseCustom(!useCustom) }} />
                        <Checkbox disabled checked={useCustom} label='Use Custom' onChange={() => { setUseCustom(!useCustom); setUseDefault(!useDefault) }} />
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Logo</div>
                        <label className='colour-text-label-grey'>{'Recommended size ' + logoInfo.recommendedSize}</label>
                        <div style={logoInfo.imageContainerStyle}>
                            <img style={logoInfo.imageStyle} src={logoInfo.src} alt="logo" id="image-template-logo" />
                        </div>
                        <Button disabled style={{ width: 'fit-content' }}>Upload</Button>
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Colours</div>
                        {brandColours && brandColours.length > 0 && brandColours.map((obj, index) => {
                            return (
                                <div className='sample-group' key={obj.category + "-" + index}>
                                    <label className='colour-text-label-grey'>{obj.category}</label>
                                    {obj.values.map((val, idx) => {
                                        return (
                                            obj.category === 'Tables' ?
                                                <>
                                                    {val.color && <ColourSample colour={val.color} label={val.label + ' Text'} key={`key-${idx}-${val.color}`} />}
                                                    {val.backgroundColor && <ColourSample colour={val.backgroundColor} label={val.label + ' Background'} key={`key-${idx}-${val.backgroundColor}`} />}
                                                    {val.borderBottomColor && !val.color && <ColourSample colour={val.borderBottomColor} label={val.label} key={`key-${idx}-${val.borderBottomColor}`} />}
                                                </>
                                                : <ColourSample colour={val.color} label={val.label} key={`key-${idx}-${val.color}`} alternativeLabel={val.alternativeLabel} dontDisplay={val.dontShowInColour} />
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Typography</div>
                        {brandTypography.map((obj, idx) => {
                            return (
                                <Typography label={obj.label} passedSize={obj.size} passedWeight={obj.weight} passedFont={obj.fontFamily} fontConfig={fontConfig} key={`key-${idx}-${obj.label}`} />
                            )
                        })}
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Misc</div>
                        {brandDividers.map((obj, idx) => {
                            return (
                                <DividerWidth passedWidth={obj.thickness} label={obj.label} key={`key-${idx}-${obj.label}`} />
                            )
                        })}
                    </div>
                    <div className='second-sidebar-left-container no-border'>
                        <Button disabled>Save Changes</Button>
                        <Button disabled variant='secondary'>Save as Default</Button>
                    </div>
                </PageGridItem>
                <PageGridItem container className='rest-of-the-page-width-second-sidebar-left'>
                    <PageContentBanner divider gap='m' dividerThinkness='m' style={{ height: 80 }}>
                        <SidebarExtensionHeader header='Style Guide' icon={<Edit2 color='#0E8EFD' />} style={{ marginBottom: 0, paddingRight: 0 }} noTabs />
                        <Divider vertical height='24px' marginTop='0.25rem' />
                        <Button disabled >Save changes</Button>
                        <Button disabled variant='secondary' >Save as Default</Button>
                    </PageContentBanner>
                    {loading ? <LoadingIcon centered /> :
                        <PageContentBodyGrid paddingTop='0px'>
                            <WebfontLoader onStatus={() => { }} config={fontConfig}>
                                <PageGridItem colSpan='12' >
                                    <div className='sample-content-section'>
                                        <label className='colour-text-label-grey'>Logo + Banner</label>
                                        {template === 'hejaz' ?
                                            <div style={{ height: 278 }}>
                                                <img src={HejazBanner} className='sample-banner-background' />
                                                <div className='sample-banner-with-logo' style={logoInfo.logoInBanner}>
                                                    {allTypographiesList.bannerHeader && allColoursList && <div className='sample-banner-text'>
                                                        <div style={{ color: allColoursList.bannerHeader, fontSize: template === 'simplicity' ? '40pt' : allTypographiesList.bannerHeader.size, fontFamily: allTypographiesList.bannerHeader.fontFamily, fontWeight: 'regular' }}>Hejaz Equities Fund (Managed Fund)</div>
                                                        <div style={{ color: allColoursList.bannerSubheader, fontSize: template === 'simplicity' ? '24pt' : allTypographiesList.bannerSubheader.size, fontFamily: allTypographiesList.bannerSubheader.fontFamily, fontWeight: allTypographiesList.bannerSubheader.weight, textAlign: 'left', paddingTop: '1rem' }}>Lorem ipsum dolor sit amet</div>
                                                    </div>}
                                                    <img style={logoInfo.imageStyle} src={logoInfo.src} alt="logo" id="image-template-logo" />
                                                </div>
                                            </div> :
                                            <div className='sample-banner-with-logo' style={logoInfo.logoInBanner}>
                                                <img style={logoInfo.imageStyle} src={logoInfo.src} alt="logo" id="image-template-logo" />
                                                {allTypographiesList.bannerHeader && allColoursList && <div className='sample-banner-text'>
                                                    <div style={{ color: allColoursList.bannerHeader, fontSize: template === 'simplicity' ? '40pt' : allTypographiesList.bannerHeader.size, fontFamily: allTypographiesList.bannerHeader.fontFamily, fontWeight: allTypographiesList.bannerHeader.weight }}>Banner Header</div>
                                                    <div style={{ color: allColoursList.bannerSubheader, fontSize: template === 'simplicity' ? '24pt' : allTypographiesList.bannerSubheader.size, fontFamily: allTypographiesList.bannerSubheader.fontFamily, fontWeight: allTypographiesList.bannerSubheader.weight }}>Lorem ipsum dolor sit amet</div>
                                                </div>}
                                            </div>
                                        }
                                    </div>
                                    <div className={`sample-content-section ${template}`}>
                                        <label className='colour-text-label-grey'>Typography</label>
                                        {allTypographiesList.heading1 && <h1 style={{ color: allColoursList.heading1, fontSize: allTypographiesList.heading1.size, fontFamily: allTypographiesList.heading1.fontFamily, fontWeight: allTypographiesList.heading1.weight }}>Heading 1</h1>}
                                        {allTypographiesList.bodyText && <p style={{ color: allColoursList.bodyText, fontSize: allTypographiesList.bodyText.size, fontFamily: allTypographiesList.bodyText.fontFamily, paddingBottom: template === 'colonial' ? 10 : null }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget dignissim est. Fusce at auctor lacus. Ut et ultrices turpis, in vestibulum lorem. Praesent
                                            consectetur lorem eu turpis scelerisque, vitae aliquam tellus pretium. Suspendisse maximus arcu id lorem consectetur pharetra sit amet sit amet nisl. Mauris
                                            vulputate sit amet lacus non porttitor.
                                        </p>}
                                        {(allTypographiesList.heading2 || allTypographiesList.heading3) && allColoursList && <div className='sample-headings-two-and-three'>
                                            {allTypographiesList.heading2 && <div>
                                                <h2 style={{ color: allColoursList.heading2, fontSize: allTypographiesList.heading2.size, fontFamily: allTypographiesList.heading2.fontFamily, fontWeight: allTypographiesList.heading2.weight }}>Heading 2</h2>
                                                <p style={{ color: allColoursList.bodyText, fontSize: allTypographiesList.bodyText.size, fontFamily: allTypographiesList.bodyText.fontFamily, lineHeight: "1.2", paddingBottom: template === 'colonial' ? 10 : null }}>Lorem ipsum</p>
                                            </div>}
                                            {allTypographiesList.heading3 && <div>
                                                <h2 style={{ color: allColoursList.heading3, fontSize: allTypographiesList.heading3.size, fontFamily: allTypographiesList.heading3.fontFamily, fontWeight: allTypographiesList.heading3.weight }}>Heading 3</h2>
                                                <p style={{ color: allColoursList.bodyText, fontSize: allTypographiesList.bodyText.size, fontFamily: allTypographiesList.bodyText.fontFamily, paddingBottom: template === 'colonial' ? 10 : null }}>Lorem ipsum</p>
                                            </div>}
                                        </div>}
                                        <label className='colour-text-label-grey'>Footer Text</label>
                                        {allTypographiesList.footer && <footer style={{ color: allColoursList.footer, fontSize: allTypographiesList.footer.size, fontFamily: allTypographiesList.footer.fontFamily, fontWeight: allTypographiesList.footer.weight }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget dignissim est. Fusce at auctor lacus. Ut et ultrices turpis, in vestibulum lorem. Praesent
                                            consectetur lorem eu turpis scelerisque, vitae aliquam tellus pretium. Suspendisse maximus arcu id lorem consectetur pharetra sit amet sit amet nisl. Mauris
                                            vulputate sit amet lacus non porttitor.
                                        </footer>}

                                    </div>
                                    <div className='sample-content-section double-column' style={{ paddingBottom: '2.5rem' }}>
                                        <div className='sample-chart'>
                                            <label className='colour-text-label-grey'>Line Chart</label>
                                            {categoryChartImage && <img alt="category chart" style={{ width: 360, height: 210, marginTop: '-2rem' }} src={"data:image/png;base64," + categoryChartImage} />}
                                        </div>
                                        <div className='sample-chart'>
                                            <label className='colour-text-label-grey'>Pie Chart</label>
                                            {pieChartImage && <img alt="pie chart" style={{ width: pieDataConfig.width, height: pieDataConfig.height, marginBottom: '-0.5rem' }} src={"data:image/png;base64," + pieChartImage} />}
                                        </div>
                                    </div>
                                    <div className='sample-content-section' style={{ position: 'relative', marginTop: '-1.5rem', borderTop: 'solid 1.5px #E7EAF2', backgroundColor: '#fff' }}>
                                        <label className='colour-text-label-grey'>Table Col/Row</label>
                                        <div className='sample-tables'>
                                            <table className='sample-table' style={tableStyles.font}>
                                                <thead>
                                                    <tr style={tableStyles.row1}>
                                                        <th>Header 1</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={tableStyles.row2}>
                                                        <td>Header 2</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                    <tr style={tableStyles.row3}>
                                                        <td>Header 3</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                    <tr style={tableStyles.row4}>
                                                        <td>Header 4</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {template !== 'simplicity' && template !== 'colonial' && <table className='sample-table' style={tableStyles.font}>
                                                <thead>
                                                    <tr style={tableStyles.row1}>
                                                        <th>Table Heading</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={tableStyles.default}>
                                                        <td>Default Value</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                    </tr>
                                                    <tr style={tableStyles.heightlight1}>
                                                        <td>Highlight 1</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                    </tr>
                                                    <tr style={tableStyles.heightlight2}>
                                                        <td>Highlight 2</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>}
                                        </div>
                                    </div>
                                </PageGridItem>
                            </WebfontLoader>
                        </PageContentBodyGrid>
                    }
                </PageGridItem>

            </PageContentBodyGrid>
        </Page >
    )
}

export default StyleGuide;