import React, { Component } from 'react';
import { Upload, FilePlus } from 'react-feather';
import FileDrop from 'react-file-drop';

import "./Dropzone.scss";

class Dropzone extends Component {
	render() {
		return (
			<FileDrop
				onDrop={this.props.onDrop}
				onDragOver={(e) => {
					let event = e;
					event.stopPropagation();
					event.preventDefault();
				}}
			>
				<div className={`dropzone${this.props.uploading ? " uploading" : ""}${this.props.className ? " " + this.props.className : ""}${this.props.small ? " dropzone-small" : ""}${this.props.hidden ? " dropzone-hidden" : ""}`} onClick={() => this.fileInput.click()}>
					{this.props.uploading ? (
						<div className="uploading-message">
							{this.props.showUploadingSpinner && <div className="sp-circle" />}
							{this.props.uploadingMessage}
						</div>
					) : (
						<>
							<div style={{display: "flex", alignItems: "center", gap: 7}}>
								{!this.props.small && <FilePlus className="upload-icon" size={18}/>}
								<div className="dropzone-message">{this.props.displayMessage ? this.props.displayMessage : "Drop or click to add files here"}</div>
							</div>

							<input
								type="file"
								style={{ display: "none" }}
								onChange={e => { this.props.onDrop(e.target.files) }}
								ref={el => this.fileInput = el}
								multiple
							/>
						</>
					)}
				</div>
			</FileDrop>);
	}
}

export default Dropzone;