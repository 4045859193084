import React, { useEffect, useState } from 'react';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridDivider from '../../Shared/Page/PageGridDivider'
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Table from '../../../components/Table/Table';
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import withRouteChange from "@threeskye/route-change"
import TickerSectorItem from '../../../components/TickerSectorItem';
import FlexWrapper from '../../FlexWrapper'
import LoadingIcon from '../../Shared/LoadingIcon'
import Tooltip from '../../../components/popups/Tooltip';
import { Send, AlertCircle, Clock, BookOpen, Eye, Mail, Users, ChevronUp, ChevronDown } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';


const AnalyticsGroupList = ({ changeRoute }) => {
	const remote = useRemote();

	const [hasSearchTerm, setHasSearchTerm] = useState("")
	const [filteredData, setFilteredData] = useState([])
	const [loading, setLoading] = useState(true)
	const [aggregates, setAggregates] = useState([]);
	const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: true })
	const [dataDisplaying, setDataDisplaying] = useState(null)

	// Metrics
	const mostPortalViews = (aggregates && aggregates.length > 0 && aggregates.toSorted((a, b) => b.portalViews - a.portalViews)[0]) || ""
	const mostEmailViews = (aggregates && aggregates.length > 0 && aggregates.toSorted((a, b) => b.emailsRead - a.emailsRead)[0]) || ""
	const mostWatchedlisted = (aggregates && aggregates.length > 0 && aggregates.toSorted((a, b) => b.watchlists - a.watchlists)[0]) || ""

	function displayHeaderTicker(group, property) {
		if (group && group[property] === 0) return <h1>-</h1>
		else if (group) return <TickerSectorItem labelSize="header-1" imageSize={32} label={group.tickerSymbol} image={`/api/public/images/group/${group.groupId}.png`} />
		else return <h1>&nbsp;</h1>
	}

	// get groups
	useEffect(() => {
		remote.get(`/analytics/aggregates/groups`).then((fetchedAggregates) => {
			const sortedAgregates = fetchedAggregates && fetchedAggregates.length > 0 && fetchedAggregates.sort((a, b) => a.tickerSymbol.localeCompare(b.tickerSymbol))
			if (sortedAgregates.length > 0) setAggregates(sortedAgregates)
		}).then(() => setLoading(false));
	}, [])

	// Sorting
	useEffect(() => {
		sortTable(null)
	}, [aggregates, filteredData])

	const sortTable = (column) => {
		if (column === null) {
			setDataDisplaying(hasSearchTerm ? filteredData : aggregates)
			return
		}
		let tableData = hasSearchTerm ? filteredData : aggregates
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Name":
				tableData.sort((a, b) => ascending ? a.tickerSymbol.localeCompare(b.tickerSymbol) : b.tickerSymbol.localeCompare(a.tickerSymbol))
				break;
			case "Minutes":
				tableData.sort((a, b) => ascending ? a.minutes - b.minutes : b.minutes - a.minutes)
				break;
			case "ResearchPublished":
				tableData.sort((a, b) => ascending ? a.researchPublished - b.researchPublished : b.researchPublished - a.researchPublished)
				break;
			case "Watchlisted":
				tableData.sort((a, b) => ascending ? a.watchlists - b.watchlists : b.watchlists - a.watchlists)
				break;
			case "Read":
				tableData.sort((a, b) => ascending ? a.emailsRead - b.emailsRead : b.emailsRead - a.emailsRead)
				break;
			case "PortalViews":
				tableData.sort((a, b) => ascending ? a.portalViews - b.portalViews : b.portalViews - a.portalViews)
				break;
			case "PDFDownloads":
				tableData.sort((a, b) => ascending ? (a.portalPdfDownloads + a.emailPdfDownloads) - (b.portalPdfDownloads + b.emailPdfDownloads) : (b.portalPdfDownloads + b.emailPdfDownloads) - (a.portalPdfDownloads + a.emailPdfDownloads))
				break;
			case "ModelDownloads":
				tableData.sort((a, b) => ascending ? a.dataDownloads - b.dataDownloads : b.dataDownloads - a.dataDownloads)
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setDataDisplaying(tableData)
	}

	// table data
	const colWidths = ["auto", "70px", "70px", "70px", "70px", "70px", "70px", "70px"];
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Name")} >
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<Tooltip label="Minutes">
					<div className='sorting-header' onClick={() => sortTable("Minutes")}>
						<Clock className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			// className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Research Published">
					<div className='sorting-header' onClick={() => sortTable("ResearchPublished")}>
						<BookOpen className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "ResearchPublished" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "ResearchPublished" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			// className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Watchlisted">
					<div className='sorting-header' onClick={() => sortTable("Watchlisted")}>
						<Users className="colour-text-positive-dark" size={16} />
						{sortingColumn && sortingColumn.column === "Watchlisted" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Watchlisted" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			)
		},
		{
			text: (
				<Tooltip label="Read">
					<div className='sorting-header' onClick={() => sortTable("Read")}>
						<Mail className="colour-text-purple" size={16} />
						{sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<div className='sorting-header' onClick={() => sortTable("PortalViews")}>
						<Eye className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			)
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<div className='sorting-header' onClick={() => sortTable("PDFDownloads")}>
						<PdfIcon noHover size={16} />
						{sortingColumn && sortingColumn.column === "PDFDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PDFDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			)
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<div className='sorting-header' onClick={() => sortTable("ModelDownloads")}>
						<XlsIcon noHover size={16} />
						{sortingColumn && sortingColumn.column === "ModelDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "ModelDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			)
		}
	]

	const dataMap = (data) => data.map((group, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(`${ROUTE_PATHS[PAGES.ANALYTICS]}/group/${group.groupId}`)}>
				<td>
					<TickerSectorItem size="m" label={group.tickerSymbol} extensionLabel={group.tickerName} image={`/api/public/images/group/${group.groupId}.png`} />
				</td>
				<td><div className="icon-counter-count">{group.minutes}</div></td>
				<td><div className="icon-counter-count">{group.researchPublished || "-"}</div></td>
				<td><div className="icon-counter-count">{group.watchlists}</div></td>
				<td><div className="icon-counter-count">{group.emailsRead}</div></td>
				<td><div className="icon-counter-count">{group.portalViews}</div></td>
				<td><div className="icon-counter-count">{group.portalPdfDownloads + group.emailPdfDownloads}</div></td>
				<td><div className="icon-counter-count">{group.dataDownloads}</div></td>
			</tr>
		)
	});


	return (
		<Page noBanner showScrollbar>
			<PageContentBodyGrid rowGap="xl" showScrollbar>
				<PageGridItem colSpan="12">
					<FlexWrapper gap="xl" style={{ paddingBottom: "1rem" }}>
						<FlexWrapper gap="xs" direction="column" align="flex-start" >
							<label className='total-metrics-label'>Total Groups</label>
							{loading ? <LoadingIcon size={32} /> : <h1 className='total-metrics-value'>{aggregates.length}</h1>}
						</FlexWrapper>
						<Tooltip disabled={mostPortalViews && mostPortalViews.portalViews === 0} label={mostPortalViews && `${mostPortalViews.portalViews} views`}>
							<FlexWrapper gap="xs" direction="column" align="flex-start" >
								<label className='total-metrics-label'>Most Portal Views</label>
								{loading ? <LoadingIcon size={32} /> : displayHeaderTicker(mostPortalViews, "portalViews")}
							</FlexWrapper>
						</Tooltip>
						<Tooltip disabled={mostEmailViews && mostEmailViews.emailsRead === 0} label={mostEmailViews && `${mostEmailViews.emailsRead} views`}>
							<FlexWrapper gap="xs" direction="column" align="flex-start" >
								<label className='total-metrics-label'>Most Email Views</label>
								{loading ? <LoadingIcon size={32} /> : displayHeaderTicker(mostEmailViews, "emailsRead")}
							</FlexWrapper>
						</Tooltip>
						<Tooltip disabled={mostWatchedlisted && mostWatchedlisted.watchlists === 0} label={mostWatchedlisted && `Watchlisted by ${mostWatchedlisted.watchlists} users`}>
							<FlexWrapper gap="xs" direction="column" align="flex-start" >
								<label className='total-metrics-label'>Most Watchlisted</label>
								{loading ? <LoadingIcon size={32} /> : displayHeaderTicker(mostWatchedlisted, "watchlists")}
							</FlexWrapper>
						</Tooltip>
					</FlexWrapper>
					<PageGridDivider />
					<PageGridItem style={{ paddingTop: "1.5rem" }}>
						<SearchTermFilter
							size="small"
							width="l"
							placeholder={`Search`}
							isClearable
							dataSet={aggregates}
							setFilteredData={setFilteredData}
							path={["tickerName"]}
							hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
						/>
					</PageGridItem>

					<div className="table-title">All Groups</div>
					{/* {showRecentlyAdded || (!isSectorList && tickerSearchTerm) || (isSectorList && hasSearchTerm) ? ( */}
					{loading ? <LoadingIcon centered size={48} /> :
						<Table
							minWidth={900}
							tableStyle={{ maxWidth: 1200 }}
							colWidths={colWidths}
							headers={headers}
							dataMap={dataMap(dataDisplaying)}
							noResults={{ message: "No groups found" }}
						/>
					}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AnalyticsGroupList);