import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions, { useRemote, undoDeleteDistributionList, useStorage } from '../../../Utils/Utils';
import { useHistory, useParams } from 'react-router-dom';
import DistributionListDetails from './DistributionListDetails';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import DistributionUsersList from './DistributionMembersList';
import MiniTickerList from '../../Shared/TablesAndLists/MiniTickerList';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import { toastDanger, toast, UndoToastMessage } from '../../../components/popups/Toast';
import { toastSuccess } from '../../../components/popups/Toast'


const blankList = {
	"list": {
		"id": null,
		"companyId": null,
		"ticker": "",
		"description": null,
		"createdBy": null,
		"createdDate": [
			null,
			null,
			null
		]
	},
	"contacts": [
		{
			"id": null,
			"companyId": null,
			"email": "",
			"firstName": "",
			"lastName": "",
			"preferredName": null,
			"role": null,
			"location": null,
			"organisationId": null,
			"keyContact": false
		}
	]
}

const DistributionListPage = ({ changeRoute }) => {
	const [editMode, setEditMode] = useState(false)
	const [createMode, setCreateMode] = useState(false)
	const [list, setList] = useState(blankList)
	const [contacts, setContacts] = useState([])
	const [tickers, setTickers] = useState([])
//	const [showModal, setShowModal] = useState(false);
	const { width } = useWindowDimensions()
	const { distributionListId } = useParams()
	const remote = useRemote()
	const storage = useStorage()
	const history = useHistory()

	useEffect(() => { createMode === true && setEditMode(true) }, [createMode])

	const params = new URLSearchParams(window.location.search);
	const createQuery = params.get('create');
	const editQuery = params.get('edit');


	useEffect(() => {
		switch (distributionListId) {

			case "create":
				setCreateMode(true)
				break;
			default:
				remote.get("/crm/lists/" + distributionListId).then(data => { setList(data.data.list); setContacts(data.data.contacts); setTickers(data.data.tickers) });
				if (createQuery) {
					setCreateMode(true)
				}
				if (editQuery) {
					setEditMode(true)
				};
		}
	}, [distributionListId, createQuery, editQuery])

	const editList = () => {
		remote.put("/crm/lists/" + distributionListId, list).then((response => {
			setEditMode(false)
		})).then(() => {
			storage.refresh("/crm/lists")
			toastSuccess("Distribution List updated")
		});
	};
	const createList = () => {
		//save list
		remote.post("/crm/lists", { name: list.name }).then((response => {
			//save tickers
			tickers.forEach((ticker) => {
				remote.put(`/crm/lists/${response.data.id}/tickers/${ticker.id}`)
			})
			//save members
			contacts.forEach(contact => {
				remote.put(`/crm/lists/${response.data.id}/members/${contact.id}`)
			})
			//redirect to new list page
			history.push(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + response.data.id)
			storage.refresh("/crm/lists")
			setList(response.data)
			setCreateMode(false)
			toastSuccess("Distribution List created")

		}));
	}
	const returnToPage = (deletedList) => {changeRoute(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + deletedList.id)}

	const removeList = (e) => {
		e.stopPropagation();
		remote.delete(`/crm/lists/${list.id}`)
			.then(() => {
				// toastSuccess("List removed")
				toast(<UndoToastMessage message="Distribution List removed" onClick={() => { undoDeleteDistributionList(list, tickers, contacts, returnToPage )}} />, {autoClose: 10000})
				changeRoute(ROUTE_PATHS[PAGES.CRM] + "/distribution")
			})
			.catch(() => {
				toastDanger("List could not be removed")
			})
	}

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					// handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/distribution")}
					hideBackButton
					editMode={editMode}
					createMode={createMode}
					edit={{ onClick: () => setEditMode(true) }}
					duplicate={{ onClick: () => setCreateMode(true) }}
					remove={{ onClick: (e) => removeList(e) }}
					cancel={{ onClick: () => (createMode ? changeRoute(`${ROUTE_PATHS[PAGES.CRM]}/distribution/`) : setEditMode(false)) }}
					save={{
						onClick: () => {
							if (createMode) createList()
							else editList()
						}
					}}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the user details section */}
				<PageGridItem container col="1 / span 6" showScrollbar>
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<DistributionListDetails editMode={editMode} list={list} editList={(content) => setList(content)} />
						{/* Divider */}
						<PageGridDivider col="1 / span 6" />
						{/* Tickers */}
						{!createMode && <MiniTickerList createMode={createMode} data={tickers} setData={setTickers} list={list} />}
					</PageContentBodyGrid>
				</PageGridItem>
				{/* Members list */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid showScrollbar rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"}>
						{!createMode && <DistributionUsersList createMode={createMode} list={list} editMode={editMode} members={contacts} setMembers={setContacts} />}
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}
export default withRouteChange(DistributionListPage);