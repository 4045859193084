import React, { Component, Suspense } from 'react';
import AbsoluteComponent from './AbsoluteComponent.js';
import FlowingPage from './FlowingPage';
import WebfontLoader from '@dr-kobros/react-webfont-loader';

import './sheets-of-paper.css';
import './sheets-of-paper-a4.css';
import './Page.scss';
import { withData } from './DataController.js';

class Page extends Component {

	constructor(props) {
		super(props);


		//we receive props:
		//name, fonts, styles, pages


		this.fontConfig = {
			custom: {
				families: Object.keys(this.props.template.fonts),
				urls: Object.values(this.props.template.fonts).map(font=>font.importLink)
			}
		}

		this.state = {
			template: this.createTemplateFromPage(),
			tickers: [],
			templatesWithSuffixes: [],
			show: true
		}

		
		this.checkSave = this.checkSave.bind(this);
		this.createTemplateFromPage = this.createTemplateFromPage.bind(this);
		this.tickerListener = this.tickerListener.bind(this);
		this.localListener = this.localListener.bind(this);
	}

	createTemplateFromPage(dataSuffix) {
		const { output } = this.props;
		return this.props.page.content.filter(c=>!output||!c.applyTo||c.applyTo.includes(output)).map(content => {
			const config = content.configuration;
			const Component = React.lazy(()=>import("./contents/" + content.className));
			
			return {
				x: content.location.left,
				y: content.location.top,
				content: <Suspense fallback={<div>...</div>}><Component config={config} styles={this.props.template.styles} datasets={this.props.template.datasets} dataSuffix={dataSuffix ? dataSuffix : undefined} /></Suspense>
			}
		});

	}

	checkSave() {
		// if (this.state.tickerInvalidOrInFlux) {
		// 	return;
		// }

		// if (this.state.needsSave) {
		// 	this.props.onPreview(this.createDTO());
		// 	this.setState({needsSave: false});
		// }

	}

	componentDidUpdate(props) {
		if (props.template?.name !== this.props.template?.name) {
			this.setState({template:this.createTemplateFromPage()});
		} else if (props.page.length !== this.props.page.length) {	//FIXME - need a better compare, eg. id on every page.
			this.setState({template:this.createTemplateFromPage()});
		}
	}
	
	componentDidMount() {
		this.autosave = window.setInterval(this.checkSave, 30000);
		this.props.data.addTickerListener(this.tickerListener);
		this.props.data.addLocalListener(this.localListener);
		this.tickerListener();
	}

	componentWillUnmount() {
		window.clearInterval(this.autosave);
		this.props.data.removeTickerListener(this.tickerListener);
		this.props.data.removeLocalListener(this.localListener);
	}

	fontCallback(status) {
//		console.log('font callback status ', status);
	}

	tickerListener(tickers) {
		const templatesWithSuffixes = tickers ? tickers.map((ticker, idx) => this.createTemplateFromPage("<"+ticker+">")) : [];
		this.setState({tickers, templatesWithSuffixes});
	}

	localListener() {
		if (this.props.page.optional) {
			const optionalPages = this.props.data.getLocalValue("OPTIONAL_PAGES");
			if (optionalPages && !JSON.parse(optionalPages).includes(this.props.page.name)) {
				this.setState({show: false});
			} else {
				this.setState({show: true});
			}
		}
		return new Promise((resolve) => resolve(true));
	}

	render() {
		if (!this.state.show) {
			return null;
		}

		const template = this.state.template.map((comp, idx)=><AbsoluteComponent key={comp.x + "," + comp.y + "," + JSON.stringify(comp.content.props.children.props.config)} {...comp}/>);

		const type = this.props.page.type;
		const orientation = this.props.page.orientation;
		const height = this.props.page.size.height;

		if (type === 'FIXED') {
			return <>
				<WebfontLoader onStatus={this.fontCallback} config={this.fontConfig}>
					<div className={`page ${orientation ? 'orientation-' + orientation : ''}`} style={{"height":`${height}pt`,"min-height":`${height}pt`}}>
						<ErrorHandler>
							{template}
						</ErrorHandler>
						{/*<Editor x={36} y={200}/> */}
					</div>
				</WebfontLoader>
			</>
		} else if (type === 'FLOWING') {
			return <WebfontLoader onStatus={this.fontCallback} config={this.fontConfig}>
				<ErrorHandler>
				<FlowingPage flowingConfig={this.props.page.flowContent} styles={this.props.template.styles} pageSize={this.props.page.size} style={{"height":`${height}pt`,"min-height":`${height}pt`}}>
					{template}
				</FlowingPage>
				</ErrorHandler>
			</WebfontLoader>
		} else if (type === 'PERTICKER') {
			return <>
				{this.state.tickers && this.state.tickers.map((ticker,i) => 
				<WebfontLoader onStatus={this.fontCallback} config={this.fontConfig}>
					<div className="page" >
						<ErrorHandler>
						{this.state.templatesWithSuffixes[i].map((comp, idx)=><AbsoluteComponent dataSuffix={"<"+ticker+">"} key={comp.x + "," + comp.y + "," + JSON.stringify(comp.content.props.children.props.config)} {...comp}/>)}
						</ErrorHandler>
						{/*<Editor x={36} y={200}/> */}
					</div>
				</WebfontLoader>
				)}
			</>
		}
	}

}

class ErrorHandler extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false
		}
	}

	componentDidCatch(error, info) {
		console.log(error)
		this.setState(state => ({ ...state, hasError: true }))
	}

	render() {
		if (this.state.hasError) { return <div>Sorry, an error occurred</div> }

		return this.props.children
	}
}

export default withData(Page);