import React, { Component } from 'react';
import "./Icons.scss";

class PdfIcon extends Component {
	render() {
		/* 		const fillA = { fill: "#C6123D" };
				const fillB = { fill: "#FF6189" };
				const fillC = { fill: "#9F0E30" };
		 */
		const { size, noHover, className, disabled } = this.props;

		return (
			<svg className={`file-icon pdf${noHover ? " no-hover" : ""}${disabled ? " disabled" : ""}${className ? ' ' + className : ''}`} id="Group_6110" data-name="Group 6110" width={size ? size : "24"} height={size ? size : "24"} viewBox="0 0 48 48" style={{width: size ? size : 24}}>
				<g id="Group_5839" data-name="Group 5839" clipPath="url(#clip-path)">
					<path
						id="Path_2264"
						data-name="Path 2264"
						d="M48,9.48a3.268,3.268,0,0,0-.929-2.333,3.147,3.147,0,0,0-2.257-.923h-.026c-3.55.027-7.16.022-10.652.017q-1.95,0-3.9,0,0-.413,0-.827c0-.775,0-1.577-.006-2.368a3.118,3.118,0,0,0-1.1-2.352A2.915,2.915,0,0,0,26.74.045c-2.254.4-4.546.811-6.762,1.208l-3.58.641L8.857,3.239,4.9,3.945l-.489.087c-.389.069-.791.14-1.187.214A3.915,3.915,0,0,0,.876,5.59,4.039,4.039,0,0,0,0,8.188q0,13.335,0,26.67V39.82c0,.144,0,.255.009.362a3.828,3.828,0,0,0,3.132,3.566q4.448.8,8.9,1.594l1.675.3,4.466.8q4.205.751,8.41,1.5A3.465,3.465,0,0,0,27.2,48a2.983,2.983,0,0,0,1.952-.7,3.12,3.12,0,0,0,1.08-2.419c0-.774,0-1.559,0-2.319q0-.387,0-.775H44.768a3.034,3.034,0,0,0,3.219-3.255q0-3.631,0-7.263c0-7.141,0-14.524.014-21.786M30.851,35.235c.392,0,.8,0,1.2,0a1.408,1.408,0,1,0-.027-2.815c-.479,0-.965,0-1.435,0h-.335V30.333h1.594a1.681,1.681,0,0,0,1.237-.455,1.344,1.344,0,0,0,.376-.975,1.432,1.432,0,0,0-1.626-1.4H30.259v-2.08h.5c.418,0,.85,0,1.276,0A1.409,1.409,0,0,0,32.07,22.6c-.48-.011-.964-.009-1.432-.007h-.383V20.508h1.3c.175,0,.356,0,.54,0a1.406,1.406,0,0,0,1.363-1.41,1.39,1.39,0,0,0-1.383-1.4c-.558-.006-1.1,0-1.68,0h-.136V15.61h.365c.452,0,.92,0,1.382,0a1.484,1.484,0,0,0,1.069-.447,1.391,1.391,0,0,0,.382-1A1.416,1.416,0,0,0,32,12.778c-.542,0-1.07,0-1.629,0h-.118V9.057H44.773c.3,0,.363.06.363.06a.692.692,0,0,1,.06.369q0,14.525,0,29.049c0,.387-.026.415-.4.415H30.248V35.233h.6M27.437,19.476q0,2.287,0,4.574V44.685c0,.072,0,.144,0,.215a.322.322,0,0,1-.08.238.309.309,0,0,1-.244.033l-4.16-.746-3.819-.685-6.783-1.212-4.769-.852-.788-.141c-1.035-.185-2.1-.375-3.155-.568a1.042,1.042,0,0,1-.856-1.017c0-.061,0-.126,0-.2V8.34c0-.874.26-1.187,1.111-1.339l7.544-1.349L23.482,3.5l.942-.166c.837-.147,1.7-.3,2.554-.471a.907.907,0,0,1,.177-.02.291.291,0,0,1,.184.047.543.543,0,0,1,.11.389c-.014,5.4-.013,10.89-.011,16.2"
						transform="translate(0 0)"
						fill="#b4b4b4"
					/>
					<path
						id="Path_2266"
						data-name="Path 2266"
						d="M580.169,466.095c.57,0,1.148,0,1.707,0h2.082c.327,0,.657,0,.985,0a1.446,1.446,0,0,0,1.038-.438,1.416,1.416,0,0,0-1.033-2.39h-.125c-1.533-.007-3.119-.014-4.68.012a1.687,1.687,0,0,0-1.043.481,1.294,1.294,0,0,0-.233,1.5,1.379,1.379,0,0,0,1.3.832"
						transform="translate(-544.837 -435.764)"
						fill="#b4b4b4"
					/>
					<path
						id="Path_2267"
						data-name="Path 2267"
						d="M580.369,218.062c.554.006,1.115.005,1.658,0h2.2c.308,0,.618,0,.926-.006a1.4,1.4,0,0,0,1.414-1.4,1.431,1.431,0,0,0-.4-1.009,1.357,1.357,0,0,0-.974-.418c-1.553-.01-3.152-.01-4.887,0a1.373,1.373,0,0,0-1.362,1.265,1.394,1.394,0,0,0,.333,1.078,1.454,1.454,0,0,0,1.095.483"
						transform="translate(-545.03 -202.456)"
						fill="#b4b4b4"
					/>
					<path
						id="Path_2268"
						data-name="Path 2268"
						d="M580.37,383.471q.837,0,1.674,0h2.091l.955,0a1.407,1.407,0,0,0,1.449-1.4,1.392,1.392,0,0,0-.39-.991,1.454,1.454,0,0,0-1.048-.43c-1.513-.008-3.066-.008-4.749,0a1.415,1.415,0,0,0-1.056,2.389,1.49,1.49,0,0,0,1.074.431"
						transform="translate(-545.005 -358.054)"
						fill="#b4b4b4"
					/>
					<path
						id="Path_2269"
						data-name="Path 2269"
						d="M585.117,298.073c-1.456,0-3,0-4.715,0a1.579,1.579,0,0,0-.5.072,1.4,1.4,0,0,0-.936,1.527,1.367,1.367,0,0,0,1.355,1.211c.342,0,.687.005,1.028.005h2.129c.532,0,1.082,0,1.624,0a1.4,1.4,0,0,0,1.479-1.4,1.381,1.381,0,0,0-.388-.984,1.5,1.5,0,0,0-1.082-.433"
						transform="translate(-545.053 -280.381)"
						fill="#b4b4b4"
					/>
					<path
						id="Path_2270"
						data-name="Path 2270"
						d="M585.031,546.13c-1.44,0-2.954,0-4.627,0a1.4,1.4,0,0,0-1.506,1.4,1.376,1.376,0,0,0,.383.983,1.537,1.537,0,0,0,1.11.439l.934,0H583.4q.81,0,1.62,0a1.42,1.42,0,0,0,1.509-1.4,1.374,1.374,0,0,0-.385-.98,1.537,1.537,0,0,0-1.113-.438"
						transform="translate(-544.997 -513.716)"
						fill="#b4b4b4"
					/>
					<g id="Group_6112" data-name="Group 6112" transform="translate(4.631 10.956)">
						<g id="Group_6111" data-name="Group 6111" clipPath="url(#clip-path-2)">
							<path
								id="Path_2568"
								data-name="Path 2568"
								d="M19.565,14.007a2.369,2.369,0,0,0-1.97-.879,12.714,12.714,0,0,0-4.3,1.084.412.412,0,0,1-.586-.181c-.763-1.044-1.449-2.134-1.961-2.967a3.244,3.244,0,0,1-.433-3.337,3.332,3.332,0,0,0,.2-.707c.012-.059.024-.119.038-.177q.056-.246.113-.492a13.542,13.542,0,0,0,.484-3.373A3.032,3.032,0,0,0,9.6.183a2.05,2.05,0,0,0-2.578.828A4.1,4.1,0,0,0,6.5,4.125,22.792,22.792,0,0,0,7.919,9l.019.05a1.127,1.127,0,0,1,.1.749c-.618,2.368-1.24,4.494-1.9,6.5a2.331,2.331,0,0,1-1.38,1.591A13.4,13.4,0,0,0,.971,20.1a3.117,3.117,0,0,0-.947,1.977,3.561,3.561,0,0,0,.485,2.272,2.276,2.276,0,0,0,1.882,1.174h.05a2.3,2.3,0,0,0,1.876-1.267,40.316,40.316,0,0,0,1.977-4.095l.211-.482c.152-.346.26-.528.466-.627a45.915,45.915,0,0,1,5.137-2.226.694.694,0,0,1,.8.168,7.872,7.872,0,0,0,4.361,2.082h.018a2.779,2.779,0,0,0,2.3-.954,3.15,3.15,0,0,0,.63-1.99,3.317,3.317,0,0,0-.653-2.12M18.33,16.743a.681.681,0,0,1-.582.258,6.642,6.642,0,0,1-2.956-1.1,12.671,12.671,0,0,1,2.952-.7.674.674,0,0,1,.593.248,1.012,1.012,0,0,1-.006,1.3m-9.1-4.828c.035.075.071.152.112.228a27.987,27.987,0,0,0,1.8,2.842c-.937.34-1.855.754-2.824,1.191l-.382.172c.463-1.421.884-2.861,1.3-4.434m-.8-9.666a.521.521,0,0,1,.729,0,1.125,1.125,0,0,1,.328,1.1,18.314,18.314,0,0,1-.6,2.964A13.907,13.907,0,0,1,8.1,3.343a1.1,1.1,0,0,1,.322-1.094M4.269,20.411a24,24,0,0,1-1.307,2.6c-.208.348-.444.455-.764.345a.775.775,0,0,1-.513-.734.894.894,0,0,1,.346-.876,12.018,12.018,0,0,1,2.238-1.336"
								transform="translate(0 0)"
								fill="#b4b4b4"
							/>
						</g>
					</g>
				</g>
			</svg>
		);
	}
}
export default PdfIcon;
