import React from "react";
import "../../../layouts/Shared/Banner/TopBannerExtension.scss";

const SidebarExtensionHeader = (props) => {
    const { icon, header, noMargin, border, style, noTabs} = props;
    return (
        <div className={`top-banner-ext-header short-banner${border === "bottom" ? " no-tabs border-bottom" : ""}${noMargin ? " no-margin" : ""}${noTabs ? " no-tabs" : ""}`} style={style}>
            {icon}
            <h3>{header}</h3>
        </div>
    );
}

export default SidebarExtensionHeader;