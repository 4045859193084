import React from "react";
import "./AdminPage.scss";
import { Route, Switch } from "react-router";
import AdminCompanyDisclaimerList from "./AdminCompanyDisclaimersPage/AdminCompanyDisclaimerList";
import AdminCompanyDisclaimersPage from "./AdminCompanyDisclaimersPage/AdminCompanyDisclaimersPage";
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import withRouteChange from "@threeskye/route-change";
import RolePermissionsList from "./RolePermissions/RolePermissionsList";
import UserManagement from "./UserManagement/UserManagement";
import SystemUserPage from "./UserManagement/SystemUserPage";
import TickerList from "./Ticker/TickerList";
import TickerPage from "./Ticker/TickerPage";
import TemplateList from "./Templates/TemplateList";
import TemplateMgmt from "./Templates/TemplateMgmt";
import SectorList from "./Sectors/SectorList";
import SectorPage from "./Sectors/SectorPage";
import CompanyMgmt from "./System/CompanyMgmt";
import ModelPortfolioPage from "./ModelPortfolio/ModelPortfolioPage";
import NoAccess from "../../NoAccess";
import SchemesList from './SchemesAndFunds/SchemesList'
import SchemePage from './SchemesAndFunds/SchemePage'
import FundsList from './SchemesAndFunds/FundsList'
import FundPage from './SchemesAndFunds/FundPage'
import OffersList from './SchemesAndFunds/OffersList'
import OfferPage from './SchemesAndFunds/OfferPage'
import StyleGuide from './BrandSettings/StyleGuide'
import SubBrandsList from './BrandSettings/SubBrandsList'
import SubBrandPage from './BrandSettings/SubBrandPage'
import { hasFeature } from "../../Utils/Utils";

export const ADMIN_SUB_PAGES = { FUNDS: "funds", TICKERS: "tickers", DISCLAIMERS: "disclaimers", MAILING_LIST: "mailing-list", REVIEW_LIST: "review-list" };
export const ADMIN_SUB_PATHS = { [ADMIN_SUB_PAGES.FUNDS]: "/schemes-and-funds", [ADMIN_SUB_PAGES.DISCLAIMERS]: "/disclaimers", [ADMIN_SUB_PAGES.MAILING_LIST]: "/mailing-list", [ADMIN_SUB_PAGES.REVIEW_LIST]: "/review-list" };

const AdminPage = (props) => {
	const { me } = props

	if (!me || !me.perms) {
		return <NoAccess />
	}
	return (
		<>
			<Switch>
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/schemes-and-funds"} component={hasFeature("FUNDS", SchemesList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/schemes-and-funds/schemes"} component={hasFeature("FUNDS", SchemesList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/schemes-and-funds/funds"} component={hasFeature("FUNDS", FundsList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/schemes-and-funds/offers"} component={hasFeature("FUNDS", OffersList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/schemes-and-funds/schemes/:schemeId"} component={hasFeature("FUNDS", SchemePage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/schemes-and-funds/funds/:fundId"} component={hasFeature("FUNDS", FundPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/schemes-and-funds/offers/:offerId"} component={hasFeature("FUNDS", OfferPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/tickers"} component={hasFeature("TICKERS", TickerList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/tickers/:ticker"} component={hasFeature("TICKERS", TickerPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/model-portfolio"} component={hasFeature("MODEL_PORTFOLIOS", ModelPortfolioPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/sectors"} component={hasFeature("SECTORS", SectorList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/sectors/sector"} component={hasFeature("SECTORS", SectorPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/disclaimers"} component={hasFeature("DISCLAIMER", AdminCompanyDisclaimerList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/disclaimers/:disclaimerId"} component={hasFeature("DISCLAIMER", AdminCompanyDisclaimersPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/user-management"} component={() => hasFeature("USER_MANAGEMENT", <UserManagement me={me} />, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/user-management/:userId"} component={hasFeature("USER_MANAGEMENT", SystemUserPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/company-management"} component={hasFeature("COMPANY_MANAGEMENT", CompanyMgmt, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/role-permissions"} component={hasFeature("ROLES", RolePermissionsList, me)} />
				<Route path={ROUTE_PATHS[PAGES.ADMIN] + "/templates/:template"} component={hasFeature("TEMPLATES", TemplateMgmt, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/templates"} component={hasFeature("TEMPLATES", TemplateList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/brand-settings/brand"} render={() => <StyleGuide/>} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/brand-settings/sub-brands/:subbrandName"} render={() => <SubBrandPage />} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/brand-settings/sub-brands"} render={() => <SubBrandsList/>} />
			</Switch>
		</>
	);
}

export default withRouteChange(AdminPage);
