import React, { useState } from 'react';
import { Settings, Upload, FilePlus, Trash2 } from 'react-feather';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useEffect } from 'react';
import TextFieldLabel from '../../../components/Input/TextFieldLabel'


const TemplateOptions = ({ editMode, template, assetType, setAssetType }) => {
    const assetTypeOptions = [{ value: 'Equities', label: 'Equities' }, { value: 'Funds', label: 'Funds' }, { value: 'Sectors', label: 'Sectors' }, { value: 'N/A', label: 'N/A' }]

    const [inheritedBranding, setInheritedBranding] = useState(true)
    const [layout, setLayout] = useState(1)
    const [columns, setColumns] = useState(1)
    const [file, setFile] = useState(false)


    useEffect(() => {
        if (template && template.ticker && template.ticker.selectionName) {
            setAssetType({ value: template.ticker.selectionName, label: template.ticker.selectionName })
        } else setAssetType({ value: 'Equities', label: 'Equities' })

    }, [template])


    return (
        <>
            <PageGridItem col="1 / span 6">
                <DetailSectionHeader header="Template Options" icon={<Settings />} />
            </PageGridItem>
            <PageGridItem col="1 / span 3">
                <label className="colour-text-label-grey transition-fade-up">Inherit Branding</label>
                <div className='portal-access-radio-options mt-xxl'>
                    <TextFieldLabel className='portal-access-radio-label' > Yes
                        <input readOnly type={"radio"} className='portal-access-radio' checked={inheritedBranding} name="has-model-access" onClick={() => setInheritedBranding(true)} />
                    </TextFieldLabel>
                    <TextFieldLabel className='portal-access-radio-label' > No
                        <input readOnly type={"radio"} className='portal-access-radio' checked={!inheritedBranding} name="has-model-access" onClick={() => setInheritedBranding(false)} />
                    </TextFieldLabel>
                </div>
            </PageGridItem>
            {!editMode && (
                <>
                    <PageGridItem col="4 / span 3">
                        <label className="colour-text-label-grey transition-fade-up">Custom Bullet Point (JPG, PNG, etc.)</label>
                        <div style={{ marginTop: '0.95rem' }}>
                            {file ?
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <div style={{ borderRight: 'solid 1px #E7EAF2', paddingRight: '0.5rem' }}>customed-bullet-point.png</div>
                                    <Upload color='#0D8EFD' size={16} onClick={() => setFile(false)} />
                                    <Trash2 color='#E66780' size={16} onClick={() => setFile(false)} />
                                </div> :

                                <div className={`dropzone dropzone-small`} style={{ padding: 5, maxWidth: 240 }} onClick={() => setFile(true)}>
                                    <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
                                        {<FilePlus className="upload-icon" size={18} />}
                                        <div className="dropzone-message">Drop or click to upload</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </PageGridItem>
                    <PageGridItem col="1 / span 3">
                        <label className="colour-text-label-grey transition-fade-up">Layout</label>
                        <div className='portal-access-radio-options mt-xxl'>
                            <TextFieldLabel className='portal-access-radio-label' > Portrait
                                <input readOnly type={"radio"} className='portal-access-radio' checked={layout === 1} name="layout" onClick={() => setLayout(1)} />
                            </TextFieldLabel>
                            <TextFieldLabel className='portal-access-radio-label' > Landscape
                                <input readOnly type={"radio"} className='portal-access-radio' checked={layout === 2} name="layout" onClick={() => setLayout(2)} />
                            </TextFieldLabel>
                        </div>
                    </PageGridItem>
                    <PageGridItem col="4 / span 3">
                        <label className="colour-text-label-grey transition-fade-up">Columns</label>
                        <div className='portal-access-radio-options mt-xxl'>
                            <TextFieldLabel className='portal-access-radio-label' > Single
                                <input readOnly type={"radio"} className='portal-access-radio' checked={columns === 1} name="columns" onClick={() => setColumns(1)} />
                            </TextFieldLabel>
                            <TextFieldLabel className='portal-access-radio-label' > Double
                                <input readOnly type={"radio"} className='portal-access-radio' checked={columns === 2} name="columns" onClick={() => setColumns(2)} />
                            </TextFieldLabel>
                            <TextFieldLabel className='portal-access-radio-label' > Custom
                                <input readOnly type={"radio"} className='portal-access-radio' checked={columns === 3} name="columns" onClick={() => setColumns(3)} />
                            </TextFieldLabel>
                        </div>
                    </PageGridItem>
                </>
            )}
        </>
    );
}

export default TemplateOptions;