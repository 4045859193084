import React, { useEffect, useRef, useState } from 'react';
import { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import DetailField from "../../Shared/DetailSection/DetailField"
import { BookOpen, Edit2, Save, Trash2, X } from 'react-feather';
import Divider from '../../../components/Divider';
import { useRemote, useStorage } from '../../../Utils/Utils';
import SearchField from '../../../components/Input/SearchField';
import FlexWrapper from "../../FlexWrapper"
import Table from '../../../components/Table/Table';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import Slider from '../../../contents/Slider';
import "./ModelPortfolioPage.scss"
import { format, formatISO, parseISO } from 'date-fns';
import RecommendationSlider from './RecommendationSlider';

/* global DecimalFormat */

const ModelAssetClassTable = ({ assetClass, updateAssetClassName, addAsset, removeAsset, editWeighting }) => {
	const [allTickers, setAllTickers] = useState([])
	const searchSelect = useRef()
	const [newModelName, setNewModelName] = useState("")
	const [editExistingName, setEditExistingName] = useState(false)
	const [editExistingWeighting, setEditExistingWeighting] = useState([])
	const [assets, setAssets] = useState([]);

	let storage = useStorage()
	const remote = useRemote()

	useEffect(() => {
		setAssets(assetClass.assets.tickers);
		setNewModelName(assetClass.groupName)
	}, [assetClass])

	useEffect(() => {
		getAddTickerOptions()
	}, [])

	const saveWeightingChange = (index, assetId) => {
		const weight = editExistingWeighting.find(weighting => weighting.index === index)
		editWeighting(weight.weighting, assetClass.id, assetId)
		setEditExistingWeighting(editExistingWeighting.filter((val) => val.index !== index))
	}

	const getAddTickerOptions = () => {
		storage.getOrFetch("/crm/tickers")
			.then(response => {
				const mappedTickers = response.map((ticker) => {
					return { value: ticker.id, label: ticker.ric, tickerDTO: ticker }
				})
				setAllTickers(mappedTickers)
			});
	}

	const colWidths = ["20%", "10%", "7.5%", "7.5%", "7.5%", "7.5%", "10%", "10%", "15%", "7.5%"]
	const headers = ["Code/Name", "", "Sector", "Currency", "Country", "Exch", "Portfolio Weight", "Last Published", "Recommendation", <><div style={{ whiteSpace: 'nowrap' }}><span>Tgt Price</span> <span style={{ fontSize: "12px" }}>(12m)</span></div></>]
	const dataMap = (data) => data.map((asset, index) => {
		const weighting = new DecimalFormat('##.##').format(asset.weighting);	 //eslint-disable-line no-undef
		const weightingBeingEdited = editExistingWeighting.findIndex(weighting => weighting.index === index)
		return (
			<tr className='tr-borders'>
				<td style={{ "display": "flex", "align-items": "center", "border-top": "0" }}><img src={asset.tickerDetails.logo} style={{ "width": "40px" }} />{/*TODO fix to use asset db logos*/}<span style={{ "margin": "0 10px 0 8px" }}>{asset.tickerDetails ? asset.tickerDetails.ric : "N/A"}</span><span className='model-portfolio-ticker-detail-name'>{asset.tickerDetails ? asset.tickerDetails.name : "N/A"}</span></td>
				<td>
					<div className='model-portfolio-icons'>
						<div>
							{asset.publishedData?.["Title"] && <BookOpen size={22} color="#0D8EFD" style={{ marginRight: '1vw' }} />}
							<Trash2 size={22} onClick={() => removeAsset(asset.ticker, assetClass.id)} />
						</div>
					</div>
				</td>
				<td>{asset.tickerDetails ? asset.tickerDetails.gicSector : "N/A"}</td>
				<td>{asset.tickerDetails && asset.tickerDetails.currencyCode}</td>
				<td>{asset.country}</td>{/*TODO lookup exchange map, or just pass from backend*/}
				<td>{asset.exchange}</td>{/*TODO lookup exchange map, or just pass from backend*/}
				<td className='model-portfolio-asset-weighting'>
					<div className='model-portfolio-right-align' style={{position: "relative"}}>
						<DetailField
							className={weightingBeingEdited >= 0 ? 'model-portfolio-edit-weighting-edit-input-background' : ''}
							value={weightingBeingEdited >= 0 ? editExistingWeighting[weightingBeingEdited].weighting : weighting}
							editMode={weightingBeingEdited >= 0}
							onChange={(e) => {
								let newWeightingsList = [...editExistingWeighting]
								newWeightingsList[weightingBeingEdited].weighting = e.target.value
								setEditExistingWeighting(newWeightingsList)
							}}
							type="number"
						/>
						{weightingBeingEdited >= 0 ? <span className="model-portfolio-asset-weighting-edit-percentage-sign">%</span> : <span>%</span>}
						{weightingBeingEdited >= 0
							? <Save className='model-portfolio-edit-asset-weighting' style={{ display: "block" }} onClick={() => { saveWeightingChange(index, asset.ticker) }} />
							: <Edit2 className='model-portfolio-edit-asset-weighting' onClick={() => setEditExistingWeighting([...editExistingWeighting, { index, weighting }])} />
						}
					</div>
				</td>
				<td>{asset.lastPublishedDate && format(parseISO(asset.lastPublishedDate), 'dd/MM/yyyy')}</td>
				<td><div className='model-portfolio-recommendation'>{asset.publishedData.RecommendationMean && <RecommendationSlider recommendation={asset.publishedData.RecommendationMean} />}</div></td>
				<td><div className='model-portfolio-right-align'>{asset.publishedData && asset.publishedData["Target Price"]}</div></td>
			</tr>
		)
	})

	return (
		<PageContentBodyGrid subGrid rowGap="xl">
			<PageGridItem container col="1 / span 12">
				<FlexWrapper direction="row" gap="m" >
					<DetailField value={newModelName} editMode={editExistingName} onChange={(e) => { setNewModelName(e.target.value) }} className='model-portfolio-banner-title' />
					{editExistingName
						? <Save size={22} 
							color="#0D8EFD"
							onClick={() => {
							setEditExistingName(false)
							updateAssetClassName(newModelName, assetClass.id)
						}} />
						: <Edit2 color="#0E8EFD" size={16} onClick={() => setEditExistingName(true)} />}
					<Divider vertical dark height={24} />
					<SearchField
						onChange={(ticker) => {
							addAsset(ticker.value, assetClass.id);
						}}
						options={allTickers}
						placeholder={`Add ticker`}
						size="small"
						value=""
						innerRef={searchSelect}
						width="m"
					/>
				</FlexWrapper>
			</PageGridItem>
			<PageGridItem container col="1 / span 12" style={{marginBottom: "2rem"}}>
				{assets && assets.length > 0
					? <Table colWidths={colWidths} dataMap={dataMap(assets)} headers={headers} />
					: <SuggestionMessage
						message={
							<>
								No tickers added yet. <span className="text-link" onClick={() => searchSelect && searchSelect.current.focus()}>Try adding one now.</span>
							</>
						}
					/>
				}
			</PageGridItem>
		</PageContentBodyGrid>
	);
}

export default ModelAssetClassTable;