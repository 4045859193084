import React, { useState } from "react";
import { PageContentBodyGrid } from "../Shared/Page/Page";
import PageGridItem from "../Shared/Page/PageGridItem";
import DraftsLibraryTable from "./DraftsLibraryTable";
import DraftsPeerReviewTable from "./DraftsPeerReviewTable";
import LoadingIcon from "../../components/LoadingIcon";

const DraftsLibraryPageBody = ({ me, filters }) => {
	const [draftsLoading, setDraftsLoading] = useState(true)

	return (
		<PageContentBodyGrid id="craigs-library-page-body" showScrollbar style={{paddingLeft: "0.5rem"}}>
			<PageGridItem fullWidth showScrollbar>
				{/* <SortParamsWrapper> */}
				{(draftsLoading) && <LoadingIcon centered className="mt-xl" />}
				<div style={draftsLoading ? { display: "none" } : null}>
					{me && me.perms && me.perms.includes("DRAFTS_ANALYST") ? <DraftsLibraryTable setDraftsLoading={setDraftsLoading} filters={filters} /> : ""}
				</div>
				{/* </SortParamsWrapper> */}
			</PageGridItem>
		</PageContentBodyGrid>
	);
}

export default DraftsLibraryPageBody;