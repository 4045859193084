import React, { useState, useEffect, createContext, useContext } from 'react';

// Create the context
export const HeaderNumberingContext = createContext();

// Create the provider component
export const HeaderNumberingProvider = ({ children }) => {
	const [headersNumbered, setHeadersNumbered] = useState(false);
	const [pageBreaks, setPageBreaks] = useState([])

	return (
		<HeaderNumberingContext.Provider value={{ headersNumbered, setHeadersNumbered, pageBreaks, setPageBreaks }}>
			{children}
		</HeaderNumberingContext.Provider>
	)
};

export const useHeaderAutoNumbering = () => {
	const context = useContext(HeaderNumberingContext);

	if (context === undefined) {
		throw new Error('useHeaderAutoNumbering must be used within a HeaderNumberingProvider');
	}

	return context;
};