import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Edit, Edit2, Eye, MessageSquare, Send, Trash2, UserCheck, UserX } from 'react-feather';
import TableActionIcon from '../../components/Table/TableActionIcon';
import TickerSectorItem from '../../components/TickerSectorItem';
import PdfIcon from '../../images/icons/PdfIcon';
import XlsIcon from '../../images/icons/XlsIcon';
import PublicationSettings from '../CraigsLibraryPage/PublicationManagement/PublicationSettings';
import StatusToken from '../../components/StatusTokens';
import FlexWrapper from '../FlexWrapper';
import Divider from '../../components/Divider';
import DraftNotePreviewModal from '../../components/Modals/DraftNotePreviewModal';
import ConfirmActionModal from '../../components/Modals/ConfirmActionModal';
import { toast, toastInfo, toastSuccess } from '../../components/popups/Toast';
import withRouteChange from '@threeskye/route-change';
import preview from './fundfactsheetpreview.png';
import { showConfirmActionModal, useStorage } from '../../Utils/Utils';
import Tooltip from '../../components/popups/Tooltip';


const RequestsTableRow = ({ allRequests, data, idx, rowActivePreview, setRowActivePreview, tickers, expandedRows, setExpandedRows, getImage, models, modelsLoading, changeRoute, refreshList }) => {

	const [document, setDocument] = useState({});
	const [imageKey, setImageKey] = useState(Date.now());
	const [confirmActionModal, setConfirmActionModal] = useState(null);
	const [me, setMe] = useState({});
	const [autoOpenComments, setAutoOpenComments] = useState(false)

	const perms = me?.perms || [];
	const storage = useStorage();


	useEffect(() => {
		// const newData = {...data, createdDate: "2022-10-18T11:03:33.132074"}
		// setDocument(newData)
		setDocument(data)
		storage.getOrFetch(`/crm/me`).then(setMe)

		if (data) {
			if (data.peerApprovedDate != null) {
				data.peerReviewStatus = "Approved"
			} else if (data.peerRequestedDate != null) {
				data.peerReviewStatus = "Pending"
			} else {
				data.peerReviewStatus = "Not Requested"
			}
			if (data.complianceApprovedDate != null) {
				data.complianceReviewStatus = "Approved"
			} else if (data.complianceRequestedDate != null) {
				data.complianceReviewStatus = "Pending"
			} else {
				data.complianceReviewStatus = "Not Requested"
			}
			if (data.complianceApprovedDate != null) {
				data.complianceReviewStatus = "Approved"
			} else if (data.complianceRequestedDate != null) {
				data.complianceReviewStatus = "Pending"
			} else {
				data.complianceReviewStatus = "Not Requested"
			}
			if (data.complianceApprovedDate != null) {
				data.supervisorReviewStatus = "Approved"
			} else if (data.supervisorRequestedDate != null) {
				data.supervisorReviewStatus = "Pending"
			} else {
				data.supervisorReviewStatus = "Not Requested"
			}

		}
	}, [data])

	const actions = [
		{
			label: "Confirm Approval",
			variant: "positive",
			disabled: !perms.includes("REVIEW_COMPLIANCE"),
			icon: <UserCheck />,
			action: () => {
				showConfirmActionModal("approveCompliance", setConfirmActionModal, data?.template, data?.ticker, refreshList)
			}
		},
		// {
		// 	label: "Confirm & Publish",
		// 	variant: "positive",
		// 	disabled: !perms.includes("REVIEW_COMPLIANCE") || !perms.includes("WORKSPACE_PUBLISH"), 
		// 	icon: <Send />,
		// 	action: () => {
		// 		showConfirmActionModal("approveComplianceAndPublish", setConfirmActionModal, data?.template, data?.ticker, refreshList)
		// 	}
		// },
		{
			label: "Deny Approval",
			variant: "danger",
			disabled: !perms.includes("REVIEW_COMPLIANCE"),
			icon: <UserX />,
			action: () => {
				showConfirmActionModal("rejectCompliance", setConfirmActionModal, data?.template, data?.ticker, refreshList)
			}
		}
	]

	const updateVisibility = (bool) => {
		let newDocument = { ...document, publishedToPortal: bool }
		setDocument(newDocument)
	}

	const updateImageKey = () => {
		setImageKey(Date.now())
	}


	const handlePublishClick = () => {
		confirmActionModal(null);
		toastSuccess("Draft published and sent");
	}

	const handleDeleteClick = () => {
		confirmActionModal(null);
		toastInfo("Draft note deleted");
	}

	const determineStatusType = (status) => {
		switch (status) {
			case "Pending":
				return "warning";
			case "Approved":
				return "positive";
			case "Denied":
			case "Rejected":
				return "danger";
			default:
				return "neutral";
		}
	}

	const displayStatusTooltip = (requestor, time) => {

		if (document && document.peerRequestedBy === "System") {
			return <FlexWrapper gap="s" direction="column" align="start">
				{requestor && time
					? <>
						<p>Approved by {requestor}</p>
						<p>{time && format(parseISO(time), "dd/MM/yyyy hh:mmaaa")}</p>
					</>
					: <p>Not yet approved</p>
				}
			</FlexWrapper>
		}

		return <FlexWrapper gap="s" direction="column" align="start" >
			{requestor && time
				? <>
					<p>Requested by {requestor}</p>
					<p>{time && format(parseISO(time), "dd/MM/yyyy hh:mmaaa")}</p>
				</>
				: <p>Not yet requested</p>
			}
		</FlexWrapper>
	}

	return (
		<>
			{rowActivePreview === idx && <DraftNotePreviewModal data={data} setRowActivePreview={setRowActivePreview} currentIndex={idx} allRequests={allRequests} compliance handleClose={() => { setRowActivePreview(null); setAutoOpenComments(false) }} actions={actions} autoOpenComments={autoOpenComments} setAutoOpenComments={setAutoOpenComments} />}
			{confirmActionModal && <ConfirmActionModal {...confirmActionModal} />}
			<tr key={idx} className={expandedRows && document && expandedRows.includes(document.docId) ? "library-row-selected" : ""} onClick={() => setRowActivePreview(idx)}>
				<td>
					{document && document.createdDate
						? <Tooltip delay={1000} label={document.peerRequestedBy === "System" ? "Data was synchronised" : "File was created"}>
							<div style={{display: 'flex', flexWrap: 'wrap'}}>
								<p style={{paddingRight: 10}}>{format(parseISO(document.createdDate), "do MMM yyyy")}</p>
								<p>{format(parseISO(document.createdDate), "hh:mm aaa")}</p>
							</div>
						</Tooltip>
						: "-"
					}
				</td>
				<td>
					<div className="tsi-details">
						<div className="tsi-label">
							{document && document.coverage}
						</div>
					</div>
				</td>
				<td className="research-title">
					<p>{document && document.title}</p>
				</td>
				<td>
					<p>{document && document.author}</p>
				</td>
				<td>
					{

						document
							? <Tooltip
								label={
									displayStatusTooltip(
										document.peerRequestedBy === "System" ? document.peerApprovedBy : document.peerRequestedBy,
										document.peerRequestedDate === "System" ? document.peerApprovedDate : document.peerRequestedDate
									)}
								delay={1000}
							>
								<StatusToken type={document && determineStatusType(document.peerReviewStatus)} label={document && document.peerReviewStatus} />
							</Tooltip>
							: ""
					}
				</td>
				<td>
					{
						document
							? <Tooltip
								label={
									displayStatusTooltip(
										document.complianceRequestedBy === "System" ? document.complianceApprovedBy : document.complianceRequestedBy,
										document.complianceRequestedDate === "System" ? document.complianceApprovedDate : document.complianceRequestedDate
									)}
								delay={1000}
							>
								<StatusToken type={document && determineStatusType(document.complianceReviewStatus)} label={document && document.complianceReviewStatus} />
							</Tooltip>
							: ""
					}
				</td>
				{/* Row Actions */}
				<td>
					<FlexWrapper gap="l">
						<TableActionIcon colour="primary" noMargin icon={<Eye />} toolTip="Click to preview document" onClick={() => setRowActivePreview(idx)} />
						<TableActionIcon colour="primary" noMargin icon={<MessageSquare />} toolTip="Click to view messages" onClick={() => { setRowActivePreview(null); setAutoOpenComments(true) }} />
					</FlexWrapper>
				</td>
			</tr >
		</>
	);
}

export default withRouteChange(RequestsTableRow);