import React, { useState } from 'react';
import { FileText } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailFieldList from '../../Shared/DetailSection/DetailFieldList';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useEffect } from 'react';


const TemplateGeneralDetails = ({ editMode, template, assetType, setAssetType, disclaimer, setDisclaimer }) => {
	const assetTypeOptions = [
		{value: 'Equities', label: 'Equities'}, 
		{value: 'Funds', label: 'Funds'}, 
		{value: 'Sectors', label: 'Sectors'}, 
		{value: 'N/A', label: 'N/A'}
	]
	const templateTypes = [
		{value: 'Company Note', label: 'Company Note', keyName: 'company', assetType: 'Equities'},
		{value: 'Strategy Note', label: 'Strategy Note', keyName: 'strategy', assetType: 'Equities'},
		{value: 'Pre-listed note', label: 'Pre-listed note', keyName: 'pre-listed', assetType: 'Equities'},
		{value: 'Sector Note (equities by sector)', label: 'Sector Note (equities by sector)', keyName: 'sector', assetType: 'Sector'},
		{value: 'Quarterly Fund Update (Funds)', label: 'Quarterly Fund Update (Funds)', keyName: 'fund update', keyName2: 'qfu', assetType: 'Funds'},
		{value: 'Fact Sheet (Funds)', label: 'Fact Sheet (Funds)', keyName: 'fund fact', assetType: 'Funds'},
		{value: 'Product Disclosure Statement (PDS)', label: 'Product Disclosure Statement (PDS)', keyName: 'pds', assetType: 'Schemes'},
		{value: 'SIPO', label: 'SIPO', keyName: 'sipo', assetType: 'Schemes'},
		{value: 'Economic Outlook', label: 'Economic Outlook', keyName: 'outlook', assetType: 'N/A'},
		{value: 'Climate Change', label: 'Climate Change', keyName: 'climate', assetType: 'N/A'}
	]

	const disclaimerOptions = [
		{value: 'General Disclaimer', label: 'General Disclaimer'}, 
		{value: 'Company Disclaimer', label: 'Company Disclaimer'}, 
		{value: 'Fact Sheet', label: 'Fact Sheet'}, 
	]

	const [templateType, setTemplateType] = useState()

	useEffect(() => {
		if (template && template.name) {
			const foundTemplate = templateTypes.find(t => template.name.toLowerCase().includes(t.keyName) || template.name.toLowerCase().includes(t.keyName2))
			setTemplateType(foundTemplate)
			setTemplateType({value: foundTemplate, label: foundTemplate})
		} //else setAssetType({value: 'Equities', label: 'Equities'})

	},[template])


	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Template Details" icon={<FileText />} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField bold editMode={editMode} autoFocus label="Name" value={template.name} />
			</PageGridItem>
			{!editMode && (
				<>
					<PageGridItem col="4 / span 3">
						{templateType && <SelectDetailField options={templateTypes} editMode={editMode} label="Template Type" value={templateType.value} />}
					</PageGridItem>
					<PageGridItem col="1 / span 3">
						<DetailField editMode={editMode} label="Optional Pages" value={template.optionalPages ? "Yes" : "No"} />
					</PageGridItem>
					<PageGridItem col="4 / span 3">
						<SelectDetailField editMode={editMode} placeholder='Select' label="Asset Type" value={assetType} options={assetTypeOptions} onChange={(option) => setAssetType(option)} />
					</PageGridItem>
					{template.outputs && <PageGridItem col="1 / span 3">
						<DetailFieldList label="Outputs" value={template.outputs.map(output => output.name + (output.dflt ? " (default)" : ""))} />
					</PageGridItem>}
					<PageGridItem col="4 / span 3">
						<SelectDetailField editMode={editMode} placeholder='Select' label="Disclaimer" value={disclaimer} options={disclaimerOptions} onChange={(option) => setDisclaimer(option)} />
					</PageGridItem>
				</>
			)}
		</>
	);
}

export default TemplateGeneralDetails;