import React, { useState, useEffect } from 'react';
import { ContextMenu, ContextMenuTrigger } from '@threeskye/react-contextmenu';
import { MenuItem } from 'react-contextmenu';
import { Node as SlateNode, Path as SlatePath } from 'slate';
import { ReactEditor } from 'slate-react';
import { useHeaderAutoNumbering } from '../Utils/UseHeaderAutoNumbering';

const TableOfContents = (props) => {
	const [headings, setHeadings] = useState([]);
	const { editor } = props

	const {pageBreaks} = useHeaderAutoNumbering()

	useEffect(updateHeaders, []);
	const ToCId = Math.round(Math.random() * 1000000);

	function determinePage(node, idx) {
		let pageCount = 1;
		pageBreaks.forEach((pageBreak) => {
			if (pageBreak.at[0] < idx) {
				pageCount++;
			};
		})
		return { ...node, page: pageCount }
	}

	function isWhitespaceString(str) { return !str.replace(/\s/g, '').length }

	function updateHeaders() {
		const newHeadings = [];
		editor.children.forEach((node, idx) => {

			if (node.type === 'heading-one' || node.type === 'heading-two' || node.type === 'heading-three') {
				if (!node.children || node.children.length === 0 || (node.children.every((child) => !child.text || isWhitespaceString(child.text)))) {
					return
				};

				let header = determinePage(node, idx);
				newHeadings.push(header);
			}
		});

		
		setHeadings(newHeadings);
	}

	const styles = props.styles;
	const flowStyles = props.flowStyles;
	const labelStyle = styles && flowStyles && styles[flowStyles['tocLabel']];
	const numberStyle = styles && flowStyles && styles[flowStyles['tocNumber']];
	const gapStyle = styles && flowStyles && styles[flowStyles['tocGap']];
	
	function getIndentation(type) {
		switch (type) {
			case 'heading-one':
				return "0px";
			case 'heading-two':
				return "16px";
			case 'heading-three':
				return "32px";
			default:
				return 0;
		}

	}

	const labelStyleMerged = labelStyle ? props.data.getStyle(labelStyle, true) : {};
	labelStyleMerged.marginBottom = 'inherit';

	return (
		<div data-slate-node="element" contentEditable={false}>
			<ContextMenuTrigger id={`table-of-contents-${ToCId}`}>
				{headings.map((heading, idx) => {
					const isHeadingOne = heading.type === 'heading-one';
					return (
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: getIndentation(heading.type), fontWeight: isHeadingOne ? 600 : 400, marginBottom: "5px" }}>
							<p key={idx} style={{ ...labelStyleMerged, width: "fit-content" }}>
								{heading.children.map((child) => child.text).join('')}
							</p>
							<div className="slate-toc-gap" style={{ ...gapStyle, flexGrow: '1', height: "0.8rem" }} />
							<p className='slate-toc-number' style={{ ...numberStyle, fontWeight: 600 }} >
								{heading.page}
							</p>
						</div>
					);
				})}
				{props.children}
			</ContextMenuTrigger>
			<div contentEditable={false} style={{ userSelect: "none" }}>
				<ContextMenu id={`table-of-contents-${ToCId}`}>
					<MenuItem
						onClick={updateHeaders}
					>
						Update Table of Contents
					</MenuItem>
				</ContextMenu>
			</div>
		</div>
	);
}

export default TableOfContents;