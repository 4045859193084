import React, { useState, useEffect } from 'react';
import { AlertCircle, Tool } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Checkbox from '../../../components/Checkbox';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import Table from '../../../components/Table/Table';
import { useRemote } from '../../../Utils/Utils';
import FlexWrapper from '../../FlexWrapper';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { PublishToPortal } from './TemplatePublishActions'
import SelectTagField from '../../../components/Input/SelectTagField';


const TemplateCompliance = ({ data, addActionToTemplate, selected, templateId, setSelectedPublishActions, removeAction }) => {
	const colWidths = [40 + "%", 55 + "%", "5%"];
	const [selectedComplianceActions, setSelectedComplianceActions] = useState([])
	const selectedPublishActionIds = selectedComplianceActions && selectedComplianceActions.length > 0 && selectedComplianceActions.map(a => a.stepId);
	// const selectedPublishActionIds = selected.map(a => a.stepId);

	useEffect(() => {
		if (selected && selected.length > 0) {
			const filteredRealList = selected.filter(a => a.stepId === 4 || a.stepId === 5)
			const filterFakeList = selectedComplianceActions && selectedComplianceActions.length > 0 && selectedComplianceActions.filter(a => a.new)
			const updatedFakeList = filterFakeList && filterFakeList.length > 0 && filterFakeList.map(a => {
				const newObj = {
					paramValues: {},
					params: [],
					stepId: a.id
				}
				return newObj
			})
			const newList = updatedFakeList ? [...filteredRealList, ...updatedFakeList] : [...filteredRealList]
			setSelectedComplianceActions(newList)
		}
	}, [selected])

	function addTolist(action) {
		if (action.new) {
			const newObj = {
				paramValues: {},
				params: [],
				stepId: action.id
			}
			const newList = [...selectedComplianceActions, newObj]
			setSelectedComplianceActions(newList)
		} else addActionToTemplate(action)
	}

	function removeFromList(action) {
		if (action.new) {
			const newList = selectedComplianceActions.filter(a => a.stepId !== action.id)
			setSelectedComplianceActions(newList)
		} else removeAction(action)
	}

	const showParams = (action) => {
		const analystOptions =[{value: 'George Esposito', label: 'George Esposito'}, {value: 'Noah Alistone', label: 'Noah Alistone'}]
		const usersOptions = [{value: 'Kate Roberts', label: 'Kate Roberts'}, {value: 'Tanner Wall', label: 'Tanner Wall'}]
		
		if (!action) return null;
		if (action.stepId === 4 || action.stepId === 5 || action.stepId === 15) return <PublishToPortal passedRole={action.stepId === 5 ? 'Analyst' :action.stepId === 15 ? 'Supervisor': 'Compliance'}/>
		if (action.stepId === 12 || action.stepId === 16) return (
			<div className='custom-publish-actions-options-selector'>
				<div className='action-field-label'>{action.stepId === 9 ? 'Analyst Spot Check' : 'Spot Check Users'}</div>
				<SelectTagField options={action.stepId === 9? analystOptions : usersOptions} />
			</div>
		)

		return (
			<FlexWrapper gap="xl" direction="column" align="start">
				{action.params.map((param, idx) => <ParamField key={idx} param={param} action={action} template={templateId} setSelectedPublishActions={setSelectedPublishActions} />)}
			</FlexWrapper>
		)
	}

	const dataMap = () => data && data.map((action, index) => {
		const active = selectedPublishActionIds && selectedPublishActionIds.includes(action.id);
		const noExpandable = [13, 14]
		const dontExpand = noExpandable.includes(action.id)

		if (!active) {
			return <tr key={action.id} style={{ borderBottom: index === data.length - 1 ? 'solid 1.5px #e7eaf2' : null }} >
				<td><Checkbox checked={active} label={action.displayName} onChange={() => addTolist(action)} stopClick /></td>
				<td>{action.description}</td>
				<td></td>
			</tr>
		}

		const selectedInstance = active && selectedComplianceActions && selectedComplianceActions.length > 0 ? selectedComplianceActions.find(a => a.stepId === action.id) : null;
		let className = "cursor-pointer ";
		let invalid = false;
		if (selectedInstance !== null) {
			for (let param of selectedInstance.params) {
				if (param.required) {
					const val = selectedInstance.paramValues[param.name];
					if (!val || (val && val.length === 0)) {
						invalid = true;
						break;
					}
				}
			}
		}

		return (
			<ExpandableTableRow key={action.id} className={className} expandContent={showParams(selectedInstance)} isLast={index === data.length - 1} dontExpand={dontExpand}>
				<td><Checkbox checked={active} label={action.displayName} onChange={() => removeFromList(action)} stopClick /></td>
				<td>{action.description} </td>
				<td>{invalid ? <AlertCircle /> : null}</td>
			</ExpandableTableRow>
		);
	})

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Compliance" icon={<Tool />} marginBottom='1rem' />
				<Table colWidths={colWidths} dataMap={dataMap()} /*tableStyle={{marginBottom: '1rem'}}*/ />
			</PageGridItem>
		</>
	);
}

const ParamField = ({ param, template, action, setSelectedPublishActions }) => {
	const value = (action.paramValues && action.paramValues[param.name]) || "";
	const remote = useRemote();
	const [editor, setEditor] = useState(value);

	const update = () => {
		remote.put(`/crm/templates/${template}/publish-steps/${action.stepId}/${param.name}`, { "value": editor }).then(setSelectedPublishActions);
	}


	return (
		<div style={{ width: "100%" }}>
			<DetailField variant="light" invalid={editor === "" ? "invalid" : undefined} editMode={true} autoFocus placeholder={param.description} label={param.name} value={editor} onChange={(e) => setEditor(e.target.value)} />
			<Button className="mt-s" disabled={editor === value} onClick={update}>Save</Button>
		</div>
	)
};

export default TemplateCompliance;