import React from "react"
import { useRemote } from "../../../Utils/Utils"
import { toastSuccess, toastDanger } from "../../../components/popups/Toast"
import TickerSectorItem from "../../../components/TickerSectorItem"
import WatchlistIcon from "../../../components/Icons/WatchListIcon"
import TableActionIcon from "../../../components/Table/TableActionIcon"
import { Trash } from "react-feather"


export const distributionListMap = (userListsToMap, userId, setData, userLists, editMode, watchList, allTickers, allGroups) => userListsToMap && userListsToMap.map((list, idx) => {
	const remote = useRemote()

	const removeList = (list) => {
		remote.delete(`/crm/lists/${list.id}/members/${userId}`)
			.then((response) => {
				toastSuccess("Removed from list");
				let newArray = []
				userLists.forEach((listFromArray) => {
					if (listFromArray.id !== list.id) {
						newArray.push(listFromArray)
					}
				})
				setData(newArray)
			})
			.catch(() => toastDanger('Distribution list could not be removed'))
	}

	return (
		<tr key={idx} className={editMode ? "" : "cursor-pointer"}>
			<td>
				<div className="truncate">
					<TickerSectorItem label={list.name} /*image={getImage(list.name)}*/ extensionLabel={list.type} />
				</div> 
			</td>
			<td className="table-action-icons transition-fade-in-slow">

				{watchList && watchList.find(w => w.fk === list.name) && <WatchlistIcon
					onWatchList={true}
					onClick={() => null}
					assetId={null}
					grouping={null}
					style={{ marginRight: "16px" }}
				/>}
				<TableActionIcon
					icon={<Trash />}
					toolTip="Remove user from list"
					onClick={(e) => {
						e.stopPropagation();
						removeList(list);
					}}
				/>
			</td>
		</tr>
	);
})

export const watchlistMap = (userListsToMap, userId, setData, userLists, editMode, watched, allTickers, allGroups) => userListsToMap && userListsToMap.map((list, idx) => {

	return (
		<tr key={idx} className={editMode ? "" : "cursor-pointer"}>
			<td>
				<div className="truncate">
					<TickerSectorItem label={list.fk} /*image={getImage(list)}*/ extensionLabel={""} />
				</div>
			</td>
			{/* <td className="table-action-icons transition-fade-in-slow">

				<WatchlistIcon
					onWatchList={watched()}
					onClick={() => null}
					assetId={null}
					grouping={null}
					style={{ marginRight: "16px" }}
				/>
				<TableActionIcon
					icon={<Trash />}
					toolTip="Remove user from list"
					onClick={(e) => {
						e.stopPropagation();
						removeList(list);
					}}
				/>
			</td> */}
		</tr>
	);
})
