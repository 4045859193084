import React, { useState, useEffect, useRef } from 'react';
import RemoteImpl from '@threeskye/global/dist/RemoteImpl';
import SessionStorage from '@threeskye/global/dist/SessionStorage'
import { format, parseISO, isSameDay, isBefore, isAfter } from 'date-fns'
import Tooltip from '../components/popups/Tooltip';
import { Mail, AlertCircle, Send, Eye, Users, BookOpen, Smartphone, Monitor, Clock } from "react-feather";
import PdfIcon from '../images/icons/PdfIcon';
import XlsIcon from '../images/icons/XlsIcon';
import * as pdfjs from 'pdfjs-dist';
import { toastSuccess, toastInfo } from '../components/popups/Toast';
import LoadingIcon from '../components/LoadingIcon';
import ErrorPage from '../layouts/ErrorPage';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const getIcon = (type, size, disabled, label, colour) => {
    switch (type) {
        case "send":
            return <Tooltip label="Delivered"><Send width={size} colour={colour} className="colour-text-positive"  /> </Tooltip>
        case "fail":
            return <Tooltip label="Failed"><AlertCircle width={size} colour={colour} className="colour-text-danger"  /> </Tooltip>
        case "read":
            return <Tooltip label={label ? label : "Read"}><Mail width={size} colour={colour} className="colour-text-purple" disabled={disabled === ""} /> </Tooltip>
        case "views":
            return <Tooltip label={label ? label : "Portal Views"}><Eye width={size} colour={colour} className="colour-text-primary" disabled={disabled === ""} /></Tooltip>
        case "downloads":
            return <Tooltip label={label ? label : "PDF Downloads"}><PdfIcon size={size} colour={colour} noHover disabled={disabled === ""} /></Tooltip>
        case "modalDownloads":
            return <Tooltip label={label ? label : "Model Downloads"}><XlsIcon size={size} colour={colour} noHover disabled={disabled === ""} /></Tooltip>
        case "watching":
            return <Tooltip label="Model Downloads"><Users size={size} colour={colour} noHover /></Tooltip>
        case "research":
            return <Tooltip label="Model Downloads"><BookOpen size={size} colour={colour} noHover /></Tooltip>
        case "mobile":
            return <Tooltip label="Mobile"><Smartphone size={size} colour={colour} className="colour-text-accent-2" disabled={disabled === ""} /></Tooltip>
        case "desktop":
            return <Tooltip label="Desktop"><Monitor size={size} colour={colour} className="colour-text-primary" disabled={disabled === ""} /></Tooltip>
        case "clock":
            return <Tooltip label="Minutes"><Clock size={size} colour={colour} className="colour-text-primary" disabled={disabled === ""} /></Tooltip>
        default:
            break;
    }
}

export const countReadersPerDoc = (arr1, arr2, property, string) => {
    const result = {};

    arr1.forEach(doc => {
        result[doc.researchId] = 0;
        arr2.forEach(user => {
            user.readDocs.forEach(readDoc => {
                if (readDoc.researchId === doc.researchId) {
                    if (readDoc[property] === string) result[doc.researchId]++;
                    else if (!property && !string) result[doc.researchId]++
                }
            });
        });
    });

    return result;
}

export const getUsersWithRelevantResearch = (users, research) => {
    return users.filter(user => {
          return user.readDocs.some(doc => research.hasOwnProperty(doc.researchId));
    });
}

export const countDocsPerViewer = (arr, property, value) => {
    return arr.filter(obj => obj[property] === value).length;
}

export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);


export const getPercentage = (value, total, places) => {
    const sum = ((value / total) * 100).toFixed(places ? places : 0) + "%";
    return sum;
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

// based off this snippet: https://stackoverflow.com/a/54570068
export function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef < HTMLDivElement > (null);

    const handleHideDropdown = (event) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

export const useOutsideAlerter = (ref, callback) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export function formatDate(date) {
    let preformattingDate = date
    if (date) {
        if  (Array.isArray(preformattingDate)) {
            preformattingDate = new Date(...date)
        }
        try {
            let nonISODate = parseISO(preformattingDate)
            let formattedDate = format(nonISODate, "dd/MM/yyyy")
            return formattedDate
        } catch {
            let formattedDate = format(preformattingDate, "dd/MM/yyyy")
            return formattedDate
        }
    } else {
        return ""
    }
}

export function formatDateTime(date) {
    let formattedDate = format(new Date(date), "dd/MM/yyyy HH:mm")
    return formattedDate
}

export function parseLocationDetails(c) {
    if (c && c.locations && Array.isArray(c.locations) && c.locations.length >= 1) {
        c.locations = { value: c.locations[0].location, label: c.locations[0].location, location: c.locations[0].location }
    }
    return c
}

export function onDocumentClick(doc) {
    if (doc && doc.id) {
        remote.get(`/documents/document/${doc.id}`).then((response) => {
            if (response) {
                openInNewTab(response.url);
            }
        });
    }
}

function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const remote = new RemoteImpl();
export const useRemote = () => remote;

const storage = new SessionStorage(remote);
export const useStorage = () => storage;

export const undoDeleteUser = (user, subscribedLists, updateState) => {
    //create user
    remote.post(`/crm/contacts`, user)
        .then((contactResponse) => {
            //add user to state
            updateState(contactResponse.data)
            //link user and org
            user.companyId && remote.put(`/crm/organisations/${user.companyId}/contacts/${contactResponse.data.id}`)
            //link user to distribution lists
            subscribedLists.forEach((list) => {
                remote.put(`/crm/lists/${list.id}/members/${contactResponse.data.id}`)
            })
        })
}
export const undoDeleteCompany = (company, members, updateState) => {
    remote.post(`/crm/organisations`, company)
        .then((companyResponse) => {
            //save members
            members.map(contact => {
                remote.put(`/crm/organisations/${companyResponse.data.id}/contacts/${contact.id}`)
            })
            updateState(companyResponse.data)
        })
}


export const undoDeleteDistributionList = (distributionList, tickers, contacts, updateState) => {
    //save list
    remote.post("/crm/lists", { name: distributionList.name }).then((response => {
        //save tickers
        tickers.forEach((ticker) => {
            remote.put(`/crm/lists/${response.data.id}/tickers/${ticker.id}`)
        })
        //save members
        contacts.forEach(contact => {
            remote.put(`/crm/lists/${response.data.id}/members/${contact.id}`)
        })
        updateState(response.data)
    }));
}

function isFunction(value) {
    return typeof value === 'function';
}


export const usePdf = ({
    canvasRef,
    path,
    postBody,
    onDocumentLoadSuccess,
    onDocumentLoadFail,
    onPageLoadSuccess,
    onPageLoadFail,
    onPageRenderSuccess,
    onPageRenderFail,
    scale = 1,
    rotate = 0,
    page = 1,
    cMapUrl,
    cMapPacked,
    workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
    withCredentials = false,
    width,
    height,
}) => {
    const [pdfDocument, setPdfDocument] = useState();
    const [pdfPage, setPdfPage] = useState();
    const [loadingPDF, setLoadingPDF] = useState(true);
    const renderTask = useRef(null);
    const onDocumentLoadSuccessRef = useRef(onDocumentLoadSuccess);
    const onDocumentLoadFailRef = useRef(onDocumentLoadFail);
    const onPageLoadSuccessRef = useRef(onPageLoadSuccess);
    const onPageLoadFailRef = useRef(onPageLoadFail);
    const onPageRenderSuccessRef = useRef(onPageRenderSuccess);
    const onPageRenderFailRef = useRef(onPageRenderFail);

    const remote = useRemote();

    // assign callbacks to refs to avoid redrawing
    useEffect(() => {
        onDocumentLoadSuccessRef.current = onDocumentLoadSuccess;
    }, [onDocumentLoadSuccess]);

    useEffect(() => {
        onDocumentLoadFailRef.current = onDocumentLoadFail;
    }, [onDocumentLoadFail]);

    useEffect(() => {
        onPageLoadSuccessRef.current = onPageLoadSuccess;
    }, [onPageLoadSuccess]);

    useEffect(() => {
        onPageLoadFailRef.current = onPageLoadFail;
    }, [onPageLoadFail]);

    useEffect(() => {
        onPageRenderSuccessRef.current = onPageRenderSuccess;
    }, [onPageRenderSuccess]);

    useEffect(() => {
        onPageRenderFailRef.current = onPageRenderFail;
    }, [onPageRenderFail]);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
    }, [workerSrc]);

    const base64ToArrayBuffer = (base64) => {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    useEffect(() => {
        setLoadingPDF(true)
        remote.post(path, postBody)
            .then((resp) => {
                const base64 = resp.pdf;
                return base64ToArrayBuffer(base64);
            })
            //			.then((resp) => resp.arrayBuffer())
            .then(array => {
                pdfjs.getDocument({ data: array }).promise.then(
                    loadedPdfDocument => {
                        setPdfDocument(loadedPdfDocument);

                        if (isFunction(onDocumentLoadSuccessRef.current)) {
                            onDocumentLoadSuccessRef.current(loadedPdfDocument);
                        }
                        setLoadingPDF(false)
                    },
                    () => {
                        if (isFunction(onDocumentLoadFailRef.current)) {
                            onDocumentLoadFailRef.current();
                        }
                        setLoadingPDF(false)
                    }
                );
            })
    }, [path]);

    useEffect(() => {
        // draw a page of the pdf
        const drawPDF = (page) => {
            // Because this page's rotation option overwrites pdf default rotation value,
            // calculating page rotation option value from pdf default and this component prop rotate.
            const rotation = rotate === 0 ? page.rotate : page.rotate + rotate;
            const dpRatio = window.devicePixelRatio;
            const adjustedScale = scale * dpRatio;
            const viewport = page.getViewport({ scale: adjustedScale, rotation });
            const canvasEl = canvasRef && canvasRef.current;
            if (!canvasEl) {
                return;
            }

            const canvasContext = canvasEl.getContext('2d');
            if (!canvasContext) {
                return;
            }

            canvasEl.style.width = width ? width : width === null ? null : `${viewport.width / dpRatio}px`;
            canvasEl.style.height = height ? height : height === null ? null : `${viewport.height / dpRatio}px`;
            canvasEl.height = viewport.height;
            canvasEl.width = viewport.width;

            // if previous render isn't done yet, we cancel it
            if (renderTask.current) {
                renderTask.current.cancel();
                return;
            }

            renderTask.current = page.render({
                canvasContext,
                viewport,
            });

            return renderTask.current.promise.then(
                () => {
                    renderTask.current = null;

                    if (isFunction(onPageRenderSuccessRef.current)) {
                        onPageRenderSuccessRef.current(page);
                    }
                },
                (reason) => {
                    renderTask.current = null;

                    if (reason && reason.name === 'RenderingCancelledException') {
                        drawPDF(page);
                    } else if (isFunction(onPageRenderFailRef.current)) {
                        onPageRenderFailRef.current();
                    }
                }
            );
        };

        if (pdfDocument) {
            pdfDocument.getPage(page).then(
                loadedPdfPage => {
                    setPdfPage(loadedPdfPage);

                    if (isFunction(onPageLoadSuccessRef.current)) {
                        onPageLoadSuccessRef.current(loadedPdfPage);
                    }

                    drawPDF(loadedPdfPage);
                },
                () => {
                    if (isFunction(onPageLoadFailRef.current)) {
                        onPageLoadFailRef.current();
                    }
                }
            );
        }
    }, [canvasRef, page, pdfDocument, rotate, scale]);

    return { pdfDocument, pdfPage, loadingPDF };
};

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
      const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.rotate(rotateRads);
    ctx.scale(scale, scale);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
    );

    ctx.restore();
}

export function useDebounceEffect(fn, waitTime, deps) {
    useEffect(() => {
        const t = setTimeout(() => {
            fn.apply(undefined, deps)
        }, waitTime)

        return () => {
            clearTimeout(t)
        }
    }, deps)
}

export const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : "";
    const lastInitial = lastName ? lastName.charAt(0) : "";
    return `${firstInitial}${lastInitial}`;
};

export const minutesIntoReadableFormat = (value) => {
    // this function takes a number that represents minutes and returns a string with days, hours and minutes
    const days = Math.floor(value / (60 * 24));
    const hours = Math.floor((value % (60 * 24)) / 60);
    const minutes = value % 60;

    const result = `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes}m`;
    return result
}

export const minutesIntoReadableFormatWithSeconds = (value) => {
    // this function takes a number that represents minutes and returns a string with minutes and seconds
    const minutes = Math.floor(value)
    const seconds = Math.round((value - minutes) * 60)
    const readableFormat = `${minutes > 0 ? minutes + 'm ' : ''}${seconds}s`
    return readableFormat
}

export function getTickerImageByRic(value, setNewPaths) {
    if (value.includes(".")) {
        return storage.getOrFetch(`/crm/tickers`).then(fetchedTickers => {
            const foundTicker = fetchedTickers.find(t => t.ric === value)
            const path = foundTicker ? `/api/public/images/ticker/${foundTicker.id}.png` : null
            setNewPaths(path)
        })
    } else {
        return storage.getOrFetch(`/crm/groupings/${1}`).then((fetchedGrouping) => {
            const foundGroup = fetchedGrouping.groups.find(g => g.groupName === value)
            const path = foundGroup ? `/api/public/images/group/${foundGroup.id}.png` : null
            setNewPaths(path)
        })
    }
}

export const listOfKiwiAndAussieCities = [
    {name: "Adelaide"},
    {name: "Albury-Wodonga"},
    {name: "Auckland"},
    {name: "Ballarat"},
    {name: "Bendigo"},
    {name: "Blenheim"},
    {name: "Brisbane"},
    {name: "Cairns"},
    {name: "Canberra-Queanbeyan"},
    {name: "Christchurch"},
    {name: "Darwin"},
    {name: "Dunedin"},
    {name: "Geelong"},
    {name: "Gisborne"},
    {name: "Gold Coast-Tweed Heads"},
    {name: "Hamilton"},
    {name: "Hastings"},
    {name: "Hobart"},
    {name: "Invercargill"},
    {name: "Launceston"},
    {name: "Lower Hutt"},
    {name: "Melbourne"},
    {name: "Napier"},
    {name: "Nelson"},
    {name: "New Plymouth"},
    {name: "Newcastle-Maitland"},
    {name: "Palmerston North"},
    {name: "Perth"},
    {name: "Porirua"},
    {name: "Queenstown"},
    {name: "Rotorua"},
    {name: "Sunshine Coast"},
    {name: "Sydney"},
    {name: "Tauranga"},
    {name: "Timaru"},
    {name: "Toowoomba"},
    {name: "Townsville"},
    {name: "Upper Hutt"},
    {name: "Wellington"},
    {name: "Whanganui"},
    {name: "Whangarei"},
    {name: "Wollongong"}
  ]
  

export const impersonate = (user, remote, storage) => {

    remote.get("/crm/impersonate/" + user.email)
        .then(result => {
            let sessionStorage = window.sessionStorage;
            let token = result.data;
            let realToken = sessionStorage.getItem("3skye.auth.token");
            sessionStorage.setItem("3skye.auth.super.token", realToken);
            sessionStorage.setItem("3skye.auth.token", token);
            sessionStorage.setItem("3skye.returnPath", window.location.pathname);
            storage.clear();
            window.location.pathname = "/";
        });
}

export const unimpersonate = (storage) => {
    let token = sessionStorage.getItem("3skye.auth.super.token");
    sessionStorage.removeItem("3skye.auth.super.token");
    sessionStorage.setItem("3skye.auth.token", token);
    let returnPath = sessionStorage.getItem("3skye.returnPath");
    sessionStorage.removeItem("3skye.returnPath");
    storage.clear();

    window.location.pathname = returnPath || "/";
}

export const showConfirmActionModal = (action, setConfirmActionModal, template, ticker, refreshList) => {
    const confirmableActions = {
        publishDraft: {
            header: "Are you sure want to publish and send this draft?",
            message: "This action cannot be undone",
            handleClose: () => setConfirmActionModal(null),
            cancelButton: { onClick: () => setConfirmActionModal(null) },
            confirmButton: { variant: "positive", label: "Yes, publish & send", onClick: () => { handlePublish(template, ticker, refreshList); setConfirmActionModal(null) } }
        },
        removeDraft: {
            header: "Are you sure you want to permanently delete this draft?",
            message: "This action cannot be undone",
            handleClose: () => setConfirmActionModal(null),
            cancelButton: { onClick: () => setConfirmActionModal(null) },
            confirmButton: { variant: "danger", label: "Yes, delete draft", onClick: () => { handleDelete(template, ticker, refreshList); setConfirmActionModal(null) } }
        },
        approvePeerReview: {
            header: "Are you sure you want to approve this draft?",
            message: "This action cannot be undone",
            handleClose: () => setConfirmActionModal(null),
            cancelButton: { onClick: () => setConfirmActionModal(null) },
            confirmButton: { variant: "positive", label: "Yes, approve draft", onClick: () => { handlePeerApproveClick(template, ticker, refreshList); setConfirmActionModal(null) } }
        },
        approvePeerReviewAndPublish: {
            header: "Are you sure you want to approve and publish this draft?",
            message: "This action cannot be undone",
            handleClose: () => setConfirmActionModal(null),
            cancelButton: { onClick: () => setConfirmActionModal(null) },
            confirmButton: { variant: "positive", label: "Yes, approve and publish", onClick: () => { handlePeerApproveClickAndPublish(template, ticker, refreshList); setConfirmActionModal(null) } }
        },
        approveCompliance: {
            header: "Are you sure you want to approve this draft?",
            message: "This action cannot be undone",
            handleClose: () => setConfirmActionModal(null),
            cancelButton: { onClick: () => setConfirmActionModal(null) },
            confirmButton: { variant: "positive", label: "Yes, approve draft", onClick: () => { handleComplianceApproveClick(template, ticker, refreshList); setConfirmActionModal(null) } }
        },
        approveComplianceAndPublish: {
            header: "Are you sure you want to approve and publish this draft?",
            message: "This action cannot be undone",
            handleClose: () => setConfirmActionModal(null),
            cancelButton: { onClick: () => setConfirmActionModal(null) },
            confirmButton: { variant: "positive", label: "Yes, approve and publish", onClick: () => { handleComplianceApproveClickAndPublish(template, ticker, refreshList); setConfirmActionModal(null) } }
        },
        rejectPeerReview: {
            header: "Are you sure you want to reject this draft?",
            message: "This action cannot be undone",
            handleClose: () => setConfirmActionModal(null),
            cancelButton: { onClick: () => setConfirmActionModal(null) },
            confirmButton: { variant: "danger", label: "Yes, reject draft", onClick: () => { handlePeerRejectClick(template, ticker, refreshList); setConfirmActionModal(null) } }
        },
        rejectCompliance: {
            header: "Are you sure you want to reject this draft?",
            message: "This action cannot be undone",
            handleClose: () => setConfirmActionModal(null),
            cancelButton: { onClick: () => setConfirmActionModal(null) },
            confirmButton: { variant: "danger", label: "Yes, reject draft", onClick: () => { handleComplianceRejectClick(template, ticker, refreshList); setConfirmActionModal(null) } }
        }
    }

    setConfirmActionModal(confirmableActions[action]);
}
export const handlePublish = (setShowPublishModal) => {
    setShowPublishModal(false);
    toastSuccess("Draft published and sent");
}

export const handleDelete = (setShowDeleteModal) => {
    setShowDeleteModal(false);
    toastInfo("Draft note deleted");
}


const handleReview = async (action, type, template, tickerString) => {
    if (!action || !template || !tickerString) return;
    const dto = {
        template: template,
        ticker: tickerString,
        type,
        action
    }
    await remote.post("/client/review/v2/approve", dto)
}

export const handlePeerApproveClick = (template, ticker, refreshList) => handleReview("approve", "peer", template, ticker).then(refreshList && refreshList)
export const handleComplianceApproveClick = (template, ticker, refreshList) => handleReview("approve", "compliance", template, ticker).then(refreshList && refreshList)
export const handlePeerApproveClickAndPublish = (template, ticker, refreshList) => handleReview("approve", "peer", template, ticker).then(() => handlePublish(template, ticker)).then(refreshList && refreshList)
export const handleComplianceApproveClickAndPublish = (template, ticker, refreshList) => handleReview("approve", "compliance", template, ticker).then(() => handlePublish(template, ticker)).then(refreshList && refreshList)
export const handlePeerRejectClick = (template, ticker, refreshList) => handleReview("reject", "peer", template, ticker).then(refreshList && refreshList)
export const handleComplianceRejectClick = (template, ticker, refreshList) => handleReview("reject", "compliance", template, ticker).then(refreshList && refreshList)

export const synchroniseData = () => {
    remote.post("/data/synchronise")
}

export const hasFeature = (feature, component, me) => {
    if (!me || !me.perms) {
        return LoadingIcon;
    }
    if (me && me.perms && me.perms.includes(feature)) {
        return component;
    }

    return ErrorPage;
}

export const hasOneOfFeatures = (features, component, me) => {
    if (!me || !me.perms) {
        return LoadingIcon;
    }
    if (me && me.perms && features.some((feature) => me.perms.includes(feature))) {
        return component;
    }

    return ErrorPage;
}

const clientManagementPerms = ["USERS", "COMPANIES", "DISTRIBUTION", "SUPPRESSED_EMAILS"]
const clientManagementRoutes = ["/crm/users", "/crm/companies", "/crm/distribution", "/crm/suppressions"]

const adminPerms = ["FUNDS", "TICKERS", "MODEL_PORTFOLIOS", "SECTORS", "DISCLAIMER", "USER_MANAGEMENT", "COMPANY_MANAGEMENT", "ROLES", "TEMPLATES", "IMPERSONATE"]
const adminRoutes = ["/admin/schemes-and-funds", "/admin/tickers", "/admin/model-portfolio", "/admin/sectors", "/admin/disclaimers", "/admin/user-management", "/admin/company-management", "/admin/role-permissions", "/admin/templates"]

export const getSidebarRouteByPermissions = (page, perms) => {
    switch (page) {
        case "admin":
            const hasAdminSubRouteAccess = adminPerms.map((perm) => {
                return perms.includes(perm)
            })
            return adminRoutes[hasAdminSubRouteAccess.indexOf(true)] || "/"
        case "clientManagement":
            const hasclientManagementSubRouteAccess = clientManagementPerms.map((perm) => {
                return perms.includes(perm)
            })
            return clientManagementRoutes[hasclientManagementSubRouteAccess.indexOf(true)] || "/"
        default:
            return "/";
    }
}


export const handleDraftFiltering = (docDate, docTicker, filters) => {
    if (filters && filters.ticker && docTicker !== filters.ticker) { return false }
    if (filters && filters.from && isBefore(parseISO(docDate), parseISO(filters.from)) && !isSameDay(parseISO(docDate), parseISO(filters.from))) { return false }
    if (filters && filters.to && isAfter(parseISO(docDate), parseISO(filters.to)) && !isSameDay(parseISO(docDate), parseISO(filters.to))) { return false }
    return true
} 
