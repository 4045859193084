import React from 'react';
import "./DetailSectionHeader.scss";

const DetailSectionHeader = (props) => {
	const { header, icon, marginBottom } = props;
	return (
		<div className="detail-section-header" style={{ marginBottom: marginBottom || null }}>
			{icon && icon}
			<h3>{header}</h3>
		</div>
	)
}

export default DetailSectionHeader;