import React, { useState, useEffect } from "react";
import { Search } from "react-feather";
import _ from 'lodash'
import TextInput from './TextInput'

const SearchTermFilter = ({ dataSet, setFilteredData, path, hasSearchTerm, placeholder, size, width, isClearable, setSearchTerm: propagateSearchTerm, disabled, noIcon }) => {


	const [searchTerm, setSearchTerm] = useState("")

	useEffect(() => {
		handleSearch(searchTerm)
	}, [dataSet])

	useEffect(() => {
		handleSearch(searchTerm)
		hasSearchTerm(searchTerm ? true : false)
	}, [searchTerm])

	const setPath = (datum) => {
		let pathType = typeof path
		switch (pathType) {
			case "string":
				return datum[path]

			case "object":
				let searchableString = ""
				path.forEach((locater) => {
					let stringToAdd = _.get(datum, locater)
					searchableString = searchableString + ' ' + stringToAdd
				})
				return searchableString
			default:
				break;
		}
	}

	const handleSearch = (searchTerm) => {
		let searchTermArray = searchTerm.toLowerCase().split(" ")
		let newFilteredData = []
		if (!dataSet || !dataSet.filter) {
			return;
		}
		newFilteredData = dataSet.filter((datum) => {
			setPath(datum)
			let formattedDatum = setPath(datum).toLowerCase()
			let verify = searchTermArray.map((term) => {
				if (formattedDatum.includes(term)) {
					return true
				} else {
					return false
				}
			})
			if (verify.includes(false) === false) {
				return datum
			}
		})
		setFilteredData(newFilteredData)
		if (propagateSearchTerm) {
			propagateSearchTerm({ searchTerm, filteredData: newFilteredData })
		}
	}

	return (
		<TextInput
			type="search"
			placeholder={placeholder}
			onChange={e => setSearchTerm(e.target.value)}
			size={size}
			width={width}
			icon={noIcon? null : <Search />}
			clearText={() => setSearchTerm("")}
			isClearable={isClearable}
			value={searchTerm}
			disabled={disabled}
		>
		
		</TextInput>
	);
}

export default SearchTermFilter;
