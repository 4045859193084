import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useParams } from 'react-router-dom';
import SystemUserDetails from './SystemUserDetails';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import withRouteChange from "@threeskye/route-change";


const SystemUserPage = ({ changeRoute }) => {
	const [createMode, setCreateMode] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [authorsMode, setAuthorsMode] = useState(false);
	const [user, setUser] = useState(null)
	const [originalUser, setOriginalUser] = useState(null)
	const remote = useRemote()
	const { userId } = useParams()
	const params = new URLSearchParams(window.location.search);
	const authorsQuery = params.get('authors');

	useEffect(() => {
		if (authorsQuery) {
			setAuthorsMode(true)
		}
		remote.get(`/crm/users/${userId}/`)
			.then((res) => {
				setUser(res);
				setOriginalUser(res);
			})
	}, [userId])

	const saveUser = () => {
		remote.put(`/crm/users/${userId}/`, user)
			.then(() => {
				setEditMode(false);
				setOriginalUser(user);
			})
	}

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + "/user-management" + (authorsMode ? "?authors=true" : ''))}
					editMode={editMode}
					edit={{ onClick: () => setEditMode(true) }}
					cancel={{
						onClick: () => {
							setEditMode(false);
							if (createMode) {
								changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users")
							}
							else setUser(originalUser);
						}
					}}
					save={{
						onClick: saveUser
					}}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the user details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<SystemUserDetails
							editUser={(content) => {
								setUser(content);
							}}
							user={user}
							editMode={editMode}
						/>
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}
export default withRouteChange(SystemUserPage);