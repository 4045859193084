import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useParams } from 'react-router-dom';
import withRouteChange from "@threeskye/route-change";
import { FileText, Users, Trash2} from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import Button from '../../../components/Buttons/Button';
import { useRemote } from '../../../Utils/Utils';
import FundsNavigation from './FundsNavigation';
import SearchField from '../../../components/Input/SearchField';
import Table from '../../../components/Table/Table';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';


const OfferPage = ( {changeRoute} ) => {
    const remote = useRemote();
    const { offerId } = useParams()

    const [originalOffer, setOriginalOffer] = useState(null)
    const [offer, setOffer] = useState(null)
    const [editMode, setEditMode] = useState(false)
    const [options, setOptions] = useState([])

    useEffect(() => {
        remote.get(`/funds/offers/${offerId}`).then(offer => {
            setOriginalOffer(offer)
            setOffer(offer)
            const optionsList = offer.funds.map(option => {
                return {
                    label: option.fundName,
                    value: option
                }
            })
            setOptions(optionsList)
        });      
    }, [remote, offerId])

    function updateOffer(newValue, property) {
        // save temporary updates
        const newObj = { ...offer, [property]: newValue }
        setOffer(newObj)
    }

    function saveEdits() {
        remote.post(`/funds/offers/${offerId}`, offer).then(offer => {
            setOriginalOffer(offer)
            setOffer(offer)
            const optionsList = offer.funds.map(option => {
                return {
                    label: option.fundName,
                    value: option
                }
            })
            setOptions(optionsList)
        })
        setEditMode(false)
    }

    function cancelEdits() {
        setOffer(originalOffer)
        setEditMode(false)
    }

    const deleteFund = () => {}
    const updateFunds = () => {}

    const fundHeaders = [<div>&nbsp;</div>, <div>&nbsp;</div>]
    const fundsColWidths = ['auto', '10%']
    const fundsDataMap = (data) => data && data.map((fund, idx) => {
        const { fundName, fundNumber } = fund
        return (
            <tr key={idx} className='cursor-pointer' onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/funds/' + fund.fundNumber)}>
                <td>{fundName + " " + fundNumber}</td>
                <td className='text-align-right' style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    <Trash2 color='#BFC1CB' onClick={(e) => { e.stopPropagation(); deleteFund(fund) }} />
                </td>
            </tr>
        );
    })

    return (
        <Page fixedBanner>
            <PageContentBanner divider>
                <FundsNavigation activeHeader = 'Offers' item = {offer} />
            </PageContentBanner>
            <PageContentBodyGrid rowGap="xl" gridColCount="12" subGrid>
                <PageGridItem container col="1 / span 6" style={{ marginTop: '1.5rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <DetailSectionHeader header="Offer Details" icon={<FileText />} />
                        <div style={{ display: 'flex', gap: 5 }}>
                            <Button onClick={() => editMode ? saveEdits() : setEditMode(true)}>{editMode ? 'Save' : 'Edit'}</Button>
                            {editMode && <Button variant="secondary" onClick={cancelEdits}>Cancel</Button>}
                        </div>
                    </div>
                </PageGridItem>
                <PageGridItem col="1 / span 3">
                    <DetailField
                        bold
                        editMode={editMode}
                        onChange={(e) => updateOffer(e.target.value, "offerName")}
                        label="Offer Name"
                        value={offer?.offerName}
                    />
                </PageGridItem>
                <PageGridItem col="4 / span 3">
                    <DetailField
                        bold
                        editMode={editMode}
                        onChange={(e) => updateOffer(e.target.value, "offerNumber")}
                        label="Offer Number"
                        value={offer?.offerNumber}
                    />
                </PageGridItem>
                <PageGridItem col="1 / span 3">
                    <DetailField
                        bold
                        editMode={editMode}
                        onChange={(e) => updateOffer(e.target.value, "status")}
                        label="Status"
                        value={offer?.status}
                    />
                </PageGridItem>
                <PageGridItem col="1 / span 3">
                    <DetailField
                        bold
                        editMode={editMode}
                        onChange={(e) => updateOffer(e.target.value, "offerRegistered")}
                        label="Date Registered"
                        value={offer?.offerRegistered}
                    />
                </PageGridItem>
                <PageGridItem container col="1 / span 6">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <DetailSectionHeader header="Funds" icon={<Users />} />
                        <SearchField
                            options={options}
                            onChange={(list) => updateFunds(list.value)}
                            placeholder='Add Funds'
                            size='small'
                            width='m'
                            value=""
                        />
                    </div>
                    <Table
                        minWidth={300}
                        tableStyle={{ maxWidth: 675 }}
                        colWidths={fundsColWidths}
                        headers={fundHeaders}
                        dataMap={fundsDataMap(offer?.funds)}
                        id='scheme-and-funds-expandable-table'
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default withRouteChange(OfferPage);