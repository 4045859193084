import React, { useState, useEffect, useRef } from 'react';
import { ChevronUp, ChevronDown, Tool, Trash2, User, Users, Eye, ChevronRight } from 'react-feather';
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import withRouteChange from "@threeskye/route-change"
import Button from '../../../components/Buttons/Button';
import Checkbox from '../../../components/Checkbox';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import Table from '../../../components/Table/Table';
import FlexWrapper from '../../FlexWrapper';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import SearchField from '../../../components/Input/SearchField'
import SearchTermFilter from '../../../components/Input/SearchTermFilter'
import DropDown from '../../../components/DropDown';
import PdfIcon from '../../../images/icons/PdfIcon'
import XlsIcon from '../../../images/icons/XlsIcon'
import './TemplateMgmt.scss'


const TemplatePublishActions = ({ data, addActionToTemplate, removeAction, selected, templateId, templateName, setSelectedPublishActions, changeRoute }) => {
	const colWidths = [40 + "%", 55 + "%", "5%"];
	const [localSelectedPublishActions, setLocalSelectedPublishActions] = useState([])
	const selectedPublishActionIds = localSelectedPublishActions && localSelectedPublishActions.length > 0 && localSelectedPublishActions.map(a => a.stepId);
	// const selectedPublishActionIds = selected.map(a => a.stepId);

	useEffect(() => {
		setLocalSelectedPublishActions([data[5]])
	}, [])

	useEffect(() => {
		if (selected && selected.length > 0) {
			const filteredRealList = selected.filter(a => a.stepId === 1 || a.stepId === 2 || a.stepId === 3 || a.stepId === 7)
			const filterFakeList = localSelectedPublishActions && localSelectedPublishActions.length > 0 && localSelectedPublishActions.filter(a => a.new)
			const updatedFakeList = filterFakeList && filterFakeList.length > 0 && filterFakeList.map(a => {
				const newObj = {
					paramValues: {},
					params: [],
					stepId: a.id
				}
				return newObj
			})
			const newList = updatedFakeList ? [...filteredRealList, ...updatedFakeList] : [...filteredRealList]
			setLocalSelectedPublishActions(newList)
		}
	}, [selected])

	function addTolist(action) {
		if (action.new) {
			const newObj = {
				paramValues: {},
				params: [],
				stepId: action.id
			}
			const newList = [...localSelectedPublishActions, newObj]
			setLocalSelectedPublishActions(newList)
		} else addActionToTemplate(action)
	}

	function removeFromList(action) {
		if (action.new) {
			const newList = localSelectedPublishActions.filter(a => a.stepId !== action.id)
			setLocalSelectedPublishActions(newList)
		} else removeAction(action)
	}

	const showParams = (action) => {
		if (!action) return null;
		if (action.stepId === 2) return <EmailDistributionListExpanded action={action} templateId={templateId} templateName={templateName} setSelectedPublishActions={setSelectedPublishActions} />
		if (action.stepId === 7) return <PublishToPortal passedRole='Compliance' />
		if (action.stepId === 11) return <DiscloseFiles />
		if (action.stepId === 17) return <EnableSubBrands changeRoute={changeRoute} />

		return (
			<FlexWrapper gap="xl" direction="column" align="start">
				{action.params.map((param, idx) => <ParamField key={idx} param={param} action={action} template={templateId} setSelectedPublishActions={setSelectedPublishActions} />)}
			</FlexWrapper>
		)
	}

	const dataMap = () => data && data.map((action, index) => {
		const active = selectedPublishActionIds && selectedPublishActionIds.includes(action.id);
		const noExpandable = [12, 13, 14, 16]
		const dontExpand = noExpandable.includes(action.id)
		const selectedInstance = active && localSelectedPublishActions && localSelectedPublishActions.length > 0 ? localSelectedPublishActions.find(a => a.stepId === action.id) : null;
		let className = "cursor-pointer ";
		let invalid = false;

		if (!active && action.id === 11) {
			return (
				<ExpandableTableRow key={action.id} className={className} expandContent={showParams({ stepId: 11 })} isLast={index === data.length - 1} >
					<td><Checkbox checked={active} label={action.displayName} onChange={() => removeFromList(action)} stopClick /></td>
					<td>{action.description} </td>
					<td>&nbsp;{/*invalid ? <AlertCircle /> : null*/}</td>
				</ExpandableTableRow>)
		}
		else if (!active && action.id !== 11) {
			return <tr key={action.id} style={{ borderBottom: index === data.length - 1 ? 'solid 1.5px #e7eaf2' : null }} >
				<td><Checkbox checked={active} label={action.displayName} onChange={() => addTolist(action)} /></td>
				<td>{action.description}</td>
				<td></td>
			</tr>
		}

		if (selectedInstance !== null) {
			for (let param of selectedInstance.params) {
				if (param.required) {
					const val = selectedInstance.paramValues[param.name];
					if (!val || (val && val.length === 0)) {
						invalid = true;
						break;
					}
				}
			}
		}

		return (
			<ExpandableTableRow key={action.id} className={className} expandContent={showParams(selectedInstance)} isLast={index === data.length - 1} dontExpand={dontExpand}>
				<td><Checkbox checked={active} label={action.displayName} onChange={() => removeFromList(action)} stopClick /></td>
				<td>{action.description} </td>
				<td>&nbsp;{/*invalid ? <AlertCircle /> : null*/}</td>
			</ExpandableTableRow>
		);
	})

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Publish Actions" icon={<Tool />} marginBottom='1rem' />
				<Table colWidths={colWidths} dataMap={dataMap()} /*tableStyle={{marginBottom: '1rem'}}*/ />
			</PageGridItem>
		</>
	);
}

const ParamField = ({ param, template, action, setSelectedPublishActions }) => {
	const value = (action.paramValues && action.paramValues[param.name]) || "";
	const remote = useRemote();
	const [editor, setEditor] = useState(value);

	const update = () => {
		remote.put(`/crm/templates/${template}/publish-steps/${action.stepId}/${param.name}`, { "value": editor }).then(setSelectedPublishActions);
	}

	return (
		<div style={{ width: "100%" }}>
			<DetailField variant="light" invalid={editor === "" ? "invalid" : undefined} editMode={true} autoFocus placeholder={param.description} label={param.name} value={editor} onChange={(e) => setEditor(e.target.value)} />
			<Button className="mt-s" disabled={editor === value} onClick={update}>Save</Button>
		</div>
	)
};

const EmailDistributionListExpanded = (props) => {
	// TODO: wire
	const { templateId, action, setSelectedPublishActions, templateName } = props
	const searchSelect = useRef();
	const remote = useRemote();
	const fakeList = { listId: 500, name: 'Example List 1', recipients: [{}, {}, {}, {}], templateName: templateName }
	const [listsOptions, setListsOptions] = useState([])
	const [currentLists, setCurrentLists] = useState([])

	useEffect(() => {
		remote.get('/crm/lists').then((fetchedLists) => {
			const updatedList = fetchedLists.map(l => {
				return { label: l.name, value: l }
			})
			if (fetchedLists && fetchedLists.length > 0) setListsOptions(updatedList)
			setCurrentLists([fakeList])
		})
	}, [])

	function addList(list) {
		remote.get("/crm/lists/" + list.listId).then(data => {
			const newList = { listId: list.listId, name: data.data.list.name, recipients: data.data.contacts, templateName: templateName }
			setCurrentLists([...currentLists, newList])
		});
	}

	function removeList(list) {
		const updetedLists = currentLists.filter(l => l.listId !== list.listId)
		setCurrentLists(updetedLists)
	}

	return (
		<PageGridItem container col="1 / span 6" gap="l" style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
			<SearchField
				onChange={(list) => addList(list.value)}
				options={listsOptions}
				placeholder={`Add Distribution List`}
				size="small"
				width={"l"}
				innerRef={searchSelect}
				value=""
				classNames="distribution-list-search"
			/>

			{currentLists && currentLists.length > 0 && currentLists.map((list, index) => {
				return (
					<div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr 0.5fr' }} key={index + '-list'}>
						<div>{list.name}</div>
						<div style={{ borderRight: 'solid 1.5px #ccd0dc', marginRight: '1rem' }}>{templateName}</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
							<User size={16} color='#0E8EFD' />
							<div>{list.recipients.length}</div>
							<Trash2 size={16} color='#999DAD' onClick={() => removeList(list)} />
						</div>
					</div>
				)
			})}

		</PageGridItem>
	)
}

const DiscloseFiles = (props) => {
	// TODO: call and endpoint
	return (
		<div className='publish-action-disclose-file-list'>
			<div className='publish-action-disclose-file-name'><PdfIcon noHover />Fund_Update.pdf</div>
			<div className='publish-action-disclose-file-name'><XlsIcon noHover />fund-returns-data.xls</div>
			<div className='publish-action-disclose-file-name'><XlsIcon noHover />general-fund-data.xls</div>
			<div className='publish-action-disclose-file-name'><XlsIcon noHover />full-portfolio-holdings-data.xls</div>
		</div>
	)
}

const EnableSubBrands = ({ changeRoute }) => {
	// TODO: call and endpoint
	const searchSelect = useRef();
	const [brandOptions, setBrandOptions] = useState([])
	const [currentBrands, setCurrentBrands] = useState([])
	const [filteredList, setFilteredList] = useState([])
	const [checked, setChecked] = useState(true)

	const fakeBrandsList = [
		{ name: 'Hejaz', pathName: 'hejaz' },
		{ name: 'MyNorth', pathName: 'my-north' },
		{ name: 'Arch Capital', pathName: 'arch-capital' },
		{ name: 'WealthStrong Financial Planning', pathName: 'wealth-strong' },
		{ name: 'Discovery Wealth Advisers', pathName: 'discovery' }
	]

	const fakeListAllSubBrands = [
		{ name: 'Hejaz', pathName: 'hejaz', icon: 'add'},
		{ name: 'MyNorth', pathName: 'my-north', icon: 'add' },
		{ name: 'Arch Capital', pathName: 'arch-capital', icon: 'add' },
		{ name: 'WealthStrong Financial Planning', pathName: 'wealth-strong', icon: 'add' },
		{ name: 'Discovery Wealth Advisers', pathName: 'discovery', icon: 'add' },
		{ name: 'Harvest Financial Group Pty Ltd', pathName: 'harvest', icon: 'add' },
		{ name: 'Priority Advisory Group', pathName: 'priority', icon: 'add' },
		{ name: 'Mindwealth Financial Planning', pathName: 'mindwealth', icon: 'add' },
		{ name: 'Meaningful Wealth', pathName: 'meaningful-wealth', icon: 'add' },
		{ name: 'True Wealth Advisers Pty Ltd', pathName: 'true-wealth', icon: 'add' },
		{ name: 'Fm Financial Solutions', pathName: 'fm', icon: 'add' },
		{ name: 'Aspire Financial Solutions', pathName: 'aspire', icon: 'add' },
		{ name: 'Bluerocke Investments Advisors Pty Ltd', pathName: 'bluerocke', icon: 'add' },
		{ name: 'Ryker Capital', pathName: 'ryker-capital', icon: 'add' },
		{ name: 'CFV Services', pathName: 'cfv-services', icon: 'add' },
	]

	useEffect(() => {
		const updatedList = fakeListAllSubBrands.map(b => {
			// return { label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>{b.name}<i className='material-icons'>add</i></div>, value: b.name, pathName: b.pathName }
			return { label: b.name, value: b.name, pathName: b.pathName }
		})
		setBrandOptions(updatedList)
		setCurrentBrands(fakeBrandsList)
	}, [])

	useEffect(() => {
		setFilteredList(currentBrands)
		if (brandOptions && brandOptions.length > 0) {
			const brandsOnList = currentBrands.map(brand => brand.name)
			const filteredOptions = brandOptions.filter(option => !brandsOnList.includes(option.value))
			setBrandOptions(filteredOptions)
		}
	}, [currentBrands])

	function addBrand(brand) {
		const newBrand = { ...brand, name: brand.value }
		setCurrentBrands([...currentBrands, newBrand])
	}

	function removeList(brand) {
		const updetedList = currentBrands.filter(b => b.name !== brand.name)
		setCurrentBrands(updetedList)
	}

	return (
		<>
			<PageGridItem container col="1 / span 6" gap="l" style={{ display: 'grid', gridTemplateColumns: '1fr 1.5fr 0.85fr', gap: '1rem', alignItems: 'center', padding: '0rem 0rem 1rem' }}>
				<div className='custom-publish-actions-search-bar'>
					<SearchTermFilter
						size='small'
						placeholder={`Search`}
						isClearable
						dataSet={currentBrands}
						setFilteredData={setFilteredList}
						path={['name']}
						hasSearchTerm={() => null}
						noIcon
						fullWidth
					/>
				</div>
				<div className='custom-publish-actions-add-bar'>
					<SearchField
						onChange={(brand) => addBrand(brand)}
						options={brandOptions}
						placeholder={`Add Sub-brand`}
						size="small"
						width={"l"}
						innerRef={searchSelect}
						value=""
						classNames="distribution-list-search"
						keepOpen
						cursor='pointer'
					/>
				</div>
				<div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', gap: '1rem' }}>
					<Checkbox checked={checked} label='Email PDF' onChange={() => setChecked(!checked)} style={{ marginRight: 0 }} />
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', opacity: 0.6, cursor: 'pointer' }} onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/brand-settings/sub-brands')}>See All <ChevronRight size={18} /></div>
				</div>
			</PageGridItem>
			<PageGridItem container col="1 / span 6" gap="s" style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
				{filteredList && filteredList.length > 0 && filteredList.map((brand, index) => {
					return (
						<div style={{ display: 'grid', gridTemplateColumns: '2fr 0.5fr', borderTop: 'solid 1.5px #E7EAF2', paddingTop: '0.5rem' }} key={index + '-brand'}>
							<div>{brand.name}</div>
							<div style={{ display: 'flex', justifyContent: 'right', alignContent: 'center', gap: 5, paddingRight: '0.5rem' }}>
								<i className='material-icons' style={{ color: '#E66780', fontSize: 16, cursor: 'pointer' }} onClick={() => removeList(brand)}>delete_forever</i>
								<Eye size={16} color='#0E8EFD' style={{ cursor: 'pointer' }} onClick={() => brand.pathName === 'hejaz' || brand.pathName === 'my-north' ? changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/brand-settings/sub-brands/' + brand.pathName) : null} />
							</div>
						</div>
					)
				})}
			</PageGridItem>
		</>
	)
}

export default withRouteChange(TemplatePublishActions);


export const PublishToPortal = (props) => {
	const { passedRole, templateId, action, setSelectedPublishActions, templateName } = props
	const [approversNumber, setApproversNumber] = useState(1)
	const [role, setRole] = useState(passedRole)
	const roles = ['Compliance', 'Supervisor', 'CRM Administrator', 'Analyst', 'Observer']

	function changeNumberofApprovers(e) {
		let value = e.target.value
		if (value > 5) value = 5
		setApproversNumber(value)
	}
	return (
		<PageGridItem container col="1 / span 6" gap="l" style={{ display: 'grid', gridTemplateColumns: '1.5fr 2fr', padding: 0 }}>
			<div className='publish-action-expansion-approvers' style={{ display: 'flex' }}>
				<Users size={16} color='#0E8EFD' />
				<div>Aprprovers</div>
				<div className='number-of-approvers'>
					<input value={approversNumber} onChange={changeNumberofApprovers} />
					<div className='input-icons'>
						<ChevronUp size={16} color='#0E8EFD' onClick={() => approversNumber >= 5 ? null : setApproversNumber(approversNumber + 1)} />
						<ChevronDown size={16} color='#0E8EFD' style={{ opacity: approversNumber <= 1 ? 0.5 : 1 }} onClick={() => approversNumber <= 1 ? null : setApproversNumber(approversNumber - 1)} />
					</div>
				</div>
			</div>
			<div className='publish-action-expansion-role'>
				<div>Role Required</div>
				<DropDown editMode={true} onChange={(e) => null} value={role}>
					{roles.map((r, index) => <div key={index + '-role'} onClick={() => setRole(r)}>{r}</div>)}
				</DropDown>
			</div>
		</PageGridItem>
	)
}