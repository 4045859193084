import React, { forwardRef } from 'react';
import "./FlexWrapper.scss";

const FlexWrapper = forwardRef(({ children, gap, columnGap, rowGap, direction, wrap, justify, align, className, onClick, fullWidth, width, style }, ref) => {
	let wrapperClass = "";

	if (className) {
		wrapperClass += className;
	}
	
	if (gap) {
		wrapperClass += " flex-wrapper-gap-" + gap;
	}
	if (columnGap) {
		wrapperClass += " flex-wrapper-column-gap-" + columnGap;
	}
	if (rowGap) {
		wrapperClass += " flex-wrapper-row-gap-" + rowGap;
	}
	return (
		<div
			ref={ref}
			onClick={onClick}
			style={{ display: "flex", flexDirection: direction, flexWrap: wrap && "wrap", justifyContent: justify, alignItems: align ? align : "center", width: fullWidth ? "100%" : width ? width : "", ...style }}
			className={wrapperClass}
		>
			{children}
		</div>
	);
});
export default FlexWrapper;