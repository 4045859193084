import React, { useState } from 'react';
import TextArea from '../../../components/Input/TextArea';
import TextInput from '../../../components/Input/TextInput';
import SelectSearchField from '../../../components/Input/SelectSearchField'
import TextFieldLabel from '../../../components/Input/TextFieldLabel'

const DetailField = (props) => {
	const { label, value, editMode, onChange, autoFocus, invalid, helperText, disabled, textArea, type, placeholder, bold, variant, className, maxlength, onClick, style, dropDown, dropDownOptions } = props;
	const cursor = style && style.cursor || null

	const [searchTerm, setSearchTerm] = useState("")

	return (
		<div className={`detail-field-wrapper${className ? ' ' + className : ''}`} onClick={onClick} style={style}>
			{editMode ? (
				<React.Fragment>
					{textArea ? (
						<TextArea maxlength={maxlength} {...props} onChange={(e) => onChange(e.target.value)} value={value} className="transition-fade-up-slow"></TextArea>
					) : dropDown ? (
						<div className={`text-input-wrapper${invalid ? " text-input-wrapper-invalid" : ""}`}>
							<TextFieldLabel>{label}</TextFieldLabel>
							<SelectSearchField
								placeholder={"Select a city"}
								value={value ? {value:value, label: value} : searchTerm}
								noIcon
								isClearable
								options={dropDownOptions && dropDownOptions.map(((option) => { return { value: option.name, label: option.name } }))}
								onChange={(e) => { setSearchTerm(e); onChange(e ? e.value : null) }}
								onClear={() => { setSearchTerm(null); onChange(null)}}
							/>
							{helperText && <div className="text-input-helper-text">{helperText}</div>}
						</div>
					) : (
						<TextInput
							disabled={disabled}
							invalid={invalid}
							helperText={helperText}
							autoFocus={autoFocus}
							label={label}
							placeholder={placeholder || label}
							value={value}
							onChange={onChange}
							className="transition-fade-up-slow"
							type={type}
							variant={variant}
						/>
					)}
				</React.Fragment>
			) : (
				<React.Fragment>
					{label ? <label className="colour-text-label-grey transition-fade-up" style={{ cursor: cursor }}>{label}</label> : ""}
					<div className={`${label ? "mt-s " : ""}transition-fade-up-slow${bold ? " font-weight-semi-bold" : ""}`} style={{ wordBreak: "break-word" }}>
						{value ? value : "-"}
					</div>
				</React.Fragment>
			)}
		</div>
	);
}

export default DetailField;