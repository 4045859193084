import React, { useState, useEffect } from 'react';
import { AlertCircle, Tool } from 'react-feather';
import { useRemote } from '../../../Utils/Utils';
import Checkbox from '../../../components/Checkbox';
import SelectTagField from '../../../components/Input/SelectTagField';
import Table from '../../../components/Table/Table';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import DetailField from '../../Shared/DetailSection/DetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import FlexWrapper from '../../FlexWrapper';
import Button from '../../../components/Buttons/Button';


const TemplateCustomPublishActions = ({ data, addActionToTemplate, removeAction, selected, templateId, setSelectedPublishActions }) => {
	const colWidths = [40 + "%", 60 + "%"];
	const options = [
		{ value: 'Van Helsing', label: 'Van Helsing' },
		{ value: 'Rick Owens', label: 'Rick Owens' },
	];

	const [selectedCustomPublishedActions, setSelectedCustomPublishedActions] = useState([])
	const selectedPublishActionIds = selectedCustomPublishedActions && selectedCustomPublishedActions.length > 0 && selectedCustomPublishedActions.map(a => a.stepId);

	useEffect(() => {
		if (selected && selected.length > 0) {
			const filteredRealList = selected.filter(a => a.stepId === 6)
			const filterFakeList = selectedCustomPublishedActions && selectedCustomPublishedActions.length > 0 && selectedCustomPublishedActions.filter(a => a.new)
			const updatedFakeList = filterFakeList && filterFakeList.length > 0 && filterFakeList.map(a => {
				const newObj = {
					paramValues: {},
					params: [],
					stepId: a.id
				}
				return newObj
			})
			const newList = updatedFakeList ? [...filteredRealList, ...updatedFakeList] : [...filteredRealList]
			setSelectedCustomPublishedActions(newList)
		}
	}, [selected])

	function addTolist(action) {
		if (action.new) {
			const newObj = {
				paramValues: {},
				params: [],
				stepId: action.id
			}
			const newList = [...selectedCustomPublishedActions, newObj]
			setSelectedCustomPublishedActions(newList)
		} else addActionToTemplate(action)
	}

	function removeFromList(action) {
		if (action.new) {
			const newList = selectedCustomPublishedActions.filter(a => a.stepId !== action.id)
			setSelectedCustomPublishedActions(newList)
		} else removeAction(action)
	}

	const showParams = (action) => {
		const providerOptions =[{value: 'Provider1', label: 'Provider1'}, {value: 'Provider2', label: 'Provider2'}, {value: 'Provider3', label: 'Provider3'}]
		const recipientsOptions = [{value: 'Vanhelsing@gmail.com', label: 'Vanhelsing@gmail.com'}, {value: 'Jonathanharker@outlook.com', label: 'Jonathanharker@outlook.com'}]
		if (!action) return null;
		if (action.stepId === 9 || action.stepId === 10) return (
			<div className='custom-publish-actions-options-selector'>
				<div className='action-field-label'>{action.stepId === 9 ? 'Providers' : 'Email Recipiants'}</div>
				<SelectTagField options={action.stepId === 9? providerOptions : recipientsOptions} />
			</div>
		)
		return (
			<FlexWrapper gap="xl" direction="column" align="start">
				{action.params.map((param, idx) => <ParamField key={idx} param={param} action={action} template={templateId} setSelectedPublishActions={setSelectedPublishActions} />)}
			</FlexWrapper>)
	}

	const dataMap = () => data && data.map((action, index) => {
		const active = selectedPublishActionIds && selectedPublishActionIds.includes(action.id);

		if (!active) {
			return (
				<tr key={action.id} style={{ borderBottom: index === data.length - 1 ? 'solid 1.5px #e7eaf2' : null }} >
					<td><Checkbox checked={active} label={action.displayName} onChange={() => addTolist(action)} stopClick /></td>
					{action.id === 8 ?
						<td className='custom-publish-actions-options-selector'><SelectTagField options={options} isDisabled /></td> :
						<td>{action.description}</td>
					}
					<td></td>
				</tr>
			)
		}

		const selectedInstance = active && selectedCustomPublishedActions && selectedCustomPublishedActions.length > 0 ? selectedCustomPublishedActions.find(a => a.stepId === action.id) : null;
		let className = "cursor-pointer ";
		let invalid = false;
		if (selectedInstance !== null) {
			for (let param of selectedInstance.params) {
				if (param.required) {
					const val = selectedInstance.paramValues[param.name];
					if (!val || (val && val.length === 0)) {
						invalid = true;
						break;
					}
				}
			}
		}
		//		const invalid = (selectedInstance.params.length !== Object.keys(selectedInstance.paramValues).length || Object.values(selectedInstance.paramValues).filter(e => e.length === 0).length > 0);

		return (action.id === 8 ?
			<tr className="cursor-pointer no-hover">
				<td><Checkbox checked={active} label={action.displayName} onChange={(e) => removeFromList(action, e)} stopClick /></td>
				<td><SelectTagField options={options} /></td>
			</tr> :
			<ExpandableTableRow key={action.id} className={className} expandContent={showParams(selectedInstance)} isLast={index === data.length - 1}>
				<td><Checkbox checked={active} label={action.displayName} onChange={() => removeFromList(action)} stopClick /></td>
				<td>{action.description} </td>
				<td>{invalid ? <AlertCircle /> : null}</td>
				{/* <td className="table-action-icons">
					<TableActionIcon icon={<Plus />} toolTip="Add this action" onClick={() => addActionToTemplate(action)} />
				</td> */}
			</ExpandableTableRow>
		);
	})

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Custom Publish Actions" icon={<Tool />} marginBottom='1rem' />
				<Table colWidths={colWidths} dataMap={dataMap()} />
			</PageGridItem>
		</>
	);
}

const ParamField = ({ param, template, action, setSelectedPublishActions }) => {

	const value = (action.paramValues && action.paramValues[param.name]) || "";
	const remote = useRemote();
	const [editor, setEditor] = useState(value);

	const update = () => {
		remote.put(`/crm/templates/${template}/publish-steps/${action.stepId}/${param.name}`, { "value": editor }).then(setSelectedPublishActions);
	}

	return (
		<div style={{ width: "100%" }}>
			<DetailField variant="light" invalid={editor === "" ? "invalid" : undefined} editMode={true} autoFocus placeholder={param.description} label={param.name} value={editor} onChange={(e) => setEditor(e.target.value)} />
			<Button className="mt-s" disabled={editor === value} onClick={update}>Save</Button>
		</div>
	)
};

export default TemplateCustomPublishActions;