import React, { useState } from 'react';
import chroma from 'chroma-js';
import DropDown from '../../../components/DropDown';
import CrigsLogoSrc from '../../../images/craigs-logo-with-border.png';
import ThreeSkyeSrc from '../../../images/3skye-logo-black.png'
import KernelLogoSrc from '../../../images/kernel-logo.png'
import Simplicity from '../../../images/simplicity.png'
import ColonialFirstState from '../../../images/colonial-first-state.png'
import HejazLogo from '../../../images/hejaz-logo.png'
import NorthLogo from '../../../images/north-logo.png'
import HejazBanner from '../../../images/hejaz-banner.png'
import MyNorthBanner from '../../../images/my-north-banner-background.png'


export const fontWeights = ['Bold', 'Semibold', 'Regular', 'Medium', 'Light']
export const sizes = ['6pt', '7pt', '9pt', '10pt', '12pt', '13pt', '15pt', '18pt', '23pt', '26pt']
export const dividerWidths = ['0.5pt', '0.75pt', '1pt', '1.25pt', '1.5pt']

export const ColourSample = ({ colour, label, alternativeLabel, dontDisplay }) => {
    return (
        <div className='sample-container' style={{ display: dontDisplay ? 'none' : null }}>
            <div className='colour-sample' style={{ backgroundColor: colour }}></div>
            <div>{alternativeLabel || label}</div>
        </div>
    )
}

export const Typography = ({ label, passedWeight, passedSize, passedFont, fontConfig }) => {
    const [family, setFamily] = useState(passedFont)
    const [weight, setWeight] = useState(passedWeight)
    const [size, setSize] = useState(passedSize)

    return (
        <div className='sample-group'>
            <label className='colour-text-label-grey'>{label}</label>
            {fontConfig && fontConfig.custom && <DropDown editMode={true} onChange={(e) => null} value={family}>
                {fontConfig && fontConfig.custom && fontConfig.custom.families && fontConfig.custom.families.map((fam, index) => <div key={index} onClick={() => setFamily(fam)}>{fam}</div>)}
            </DropDown>}
            <div style={{ display: 'flex', gap: '10px' }}>
                <DropDown editMode={true} onChange={(e) => null} value={weight} style={{ width: 150 }}>
                    {fontWeights.map((w, index) => <div key={index} onClick={() => setWeight(w)}>{w}</div>)}
                </DropDown>
                <DropDown editMode={true} onChange={(e) => null} value={size} style={{ width: 90 }}>
                    {sizes.map((s, index) => <div key={index} onClick={() => setSize(s)}>{s}</div>)}
                </DropDown>
            </div>

        </div>
    )
}

export const DividerWidth = ({ passedWidth, label }) => {
    const [wdith, setWidth] = useState(passedWidth)
    return (
        <div className='sample-with-group'>
            <div>{label}</div>
            <DropDown editMode={true} onChange={(e) => null} value={wdith} inputStyle={{ width: 84 }}>
                {dividerWidths.map((wdt, index) => <div key={index} onClick={() => setWidth(wdt)}>{wdt}</div>)}
            </DropDown>
        </div>

    )
}

export function generateLighterColors(baseColor, lightestColor = 'white', count = 7) {
    const scale = chroma.scale([baseColor, lightestColor]).mode('lab');
    let colors = [];
    for (let i = 0; i < count; i++) {
        colors.push(scale(i / (count - 1)).hex());
    }
    return colors;
}

export function extractColours(array) {
    const allColoursObject = {};
    array.forEach(category => {
        category.values.forEach(value => {
            if (category.category === 'Tables') {
                if (value.borderBottomColor) allColoursObject[value.keyName] = value.borderBottomColor
                if (value.backgroundColor) allColoursObject[value.keyName + 'Background'] = value.backgroundColor
                if (value.color) allColoursObject[value.keyName + 'Text'] = value.color
            } else if (value.keyName && value.color) {
                allColoursObject[value.keyName] = value.color;
            }
        });
    });
    return allColoursObject;
}

export function extractBrandTypography(array, template) {
    const allColoursObject = [];
    array.forEach(category => {
        category.values.forEach(value => {
            if (value.keyName && value.fontFamily) {
                if (value.keyName === 'bannerHeader') {
                    const weight = template && template === 'colonial' ? 'regular' : 'bold'
                    allColoursObject.push({ label: value.label, keyName: value.keyName, fontFamily: value.fontFamily, size: value.size + 'pt', weight: weight, lineHeight: 1.2 })
                } else {
                    allColoursObject.push({ label: value.label, keyName: value.keyName, fontFamily: value.fontFamily, size: value.size + 'pt', weight: 'regular', lineHeight: 1.2 })
                }
            }
        });
    });
    return allColoursObject;
}

export function extractTypographies(array, template) {
    const allColoursObject = {};
    array.forEach(category => {
        category.values.forEach(value => {
            if (value.keyName && value.fontFamily) {
                if (value.keyName === 'bannerHeader') {
                    const weight = template && template === 'colonial' ? 'regular' : 'bold'
                    allColoursObject[value.keyName] = { fontFamily: value.fontFamily, size: value.size + 'pt', weight: weight, lineHeight: 1.2 }
                } else {
                    allColoursObject[value.keyName] = { fontFamily: value.fontFamily, size: value.size + 'pt', weight: 'regular', lineHeight: 1.2 }
                }
            }
        });
    });
    return allColoursObject;
}

export const allCompaniesStyles = {
    craigs: {
        baseColour: '#ac724a',
        lightestColour: '#f9f6f3',

        logoInfo: {
            src: CrigsLogoSrc,
            imageStyle: { width: 219 },
            imageContainerStyle: { width: 224, border: '1px solid #E7EAF2', padding: 1.5, height: 109 },
            recommendedSize: '219 x 104',
            logoInBanner: { backgroundColor: '#e7e6dd' }
        }
    },

    kernel: {
        baseColour: '#04796b',
        lightestColour: '#edf9f5',

        logoInfo: {
            src: KernelLogoSrc,
            imageStyle: { width: 187, height: 36 },
            imageContainerStyle: { width: 200, border: '1px solid #E7EAF2', padding: 5, height: 49 },
            recommendedSize: '187 x 36',
            logoInBanner: { backgroundColor: '#edf9f5', paddingTop: '2.5rem' }
        }
    },

    threeskye: {
        baseColour: '#0a8efd',
        lightestColour: '#ecf4ff',

        logoInfo: {
            src: ThreeSkyeSrc,
            imageStyle: { width: 152, height: 34 },
            imageContainerStyle: { width: 172, height: 54, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '152 x 35',
            logoInBanner: { backgroundColor: '#ffffff', paddingTop: '2.5rem' }
        }
    },

    simplicity: {
        baseColour: '#0a8efd',
        lightestColour: '#ecf4ff',

        logoInfo: {
            src: Simplicity,
            imageStyle: { width: 'auto', height: 100 },
            imageContainerStyle: { width: 98, height: 120, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '78 x 100',
            logoInBanner: { backgroundColor: '#edece8', paddingTop: '2.5rem' }
        }
    },

    colonial: {
        baseColor: '',
        lightestColour: '',
        logoInfo: {
            src: ColonialFirstState,
            imageStyle: { width: 'auto', height: 100 },
            imageContainerStyle: { width: 255, height: 120, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '235 x 100',
            logoInBanner: { paddingTop: '2.5rem' },
            disclaimerLogowith: '74.25pt'
        }
    },

    hejaz: {
        baseColor: '',
        lightestColour: '',
        logoInfo: {
            src: HejazLogo,
            imageStyle: { width: 'auto', height: 85 },
            imageContainerStyle: { width: 180, height: 105, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '160 x 85',
            logoInBanner: { paddingTop: '4rem', position: 'relative', top: -280 },
            bannerSrc: HejazBanner,
            bannerHeight: 273,
            disclaimerLogowith: '90pt'
        }
    },

    north: {
        baseColor: '',
        lightestColour: '',
        logoInfo: {
            src: NorthLogo,
            imageStyle: { width: 'auto', height: 85 },
            imageContainerStyle: { width: 208, height: 105, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '160 x 85',
            logoInBanner: { paddingTop: '4rem', position: 'relative', top: -170 },
            bannerSrc: MyNorthBanner,
            bannerHeight: 125,
            disclaimerLogowith: '90pt'
        }
    }
}

export function getSubBrandName(string, string2) {
    switch (string) {
        case 'hejaz':
            return string2 === 'template' ?
                'Hejaz Fact Sheet' : string2 === 'email' ?
                    'www.admin@hejaz.com.au' : string2 === 'website' ?
                        'www.hejazfs.com.au' : string2 === 'legalName' ?
                            'Hejaz Asset Management Pty Ltd ABN 69 613 618 821 AFSL 550009' : string2 === 'phone' ?
                                '1300 043 529' : string2 === 'insurerLegalName' ?
                                    'AIA Australia Limited ABN 79 004 837 861 AFSL 230043 (AIA Australia)' : string2 === 'product' ?
                                        'Hejaz Islamic Super & Pension' :
                                        'Hejaz Asset Management'
        case 'north':
        case 'my-north':
            return string2 === 'template' ?
                'MyNorth Fact Sheet' : string2 === 'email' ?
                    'www.north@amp.com.au' : string2 === 'website' ?
                        'northonline.com.au' : string2 === 'legalName' ?
                            'NMMT Limited ABN 42 058 835 573 AFSL 234653 (NMMT) ' : string2 === 'phone' ?
                                '1800 667 841' : string2 === 'insurerLegalName' ?
                                    'TAL Life Limited ABN 70 050 109 450 AFSL 237848 (TAL)' : string2 === 'product' ?
                                        'MyNorth Super & Pension' :
                                        'MyNorth'
        case 'bulletin':
            return 'Bulletin'
        case 'pds':
            return 'Product Disclosure Statement'
        case 'sipo':
            return 'Statement of Investment Policy and Objectives'
        case 'qfu':
            return 'Quarterly Fund Update'
        default:
            return string2 === 'template' ?
                'CFS Bulletin' : string2 === 'website' ?
                    'www.cfs.com.au' : string2 === 'legalName' ?
                        'Colonial First State Investments Limited ABN 98 002 348 352 AFSL 232468 (CFSIL)' : string2 === 'phone' ?
                            '13 13 36' : string2 === 'insurerLegalName' ?
                                'AIA Australia Limited ABN 79 004 837 861 AFSL 230043 (AIA Australia)' : string2 === 'product' ?
                                    'FirstChoice super and pension' :
                                    'Colonial First State'
    }
}

export function getCategoryChartData(categoryChartConfig) {
    const dataObj = {
        "data": {
            "data": {
                "TICKERLIST": "ERD.NZ",
                "F121": "Overweight",
                "F103": "EROAD",
                "F104": "ERD.NZ",
                "F105": "ERD NZ",
                "F107": "ERD",
                "F106": "NZC",
                "F100": "Australasia",
                "F101": "New Zealand",
                "F102": "Technology",
                "REFERENCE_YEAR": "2021",
                "F1001[-2]": "",
                "F1001[-1]": "",
                "F1001[0]": "",
                "F1001[1]": "",
                "F1001[2]": "",
                "F1000[-2]": "61.351896",
                "F1000[-1]": "81.2",
                "F1000[0]": "92.6922171337933",
                "F1000[1]": "106.08940448482154",
                "F1000[2]": "122.19637839057877",
                "F7200[-2]": "",
                "F7200[-1]": "",
                "F7200[0]": "",
                "F7200[1]": "",
                "F7200[2]": "",
                "F108": "NZD",
                "F1201[-2]": "15.633179000000005",
                "F1201[-1]": "27.1",
                "F1201[0]": "32.85305892982194",
                "F1201[1]": "38.83676509050355",
                "F1201[2]": "45.3213663852523",
                "F7220[-2]": "0.2548116687379964",
                "F7220[-1]": "0.33374384236453203",
                "F7220[0]": "0.3544316874242133",
                "F7220[1]": "0.36607581387696464",
                "F7220[2]": "0.3708896039487414",
                "F1250[-2]": "",
                "F1250[-1]": "",
                "F1250[0]": "",
                "F1250[1]": "",
                "F1250[2]": "",
                "F7230[-2]": "-0.037803558670786566",
                "F7230[-1]": "0.055418719211822655",
                "F7230[0]": "0.09184221926134388",
                "F7230[1]": "0.11150441964793545",
                "F7230[2]": "0.12580314842678353",
                "F1700[-2]": "-4.924458999999996",
                "F1700[-1]": "1.0000000000000004",
                "F1700[0]": "4.457462958254274",
                "F1700[1]": "6.728604918884742",
                "F1700[2]": "9.310164864368634",
                "F7000[-2]": "-7.31",
                "F7000[-1]": "1.55",
                "F7000[0]": "6.528329124393557",
                "F7000[1]": "9.854607401089085",
                "F7000[2]": "13.635518905302856",
                "F7000[-3]": "-5.61",
                "F7410[-2]": "",
                "F7410[-1]": "",
                "F7410[0]": "",
                "F7410[1]": "",
                "F7410[2]": "",
                "F7130[-2]": "",
                "F7130[-1]": "",
                "F7130[0]": "",
                "F7130[1]": "",
                "F7130[2]": "",
                "F4110[-2]": "67.283918",
                "F130": "3.61",
                "F135": "1",
                "F5340[-2]": "18.499889999999997",
                "F1040[-2]": "61.351896",
                "F4110[-1]": "67.361474",
                "F5340[-1]": "32.400000000000006",
                "F1040[-1]": "81.2",
                "F4110[0]": "68.278772",
                "F5340[0]": "41.404605148959426",
                "F1040[0]": "92.6922171337933",
                "F4110[1]": "68.278772",
                "F5340[1]": "46.38277188060295",
                "F1040[1]": "106.08940448482154",
                "F4110[2]": "68.278772",
                "F5340[2]": "48.89341188099929",
                "F1040[2]": "122.19637839057877",
                "F7015[-2]": "-7.31",
                "F7015[-1]": "1.55",
                "F7015[0]": "6.528329124393557",
                "F7015[1]": "9.854607401089085",
                "F7015[2]": "13.635518905302856",
                "F7060[-2]": "0",
                "F7060[-1]": "0",
                "F7060[0]": "0",
                "F7060[1]": "0",
                "F7060[2]": "0",
                "F124": "Initiation of coverage",
                "F120": "4.596619958451254",
                "EXT.HISTORICNOTES.LASTTARGETPRICE": "",
                "EXT.COMPARISON.F1040": "N/A",
                "EXT.COMPARISON.F7230": "N/A",
                "EXT.COMPARISON.F1905": "N/A",
                "F1905[0]": "4.457462958254274",
                "F1905[1]": "6.728604918884742",
                "PAGE_NUM": "",
                "F122": "2020-08-04",
                "F110": "NZD",
                "F1001[-3]": "",
                "F7220[-3]": "0.24093886794135855",
                "F7230[-3]": "-0.093310211537154",
                "F1040[-3]": "43.766496",
                "F1040[-4]": "32.763801",
                "F7250[-3]": "-0.06089515717572527",
                "F7250[-2]": "-0.09601057671223155",
                "F7250[-1]": "0.019493177387914236",
                "F7250[0]": "0.07994379087139572",
                "F7250[1]": "0.10768168245303286",
                "F7250[2]": "0.12967483820339973",
                "F109": "31 Mar",
                "F7015[-3]": "-5.61",
                "F7060[-3]": "0",
                "F3600[-3]": "56.766813",
                "F3600[-2]": "51.29079700000001",
                "F3600[-1]": "51.30000000000001",
                "F3600[0]": "55.75746295825428",
                "F3600[1]": "62.48606787713901",
                "F3600[2]": "71.79623274150767",
                "F4110[-3]": "61.668093",
                "F2700[-3]": "-18.591296",
                "F2700[-2]": "-13.079387",
                "F2700[-1]": "-12.799999999999997",
                "F2700[0]": "-9.00460514895942",
                "F2700[1]": "-4.978166731643526",
                "F2700[2]": "-2.510640000396336",
                "F5340[-3]": "4.612943999999999",
                "F1201[-3]": "10.545049999999996",
                "F1250[-3]": "",
                "F1251[-3]": "-4.083861000000004",
                "F1251[-2]": "-2.319319999999996",
                "F1251[-1]": "4.5",
                "F1251[0]": "8.513058929821941",
                "F1251[1]": "11.829437477875107",
                "F1251[2]": "15.372689127885383",
                "F1300[-3]": "-5.883056000000004",
                "F1300[-2]": "-5.115657999999996",
                "F1300[-1]": "1.4000000000000004",
                "F1300[0]": "6.190920775353158",
                "F1300[1]": "9.345284609562142",
                "F1300[2]": "12.930784533845326",
                "F1700[-3]": "-3.456824000000004",
                "F2000[-3]": "5.205344",
                "F2000[-2]": "14.2",
                "F2000[-1]": "23.1",
                "F2000[0]": "26.21843736188204",
                "F2000[1]": "33.00184007392259",
                "F2000[2]": "38.37626820909132",
                "F5100[-3]": "11.298668",
                "F5100[-2]": "16.2",
                "F5100[-1]": "11.6",
                "F5100[0]": "12.976910398731064",
                "F5100[1]": "14.215980200966088",
                "F5100[2]": "14.663565406869452",
                "F2200[-3]": "20.828054",
                "F2200[-2]": "0",
                "F2200[-1]": "0",
                "F2200[0]": "0",
                "F2200[1]": "0",
                "F2200[2]": "0",
                "F2230[-3]": "0",
                "F2230[-2]": "0",
                "F2230[-1]": "0",
                "F2230[0]": "0",
                "F2230[1]": "0",
                "F2230[2]": "0",
                "F5160[-3]": "19.454055",
                "F5160[-2]": "8.155336999999996",
                "F5160[-1]": "1.1613040000000083",
                "F5160[0]": "7.604605148959422",
                "F5160[1]": "4.978166731643526",
                "F5160[2]": "2.510640000396336",
                "F2150[-3]": "-5.647998",
                "F2150[-2]": "-6.669437000000003",
                "F2150[-1]": "-7.800000000000001",
                "F2150[0]": "-8.342299542041397",
                "F2150[1]": "-8.91150997672501",
                "F2150[2]": "-10.032322665866516",
                "F2240[-3]": "-0.754884",
                "F2240[-2]": "-0.9210289999999995",
                "F2240[-1]": "-1.1",
                "F2240[0]": "0",
                "F2240[1]": "0",
                "F2240[2]": "0",
                "F2130[-3]": "0",
                "F2130[-2]": "0",
                "F2130[-1]": "0",
                "F2130[0]": "0",
                "F2130[1]": "0",
                "F2130[2]": "0",
                "F2140[-3]": "0",
                "F2140[-2]": "0",
                "F2140[-1]": "0",
                "F2140[0]": "0",
                "F2140[1]": "0",
                "F2140[2]": "0",
                "F2300[-3]": "20.935056000000003",
                "F2300[-2]": "-5.731609000000002",
                "F2300[-1]": "-12.738805999999999",
                "F2300[0]": "-1.4",
                "F2300[1]": "0",
                "F2300[2]": "0",
                "F2060[-3]": "5.59869",
                "F2060[-2]": "-2.376602000000001",
                "F2060[-1]": "-1.8906929999999988",
                "F2060[0]": "2.5790255963722366",
                "F2060[1]": "0.734092457590588",
                "F2060[2]": "0.8825739126442329",
                "F3010[-3]": "21.870415",
                "F3010[-2]": "16.138806",
                "F3010[-1]": "3.4",
                "F3010[0]": "2",
                "F3010[1]": "2",
                "F3010[2]": "2",
                "F3130[-3]": "23.848227",
                "F3130[-2]": "33.901289",
                "F3130[-1]": "37.4",
                "F3130[0]": "41.400910398731064",
                "F3130[1]": "45.680672104001694",
                "F3130[2]": "49.38087620591074",
                "F5220[-3]": "0",
                "F5220[-2]": "0",
                "F5220[-1]": "0",
                "F5220[0]": "0",
                "F5220[1]": "0",
                "F5220[2]": "0",
                "F3200[-3]": "111.148191",
                "F3200[-2]": "123.199577",
                "F3200[-1]": "125.80000000000001",
                "F3200[0]": "138.5503629008651",
                "F3200[1]": "151.5417963521769",
                "F3200[2]": "164.9071055640693",
                "F3320[-3]": "0",
                "F3320[-2]": "0",
                "F3320[-1]": "0",
                "F3320[0]": "0",
                "F3320[1]": "0",
                "F3320[2]": "0",
                "F3420[-3]": "26.483359",
                "F3420[-2]": "34.638695999999996",
                "F3420[-1]": "35.800000000000004",
                "F3420[0]": "43.404605148959426",
                "F3420[1]": "48.38277188060295",
                "F3420[2]": "50.89341188099929",
                "F3500[-3]": "54.381378",
                "F3500[-2]": "71.90878",
                "F3500[-1]": "74.5",
                "F3500[0]": "82.79289994261083",
                "F3500[1]": "89.0557284750379",
                "F3500[2]": "93.11087282256163",
                "F3700[-3]": "56.76681299999999",
                "F3700[-2]": "51.290797000000005",
                "F3700[-1]": "51.300000000000004",
                "F3700[0]": "55.75746295825428",
                "F3700[1]": "62.48606787713903",
                "F3700[2]": "71.79623274150765",
                "F7015[-4]": "-8.82",
                "F7090[-3]": "0",
                "F7090[-2]": "0",
                "F7090[-1]": "0",
                "F7090[0]": "0",
                "F7090[1]": "0",
                "F7090[2]": "0",
                "F7400[-3]": "0.08126128200996592",
                "F7400[-2]": "0.36068634301003344",
                "F7400[-1]": "0.6315789473684211",
                "F7400[0]": "0.742584094616341",
                "F7400[1]": "0.7422898168564775",
                "F7400[2]": "0.6810024706593342",
                "F7460[-3]": "5.598836382016695",
                "F7460[-2]": "5.59058990722867",
                "F7460[-1]": "9.678571428571429",
                "F7460[0]": "14.14776242602046",
                "F7460[1]": "15.633806431919917",
                "F7460[2]": "18.55984320430369",
                "EXT.PERTICKER.DISCLOSURE": "None",
                "F160": "None",
                "EXT.HISTORICNOTES.DATA": ""
            }
        },
        "configuration": JSON.stringify(categoryChartConfig),
        "className": "CategoryChart",
        "dataSuffix": null
    }
    return dataObj
}

export function getCategoryChartConfig(categoryChartText, colourList) {
    const allColoursList = colourList || {
        axis: "#2B2E34",
        chartBackground: "#ffffff",
        grid: "#ffffff",
        line1: "#d81521",
        line2: "#008fd2",
    }
    const dataObj = {
        width: 240,
        height: 132,
        styles: { p: categoryChartText.p, h1: categoryChartText.h1 },
        backgroundColour: allColoursList.chartBackground,
        gridColour: allColoursList.grid,
        gridStrokeWidth: 0.25,
        axisColour: allColoursList.axis,
        axisStrokeWidth: 0.5,
        rightAxisZeroBased: true,
        categories: [
            { value: "${format:1:0:REFERENCE_YEAR-3}${F1001[-3]}" },
            { value: "${format:1:0:REFERENCE_YEAR-2}${F1001[-2]}" },
            { value: "${format:1:0:REFERENCE_YEAR-1}${F1001[-1]}" },
            { value: "${format:1:0:REFERENCE_YEAR}${F1001[0]}" },
            { value: "${format:1:0:REFERENCE_YEAR+1}${F1001[1]}" },
            { value: "${format:1:0:REFERENCE_YEAR+2}${F1001[2]}" }
        ],
        series: [
            {
                name: { value: "Lorem" },
                type: "line",
                lineWidth: 1,
                colour: allColoursList.line2,
                axis: "left",
                data: [{ value: "${F7220[-3]*100}" }, { value: "${F7220[-2]*100}" }, { value: "${F7220[-1]*100}" }, { value: "${F7220[0]*100}" }, { value: "${F7220[1]*100}" }, { value: "${F7220[2]*100}" }]
            },
            {
                name: { value: "Lorem" },
                type: "line",
                lineWidth: 1,
                colour: allColoursList.line1,
                axis: "left",
                data: [{ value: "${F7230[-3]*100}" }, { value: "${F7230[-2]*100}" }, { value: "${F7230[-1]*100}" }, { value: "${F7230[0]*100}" }, { value: "${F7230[1]*100}" }, { value: "${F7230[2]*100}" }]
            }
        ]
    }
    return dataObj
}
