import React, { useEffect, useState } from 'react';
import { ChevronUp, ChevronDown, ChevronRight, Edit2 } from 'react-feather';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import PageGridDivider from '../../Shared/Page/PageGridDivider'
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import withRouteChange from '@threeskye/route-change';
import SearchTermFilter from '../../../components/Input/SearchTermFilter'
import Divider from '../../../components/Divider';
import Checkbox from '../../../components/Checkbox'
import FundsNavigation from './FundsNavigation';

const OffersList = ({ changeRoute}) => {

    const remote = useRemote();
	const [checked, setChecked] = useState(false)
	const [sortingColumn, setSortingColumn] = useState({ column: 'offerRegistered', ascending: true })
    const [offerData, setOfferData] = useState([]);
    const [hasOfferSearchTerm, setHasOfferSearchTerm] = useState(false);
	const [filteredOfferData, setFilteredOfferData] = useState([])
	const [rowExpanded, setRowExpanded] = useState(null)
	const [tableWidth, setTableWidth] = useState(1200)

	const offerDataDisplaying = hasOfferSearchTerm ? filteredOfferData : offerData

    useEffect(() => {
		remote.get("/funds/offers").then(setOfferData);
		window.addEventListener("resize", getTableWidth);
        return () => window.removeEventListener("resize", getTableWidth);
	}, [remote])

    // Clear Sorting
	useEffect(() => {
		setSortingColumn({ column: "offerRegistered", ascending: true })
	}, [filteredOfferData])

	const toggleSort = (column) => {
		if (column === null) {
			setSortingColumn({ column: "offerRegistered", ascending: true })
			return
		}

		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		setSortingColumn({ column: column, ascending: ascending })
	}

    const sortTable = (dataToSort) => {
		if (sortingColumn.column === null) {
			return dataToSort
		}
		if (!dataToSort) {
			return dataToSort
		}

		let tableData = dataToSort
		const ascending = sortingColumn.ascending
		const column = sortingColumn.column;

		switch (column) {
			case 'funds':
				tableData.sort((a, b) => ascending ? a.funds.length - b.funds.length : b.funds.length - a.funds.length)
				break
			default:
				tableData.sort((a, b) => ascending ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column]))
		}

		return tableData
	}

	function getTableWidth() {
        let tableElement = document.getElementById('scheme-and-funds-expandable-table')
        if (tableElement) {
            setTableWidth(tableElement.offsetWidth)
        }
    }

	// Table widths and headers
	const offersColWidths = ['30%', '11%', '29%', '13%', '13%', '50px', '60px'];
	const offersHeaderLabels = [
		{ heading: 'Offer Name', field: 'offerName'},
		{ heading: 'Offer Number', field: 'offerNumber'},
		{ heading: 'Scheme Name', field: 'schemeName'},
		{ heading: 'Scheme Number', field: 'schemeNumber'},
		{ heading: 'Date Registered', field: 'offerRegistered'},
		{ heading: 'Funds', field: 'funds'}
	]

	const offersHeaders = offersHeaderLabels.map((column) => {
		return (
			{
				text: (
					<div className='sorting-header' onClick={() => toggleSort(column.field)} >
						{column.heading}
						{sortingColumn?.column === column.field && sortingColumn?.ascending === true ?
							<ChevronUp className='colour-text-primary' size={12} /> :
							<ChevronDown className={sortingColumn?.column === column.field ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
						}
					</div>
				)
			}
		)
	})

    const offerDataMap = (data) => data && data.map((offer, idx) => {
		const { offerName, offerNumber, schemeName, schemeNumber, offerRegistered, funds } = offer
		const colWidths = ['42%', '11%', '32%', '10%', '2%', '3rem']

		return (
			<>
				<tr key={idx} className='cursor-pointer' onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/offers/' + offer.offerNumber)}>
					<td className={ rowExpanded === idx || checked ? "expanded-table-row" : "" }>{offerName}</td>
					<td className={ rowExpanded === idx || checked ? "expanded-table-row" : "" }>{offerNumber}</td>
					<td className={ rowExpanded === idx || checked ? "expanded-table-row" : "" }>{schemeName}</td>
					<td className={ rowExpanded === idx || checked ? "expanded-table-row" : "" }>{schemeNumber}</td>
					<td className={ rowExpanded === idx || checked ? "expanded-table-row" : "" }>{offerRegistered} </td>
					<td className={ rowExpanded === idx || checked ? "expanded-table-row" : "" }>{funds?.length}</td>
					<td className={ rowExpanded === idx || checked ? "expanded-table-row" : "" } style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'right' }}>
						<Edit2 className='colour-text-primary' />
						{rowExpanded === idx || checked ?
                            <ChevronUp className='colour-text-primary' size={24} onClick={(e) => { e.stopPropagation(); setRowExpanded(null) }} /> :
                            <ChevronDown className='colour-text-neutral-dark-30' size={24} onClick={(e) => { e.stopPropagation(); setRowExpanded(idx) }}/>
						}
					</td>
				</tr>
				{rowExpanded === idx || checked ?
                    <>
                        <table style={{ maxWidth: 1200, width: 1200 }}>
                            {colWidths && (
                                <colgroup>
                                    {colWidths.map((width, idx) => (
                                        <col key={"col-" + idx} span="1" style={{ width }} />
                                    ))}
                                </colgroup>
                            )}
                            <thead>
                                <tr>
                                    <th className="nested-table-empty-th"><div className="nested-table-div-in-td no-top left-xxl">Fund Name</div></th>
								    <th className="nested-table-empty-th"><div className="nested-table-div-in-td no-top" >Fund Number</div></th>
                                    <th className="nested-table-empty-th"><div className="nested-table-div-in-td no-top">Offer Name</div></th>
                                    <th className="nested-table-empty-th"><div className="nested-table-div-in-td no-top">Offer Number</div></th>
                                    <th className="nested-table-empty-th no-border"><div>&nbsp;</div></th>
									<th className="nested-table-empty-th no-border"><div>&nbsp;</div></th>
                                </tr></thead>
                            <tbody>
                                {funds?.map((f, index) => (
                                    <tr key={schemeNumber + "-funds-expanded-" + index}>
                                        <td className="nested-table-empty-td"><div className="nested-table-div-in-td left-xxl">{f.fundName}</div></td>
										<td className="nested-table-empty-td"><div className="nested-table-div-in-td">{f.fundNumber}</div></td>
                                        <td className="nested-table-empty-td"><div className="nested-table-div-in-td">{f.offerName}</div></td>
                                        <td className="nested-table-empty-td"><div className="nested-table-div-in-td">{f.offerNumber}</div></td>
                                        <td className="nested-table-empty-td">
                                            <div className="nested-table-div-in-td" style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                                <ChevronRight className='colour-text-primary' size={16} onClick={(e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/funds/' + f.fundNumber) }} />
                                            </div>
                                        </td>
										<td className="nested-table-empty-td no-border"><div>&nbsp;</div></td>
                                    </tr>))}
                                <tr>
                                    <td className="nested-table-empty-td"><div className="nested-table-div-in-td left-xxl">&nbsp;</div></td>
									<td className="nested-table-empty-td"><div className="nested-table-div-in-td">&nbsp;</div></td>
                                    <td className="nested-table-empty-td"><div className="nested-table-div-in-td">&nbsp;</div></td>
                                    <td className="nested-table-empty-td"><div className="nested-table-div-in-td">&nbsp;</div></td>
                                    <td className="nested-table-empty-td no-border"><div className="nested-table-div-in-td">&nbsp;</div></td>
                                    <td className="nested-table-empty-td no-border"><div>&nbsp;</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </> : ""
				}
			</>);
	})

	const sortedDataToDisplay = sortTable(offerDataDisplaying)
	const dataToDisplay = !!sortedDataToDisplay ?  offerDataMap(sortedDataToDisplay) : null
	
	return (
		<Page fixedBanner>
			<PageContentBanner divider dividerThinkness="m">
				<FundsNavigation activeHeader='Offers' />
			</PageContentBanner>
			<PageContentBodyGrid>
				<PageGridItem colSpan='12' >
					<div style={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
						<SearchTermFilter
							size='small'
							width='m'
							placeholder='Search'
							isClearable
							dataSet={offerData}
							setFilteredData={setFilteredOfferData}
							path={['offerName','offerNumber','schemeName', 'schemeNumber']}
							hasSearchTerm={(boolean) => setHasOfferSearchTerm(boolean)}
						/>
						<Divider height={32} vertical />
						<Checkbox checked={checked} label='Expand All' onChange={() => setChecked(!checked)} />
					</div>
				</PageGridItem>
				<PageGridDivider width='100rem' />
				<PageGridItem colSpan='12' >
					<Table
						minWidth={1200}
						tableStyle={{ maxWidth: 1200 }}
						colWidths={ offersColWidths }
						headers={offersHeaders }
						dataMap={dataToDisplay}
						id='scheme-and-funds-expandable-table'
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page >
	)
		
}

export default withRouteChange(OffersList);