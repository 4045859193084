import React from 'react';

import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import withRouteChange from '@threeskye/route-change';
import { PageMenu, PageMenuItem } from '../../Shared/Menu/Menu'

const FundsNavigation = ({ changeRoute, activeHeader, item }) => {
    return (
        <PageMenu>
            <PageMenuItem className={activeHeader === 'Schemes' && !item ? 'active' : ''}>
                <span onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/schemes')}>Schemes{activeHeader === 'Schemes' && item ? '\u00A0/\u00A0' : ''}</span>
                {activeHeader === 'Schemes' && item && <span className='active'>{item?.schemeName}</span>}
            </PageMenuItem>
            <PageMenuItem className={activeHeader === 'Offers' && !item ? 'active' : ''}>
                <span onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/offers')}>Offers{activeHeader === 'Offers' && item ? '\u00A0/\u00A0' : ''}</span>
                {activeHeader === 'Offers' && item && <span className='active'>{item?.offerName}</span>}
            </PageMenuItem>
            <PageMenuItem className={activeHeader === 'Funds' && !item ? 'active' : ''}>
                <span onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/funds')}>Funds{activeHeader === 'Funds' && item ? '\u00A0/\u00A0' : ''}</span>
                {activeHeader === 'Funds' && item && <span className='active'>{item?.fundName}</span>}
            </PageMenuItem>
        </PageMenu>
    )
}

export default withRouteChange(FundsNavigation)