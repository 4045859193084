import React, { useEffect, useState } from 'react';
import { FileText, Plus, ChevronDown, ChevronUp } from 'react-feather';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { dummyDisclaimers } from '../../../Utils/DummyData';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import Table from '../../../components/Table/Table';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import Button from '../../../components/Buttons/Button';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Tooltip from '../../../components/popups/Tooltip';
import FlexWrapper from '../../FlexWrapper';
import withRouteChange from '@threeskye/route-change';
import LoadingIcon from '../../Shared/LoadingIcon'


const AdminCompanyDisclaimerList = ({ changeRoute }) => {
	const [disclaimersList, setDisclaimersList] = useState([])
	const [soreterdList, setSortedList] = useState([])
	const [sortingColumn, setSortingColumn] = useState({ column: 'Name', ascending: true })
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		// TODO: replace with real endpoint
		setDisclaimersList(dummyDisclaimers)
		setLoading(false)
	}, [])

	useEffect(() => {
		sortTable('Name', 'ascending')
	}, [disclaimersList])


	function sortTable(column, string) {
		let tableData = disclaimersList
		const ascending = sortingColumn.column === column ? string ? string === 'ascending' ? true : false : !sortingColumn.ascending : true
		switch (column) {
			case 'Name':
				tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
				break;
			case 'Templates':
				tableData.sort((a, b) => ascending ? (a.templatesUsing.length || 0) - (b.templatesUsing.length || 0) : (b.templatesUsing.length || 0) - (a.templatesUsing.length || 0))
				break;
			case 'Type':
				tableData.sort((a, b) => ascending ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type))
				break;
			case 'Created':
				tableData.sort((a, b) => ascending ? a.created.localeCompare(b.created) : b.created.localeCompare(a.created))
				break;
			case 'Modified':
				tableData.sort((a, b) => ascending ? a.modified.localeCompare(b.modified) : b.modified.localeCompare(a.modified))
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setSortedList(tableData)
	}

	const colWidths = ['auto', '250px', '150px', '150px', '220px', '120px'];
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable('Name')}>
					Name
					{sortingColumn && sortingColumn.column === 'Name' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Name' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header right' style={{paddingRight: '5.5rem'}} onClick={() => sortTable('Templates')}>
					Templates (using)
					{sortingColumn && sortingColumn.column === 'Templates' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Templates' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable('Type')}>
					Type
					{sortingColumn && sortingColumn.column === 'Type' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Type' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header right' onClick={() => sortTable('Created')}>
					Date Created
					{sortingColumn && sortingColumn.column === 'Created' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Created' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header right' onClick={() => sortTable('Modified')}>
					Date Modified
					{sortingColumn && sortingColumn.column === 'Modified' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Modified' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			)
		}
	];


	function dataMap(data) {
		if (data && data.length > 0) {
			return data.map((d, idx) => {
				return (
					<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/disclaimers/' + d.id)}>
						<td><FlexWrapper gap="s">{d.name} {d.isDefault && <Tooltip label="Global Default" className="colour-text-primary"><FileText size={18} /></Tooltip>}</FlexWrapper></td>
						<td className="text-align-right" style={{paddingRight: '6rem'}}>{d.templatesUsing.length}</td>
						<td>{d.type}</td>
						<td className="text-align-right">{d.created}</td>
						<td className="text-align-right">{d.modified}</td>
						<TableActionIconGroup remove={{ onClick: (e) => deleteDisclaimer(e, d.id) }} />
					</tr>
				);
			})
		}
	}

	function deleteDisclaimer(event, disclaimerId) {
		event.stopPropagation()
		const updatedList = disclaimersList.filter((disclaimer) => disclaimer.id !== disclaimerId)
		setDisclaimersList(updatedList)
	}


	return (
		<Page>
			<PageContentBanner divider>
				<Button icon={<Plus />}>New Disclaimer</Button>
			</PageContentBanner>
			<PageContentBodyGrid id="admin-disclaimer-page">
				<PageGridItem colSpan="12">
					{loading ?
						<LoadingIcon centered /> :
						<Table
							headers={headers}
							dataMap={dataMap(soreterdList)}
							colWidths={colWidths}
							tableStyle={{ maxWidth: 1400 }}
							minWidth={1150}
						/>
					}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AdminCompanyDisclaimerList);