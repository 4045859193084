import React from "react";
import "./App.scss";
import "./styles/UtilClasses.scss";
import "./styles/DesignTokenClasses.scss";
import BuilderPage from "./layouts/BuilderPage";
import DataController from "./DataController";
import { ThreeSkyeWrapper, withRemote } from "@threeskye/global";
import { withRouter } from "react-router";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Authorise from "./Authorise";
import Extensions from "./Extensions";
import NoAccess from "./NoAccess";
import MenuWrapper from "./layouts/MenuWrapper";
import CraigsLibraryPage from "./layouts/CraigsLibraryPage/CraigsLibraryPage";
import AdminPage from "./layouts/AdminPage/AdminPage";
import DistributionList from "./layouts/CompanyUserManagement/Distribution/DistributionList";
import SettingsProfile from "./layouts/Settings/Profile/SettingsProfilePage";
import SettingsGeneralPage from "./layouts/Settings/General/SettingsGeneralPage";
import UsersLists from "./layouts/CompanyUserManagement/User/UsersLists";
import UserPage from "./layouts/CompanyUserManagement/User/UserPage";
import CompanyLists from "./layouts/CompanyUserManagement/Company/CompanyLists";
import CompanyPage from "./layouts/CompanyUserManagement/Company/CompanyPage";
import ToastContainer from "./components/popups/ToastContainer";
import DistributionListPage from "./layouts/CompanyUserManagement/Distribution/DistributionListPage";
import ErrorPage from "./layouts/ErrorPage";
import DeliveryDetails from "./layouts/CraigsLibraryPage/DeliveryDetails";
import SuppressionList from "./layouts/CompanyUserManagement/Distribution/SuppressionList";
// Analytics
import AnalyticsPage from "./layouts/Analytics/AnalyticsPage";
import AnalyticsTickersList from "./layouts/Analytics/Tickers/AnalyticsTickersList";
import AnalyticsGroupList from './layouts/Analytics/Tickers/AnalyticsGroupList';
import AnalyticsTickerPage from "./layouts/Analytics/Tickers/AnalyticsTickerPage"
import AnalyticsTickerResearchViews from "./layouts/Analytics/Tickers/AnalyticsTickerResearchViews"
import AnalyticsUsersList from "./layouts/Analytics/Users/AnalyticsUsersList";
import AnalyticsUserPage from "./layouts/Analytics/Users/AnalyticsUserPage";
import AnalyticsUserResearchViews from "./layouts/Analytics/Users/AnalyticsUserResearchView"
import AnalyticsCompaniesList from "./layouts/Analytics/Companies/AnalyticsCompaniesList";
import AnalyticsCompanyPage from "./layouts/Analytics/Companies/AnalyticsCompanyPage";
import DraftsLibrary from "./layouts/Drafts/DraftsLibrary";
import RequestsLibrary from "./layouts/Drafts/RequestsLibrary";
import { unimpersonate, useStorage, hasFeature, hasOneOfFeatures } from "./Utils/Utils";
import LoadingIcon from "./components/LoadingIcon";
import AnalyticsAuthorsList from "./layouts/Analytics/Authors/AnalyticsAuthorsList";
import AnalyticsAuthorPage from "./layouts/Analytics/Authors/AnalyticsAuthorPage";
import NoPermissionsPage from "./layouts/NoPermissionsPage";


export const PAGES = {
	EDITOR: "editor",
	LIBRARY: "library",
	DRAFTS: "drafts",
	ADMIN: "admin",
	CRM: "crm",
	ANALYTICS: "analytics",
	REQUESTS: "requests"
};

export const ROUTE_PATHS = {
	[PAGES.EDITOR]: "/editor",
	[PAGES.LIBRARY]: "/",
	[PAGES.DRAFTS]: "/drafts",
	[PAGES.ADMIN]: "/admin",
	[PAGES.CRM]: "/crm",
	[PAGES.SETTINGS]: "/settings",
	[PAGES.ANALYTICS]: "/analytics",
	[PAGES.DRAFTS]: "/drafts",
	[PAGES.REQUESTS]: "/requests"

};

class AuthIntercept extends React.Component {

	constructor(props) {
		super(props);
		this.state = { authenticated: false, me: null, company: null, organisation: null, topMessage: null, loadingRole: true };
		this.logout = this.logout.bind(this)
		this.initialise = this.initialise.bind(this)
		this.setTopMessage = this.setTopMessage.bind(this);
		this.hasComplianceRouteAccess = this.hasComplianceRouteAccess.bind(this);
	}

	setTopMessage(message) {
		this.setState({ topMessage: message });
	}

	componentDidMount() {
		this.initialise()
	}

	initialise() {
		const authenticated = !!window.sessionStorage.getItem("3skye.auth.token");
		if (!authenticated) {
			//For now this is hard wired (backend) to CIP.  Need to find a way to split based on URL
			window.location.href = "/api/login?host=" + window.location.hostname;
		} else if (authenticated) {
			this.setState({ authenticated });
			this.props.remote.registerLogoutHandler(this.logout);
			this.props.remote.get("/crm/company").then((org) => { this.setState({ organisation: org }) })
			this.props.remote.get("/crm/me").then((me) => {
				// this.setState({ me: { ...me, role: "COMP" }, loadingRole: false });
				this.setState({ me, loadingRole: false });
			})
		}
	}

	handleLandingPage() {
		// TODO: handle landing page swaparoo
		// switch through permissions to determine which page to land on
		// replace library page component with a redirect to the approriate page
	}



	componentWillMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			localStorage.setItem("3skye.research.lastpath", location.pathname);
		});

		// TODO needs re-thinking to accomodate the many more pages that now exist :)

		// if (localStorage.getItem("3skye.research.lastpath")) {
		// 	this.props.history.push(localStorage.getItem("3skye.research.lastpath"));
		// }
	}

	componentWillUnmount() {
		this.unlisten();
	}

	logout() {
		if (this.props.logout) {
			console.log("Auth interceptor has a passed down logout.  Calling")
			this.props.logout();
		} else {
			console.log("Auth interceptor has no passed down logout.  Initialising")
			this.initialise();
		}

	}

	hasComplianceRouteAccess(component) {
		//if (this.state.loadingRole)	return null;
		return component;
		return this.state.me && this.state.me.role === "COMP" ? component : ErrorPage;
	}

	render() {
		const { authenticated, loadingRole } = this.state;
		if (!authenticated) {
			return null;
		}

		return (
			<MenuWrapper topComponent={this.state.topMessage} user={this.state.me} updateUser={(val) => this.setState({ me: val })}>
				{this.state.loadingRole
					? <div className="mt-xl" style={{ width: "calc(100vw - 64px)" }}> <LoadingIcon centered /></div>
					: <>
						<Switch>
							{(this.state.me === null || (this.state.me && this.state.me.perms && this.state.me.perms.length < 1)) && < Route path="*" component={NoPermissionsPage} />}
							<Route
								exact
								path={ROUTE_PATHS[PAGES.EDITOR]}
								render={() => (
									<ProtectedRoute user={this.state.me} requiredFeature={"EDIT"}>
										<DataController internalApi={true} setTopMessage={this.setTopMessage}>
											<BuilderPage organisation={this.state.organisation} template={this.craigs} setTopMessage={this.setTopMessage} />
										</DataController>
									</ProtectedRoute>
								)}
							/>

							{/* Settings and Profile Pages */}
							<Route path={ROUTE_PATHS[PAGES.SETTINGS] + "/profile"} component={SettingsProfile} />
							<Route path={ROUTE_PATHS[PAGES.SETTINGS] + "/general"} component={SettingsGeneralPage} />

							{/* Drafts Workspace/Requests Routes */}
							<Route exact path={ROUTE_PATHS[PAGES.DRAFTS]} component={hasOneOfFeatures(["DRAFTS_ANALYST"], DraftsLibrary, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.REQUESTS]} component={hasOneOfFeatures(["DRAFTS_COMPLIANCE", "DRAFTS_PEER"], RequestsLibrary, this.state.me)} />

							{/* Analytics */}
							<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/dashboard"} component={hasFeature("ANALYTICS_VIEW", AnalyticsPage, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/authors"} component={hasFeature("ANALYTICS_VIEW", AnalyticsAuthorsList, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers"} component={hasFeature("ANALYTICS_VIEW", AnalyticsTickersList, this.state.me)} />
							<Route
								exact
								path={ROUTE_PATHS[PAGES.ANALYTICS] + "/authors/author/:authorId"}
								render={(props) =>
									<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"}>
										<AnalyticsAuthorPage datatita={props} />
									</ProtectedRoute>
								} />
							<Route
								exact
								path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/:tickerId"}
								render={(props) =>
									<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"}>
										<AnalyticsTickerPage datatita={props} />
									</ProtectedRoute>
								} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group"} component={hasFeature("ANALYTICS_VIEW", AnalyticsGroupList, this.state.me)} />
							<Route
								exact
								path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group/:groupId"}
								render={(props) =>
									<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"}>
										<AnalyticsTickerPage datatita={props} />
									</ProtectedRoute>
								}
							/>
							<Route
								path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/:tickerId/research/:researchId"}
								render={(props) =>
									<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"}>
										<AnalyticsTickerResearchViews datatita={props} />
									</ProtectedRoute>
								} />
							<Route
								path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group/:groupId/research/:researchId"}
								render={(props) =>
									<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"}>
										<AnalyticsTickerResearchViews datatita={props} />
									</ProtectedRoute>
								} />
							<Route
								// exact
								path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/:userId/research/:researchId"}
								render={(props) =>
									<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"}>
										<AnalyticsUserResearchViews datatita={props} />
									</ProtectedRoute>
								} />

							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users"} component={hasFeature("ANALYTICS_VIEW", AnalyticsUsersList, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/:userId"} component={hasFeature("ANALYTICS_VIEW", AnalyticsUserPage, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/:userId/research/:researchId"} component={(props) => (
								<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"} >
									<AnalyticsUserResearchViews datatita={props} />
								</ProtectedRoute>
							)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/companies"} component={hasFeature("ANALYTICS_VIEW", AnalyticsCompaniesList, this.state.me)} />
							<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/companies/company/:companyId"} component={hasFeature("ANALYTICS_VIEW", AnalyticsCompanyPage, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group"} component={hasFeature("ANALYTICS_VIEW", AnalyticsGroupList, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group/:groupId"} component={(props) => (
								<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"} >
									<AnalyticsTickerPage datatita={props} />
								</ProtectedRoute>
							)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group/:groupId/research/:researchId"} component={(props) => (
								<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"} >
									<AnalyticsTickerResearchViews datatita={props} />
								</ProtectedRoute>
							)} />
							<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/:tickerId/research/:researchId"} component={(props) => (
								<ProtectedRoute user={this.state.me} requiredFeature={"ANALYTICS_VIEW"} >
									<AnalyticsTickerResearchViews datatita={props} />
								</ProtectedRoute>
							)} />
							{/* Admin Routes - subpages contained inside <AdminPage /> */}
							<Route
								path={ROUTE_PATHS[PAGES.ADMIN]}
								render={() =>
									<ProtectedRoute user={this.state.me} requiredFeature={["FUNDS", "TICKERS", "MODEL_PORTFOLIOS", "SECTORS", "DISCLAIMER", "USER_MANAGEMENT", "COMPANY_MANAGEMENT", "ROLES", "TEMPLATES"]} >
										<AdminPage me={this.state.me} />
									</ProtectedRoute>
								} />

							{/* CRM Routes */}
							<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/users"} component={hasFeature("USERS", UsersLists, this.state.me)} />
							<Route path={ROUTE_PATHS[PAGES.CRM] + "/users/user/:userId"} component={hasFeature("USERS", UserPage, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/companies"} component={hasFeature("COMPANIES", CompanyLists, this.state.me)} />
							<Route path={ROUTE_PATHS[PAGES.CRM] + "/companies/company/:companyId"} component={hasFeature("COMPANIES", CompanyPage, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/distribution"} component={hasFeature("DISTRIBUTION", DistributionList, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/:distributionListId"} component={hasFeature("DISTRIBUTION", DistributionListPage, this.state.me)} />
							<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/suppressions"} component={hasFeature("SUPPRESSED_EMAILS", SuppressionList, this.state.me)} />

							{/* Library Pages */}
							<Route path={ROUTE_PATHS[PAGES.CRM] + "/delivery/:deliveryId"} component={DeliveryDetails} />
							<Route exact component={hasFeature("LIBRARY_VIEW", CraigsLibraryPage, this.state.me)} />
							{/* <Route path={ROUTE_PATHS[PAGES.LIBRARY] + "publication/:publicationId"} component={PublicationView} /> */}

							{/* 404/error page */}
							<Route path="*" component={ErrorPage} />
						</Switch>
					</>
				}
				</MenuWrapper>
				);
	}
}

				const ProtectedRoute = ({user, requiredFeature, children}) => {

	if (Array.isArray(requiredFeature)) {
		if (user && user.perms && requiredFeature.some(f => user.perms.includes(f) )) {
			return children;
		}
	} else if (user && user.perms && user.perms.includes(requiredFeature)) {
		return children;
	}
				console.log("Protected route and don't have requiredFeature ", requiredFeature)

				return <ErrorPage />
};

				const ImpersonationWrapper = ({remote, ...others }) => {
	const storage = useStorage();

	const logout = (storage) => {
					unimpersonate(storage);
	}

				console.log("Impersonation wrapper has remote, router ", remote, others)

				return <AuthIntercept logout={() => logout(storage)} remote={remote} {...others} />
}


				class InternalApiApp extends React.Component {

					render() {

		const component = window.sessionStorage.getItem("3skye.auth.super.token") !== null ? ImpersonationWrapper : AuthIntercept;
				const hocd = withRemote(withRouter(component));

				return (
				<ThreeSkyeWrapper disableI18n={true}>
					<ToastContainer />
					<BrowserRouter>
						<Switch>
							<Route exact path="/authorise/:token" component={Authorise} />
							<Route path="/ext/:comp" component={Extensions} />
							<Route path="/no-access" component={NoAccess} />
							<Route component={hocd} />
						</Switch>
					</BrowserRouter>
				</ThreeSkyeWrapper>
				);
	}
}

				export default (InternalApiApp);
